
import { Component, Vue } from 'vue-property-decorator'
import { popUpRoute } from '@/services/utils/popupRoute'
import { IProduct } from '@/modules/inventory/product/types/productTypes'
import { Field } from '@/services/utils/baseForm'
import eventBus from '@/eventBus'
import { sellerEndpoints } from '@/modules/seller'
import { CeleryApiType } from '@/modules/celery-task/types/celeryTaskTypes'
import { productEndpoints } from '@/modules/inventory/product'
import { stockEndpoints } from '@/modules/inventory/stock'

@Component({
  name: 'ImportFile',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ImportFile extends Vue {
  celeryApiType = this.$route.params.celeryApiType || ''

  /**
   * function will be passed to child components
   * @params product which modules type the fetched product will be passed to it in the child component. (base form)
   */
  fields = () => [
    Field.dropdown<IProduct>(
      'merchant',
      'merchantId',
      () => sellerEndpoints.list(),
      'fullName',
      'id'
    )
      .required()
      .setCol('col-12'),
    Field.file<IProduct>('file', 'uploadProductAttachment')
      .setProps({
        accept: '.csv'
      })
      .setRules('ext:csv')
      .required()
      .setCol('col-12')
  ]

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: IProduct) {
    //Note: handle it in more dynamic way :(
    if (this.celeryApiType === CeleryApiType.PRODUCT)
      await productEndpoints
        .import({ file: values.file, merchantId: values.merchantId })
        .then(() => this.closePopUp())

    if (this.celeryApiType === CeleryApiType.STOCK)
      await stockEndpoints
        .import({ file: values.file, merchantId: values.merchantId })
        .then(() => this.closePopUp())
    eventBus.refreshData()
  }

  closePopUp() {
    popUpRoute.removeModalPath()
  }
}
