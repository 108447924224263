import axios from 'axios'
import { ReadOnlyApi } from '@/services/api/readOnlyApi'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import snakeCaseKeys from 'snakecase-keys'
import { ApiService } from '@/services/api/apiService'
import { getDiff } from '@/shared/helpers/getDifference'

/**
 * Model api service that contains all types of endpoint methods/actions like
 * (get, post, patch, and delete).
 */
export class ModelApi<T> extends ReadOnlyApi<T> {
  constructor(resource: string, modelName = '') {
    super(resource, modelName)
  }

  /**
   * A private helper function that inserts all the data into a formData
   * if isFormData is true. Otherwise, it just returns the original data object
   * @param data
   * @param isFormData
   * @param isDeep
   * @private
   */
  static rearrangeData<T extends AnyObject>(
    data: T,
    isFormData: boolean,
    isDeep = false
  ): FormData | T {
    const formData = new FormData()

    if (isFormData) {
      const newData = snakeCaseKeys(data, { deep: isDeep })
      ApiService.addToFormData(newData, formData)
    }
    return isFormData ? formData : data
  }

  /**
   * Create a new record in the backend via post method
   * @param data Post body data.
   * @param isFormData
   * @param isDeep
   */
  post(data = {}, isFormData = false, isDeep = false): Promise<void> {
    return new Promise((resolve, reject) => {
      const newData = ModelApi.rearrangeData(data, isFormData, isDeep)
      axios
        .post(this.getUrl(), newData)
        .then(response => {
          this.responseNotify('notifications.addedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Update a record in the backend via patch method.
   * @param id Id of the selected record.
   * @param data Patch body data that will be updated in the backend.
   * @param originalData
   * @param isFormData
   * @param isDeep
   */
  patch(
    id: Id,
    originalData: Partial<T>,
    data = {},
    isFormData = false,
    isDeep = false
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const differences = getDiff(
        originalData as Record<string, unknown>,
        data as Record<string, unknown>
      )

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData
      if (differences) {
        newData = ModelApi.rearrangeData(differences, isFormData, isDeep)
      }

      if (id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .patch(this.getUrl(id), newData)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Delete a record in the backend via delete method.
   * @param id Id of the selected record.
   * @param parentIds
   * @param path
   */
  async delete(id: Id, parentIds?: Id, path?: string): Promise<T> {
    if (+id <= 0) {
      this.responseNotify('notifications.id0', 'error')
      return Promise.reject(new Error('Id must be greater than 0'))
    }

    const url = parentIds ? path : this.getUrl(id)

    try {
      const response = await axios.delete(url as string)
      this.responseNotify('notifications.deletedSuccessfully')
      return response.data.data
    } catch (error) {
      const errorMessage = error.response?.data || 'Unknown error'
      this.responseNotify(errorMessage, 'error')
      return Promise.reject(errorMessage)
    }
  }

  /**
   * Setting a password via post method
   * @param id of the selected record.
   * */
  setPassword(id: Id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.resource}/${id}/set_password/`)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  async export(data: AnyObject = {}, params: AnyObject = {}) {
    return axios
      .post(
        `${this.resource}/export_${this.resource}/`,
        { ...data },
        { responseType: 'blob', params }
      )
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const today = new Date()
          .toISOString()
          .substring(0, 10)
          .replace('_', '-')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.resource}-${today}.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
      .catch(async error => {
        //`.text()` method is used to read the contents of a Blob object as text.
        const errorResponse = await error.response.text()
        const parseResponse = JSON.parse(errorResponse)
        this.responseNotify(
          parseResponse.data?.detail || parseResponse.data,
          'error'
        )
      })
  }

  import(data: AnyObject): Promise<unknown> {
    const form = ModelApi.rearrangeData(data, true)
    return axios
      .post(`${this.resource}/import_${this.resource}/`, form)
      .then(() => this.responseNotify('notifications.updatedSuccessfully'))
      .catch(error => {
        this.responseNotify(error.message, 'error')
        throw error.message
      })
  }
}
