import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { commissionPreviewComponents } from '@/modules/accounting/commission/helpers/previewComponents'
import TheUpsertCommission from '@/modules/accounting/commission/views/TheUpsertCommission.vue'
import { commissionEndpoints } from '@/modules/accounting/commission'

/** Commission module routes*/
export const commissionRoutes: RouteConfig[] = [
  {
    path: '/commission',
    name: 'commission',
    component: () =>
      import(
        /* webpackChunkName: "TheCommissionChunk" */ '@/modules/accounting/commission/views/TheCommission.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.commissionMetaTitle'
    }
  },
  {
    path: '/commission/preview-details/:id',
    name: 'preview-commission',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCommissionChunk" */ '@/modules/accounting/commission/views/ThePreviewCommission.vue'
      ),
    meta: {
      title: 'navigations.commissionMetaTitle',
      parentRouteName: 'commission',
      logPage: {
        endpoint: commissionEndpoints,
        previewDynamicComponents: commissionPreviewComponents
      }
    }
  }
]
export const commissionModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertCommission,
    persistent: true,
    name: 'upsert-commission',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-commission',
    requiredParams: ['id'],
    onDelete: q => commissionEndpoints.delete(q.id.toString()),
    dialogTitle: 'commissionTitle'
  }
]
