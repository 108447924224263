import { Module, VuexModule } from 'vuex-module-decorators'
import { FooterLink } from '@/modules/login/types/loginTypes'

@Module({ namespaced: true, name: 'footer' })
export default class FooterStore extends VuexModule {
  footerLinks: FooterLink[] = [
    {
      title: 'web',
      icon: 'fal fa-globe',
      link: 'https://www.arbela.store'
    },
    {
      title: 'facebook',
      icon: 'fa-brands fa-facebook',
      link: '//facebook.com/arbela.store'
    },
    {
      title: 'instagram',
      icon: 'fa-brands fa-instagram',
      link: '//instagram.com/arbela.store'
    },
    {
      title: 'linkedin',
      icon: 'fa-brands fa-linkedin',
      link: '//linkedin.com/company/arbela'
    }
  ]

  /**
   * Get footer links from vuex store.
   */
  get getFooterLinks() {
    return this.footerLinks
  }
}
