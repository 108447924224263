
import { Component, Vue } from 'vue-property-decorator'
import TheHeader from '@/core/components/TheHeader.vue'
import TheNavigationDrawer from '@/core/components/TheNavigationDrawer.vue'
import { authEndpoints } from '@/modules/login'
import appStore from '@/AppStore'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { popUpRoute } from '@/services/utils/popupRoute'

@Component({
  name: 'TheLayout',
  components: {
    TheNavigationDrawer,
    TheHeader,
    BaseDialog: () =>
      import(
        /* webpackChunkName: "BaseDialog" */ '@/stories/base-dialog/BaseDialog.vue'
      )
  }
})
export default class TheLayout extends Vue {
  show = false
  dialog: PopUpRouteConfig | null = null
  miniMenu = false
  isRefreshing = false

  /**
   * checking expiry token if it's about to be expired we request it for next request
   * to get a new expiry token
   */
  beforeUpdate() {
    const currentTime = Math.floor(new Date().getTime() / 1000)
    const getExpiry = appStore.getters['auth/getExpiryToken']
    const timeToRefresh = 1200 // 20 Minutes in seconds it equals to 1200
    // for test 86395 in between 5 seconds it fetches
    const result = getExpiry - currentTime
    if (result > 0 && result < timeToRefresh) {
      this.isRefreshing = true
      authEndpoints
        .refresh()
        .finally(() => setTimeout(() => (this.isRefreshing = false), 1500))
    }
  }

  mounted() {
    // do not attach if there is already a listener
    if (!document.onAppDialog) {
      this.attachListener()
    }
    // handle the app modal to see if a modal is open
    const { dialog } = parsePathQueryString(location.search)
    if (dialog && !Array.isArray(dialog)) {
      this.handleAppDialog(dialog)
    }
  }

  attachListener() {
    document.onAppDialog = (e: CustomEvent) => {
      const { open, name } = e.detail
      if (open && !name) throw new Error(`name is required when opening modal`)
      else this.handleAppDialog(name)
    }
    document.addEventListener('app-dialog', document.onAppDialog as never)
  }

  beforeDestroy() {
    // remove the listener when app is destroyed
    document.removeEventListener('app-dialog', document.onAppDialog as never)
    delete document.onAppDialog
  }

  handleAppDialog(dialogName?: string) {
    // if a name is provided, open the name. Otherwise, close everything
    if (dialogName) {
      this.dialog = popUpRoute.getPopup(dialogName)
      // show the modal
      this.show = true
    } else {
      this.show = false
    }
  }
}
