/**
 * handles dropdown search filter
 * @param item - item list
 * @param search - searched query from the dropdown
 * @returns boolean indicates that search query exists inside the list or not.
 * */
export function searchFilter<T>(item: T, search: string): boolean {
  return Object.values(item).some(value => {
    switch (typeof value) {
      case 'object':
        return searchFilter(value, search)
      case 'string':
      case 'number':
        return String(value).toLowerCase().indexOf(search.toLowerCase()) > -1
      default:
        return false
    }
  })
}
