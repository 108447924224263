
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { AnyObject } from '@/shared/types/builtInTypes'
import { generateDynamicTitle } from '@/shared/helpers/generateDynamicTitle'
import { Route } from 'vue-router'

@Component({
  name: 'DialogBaseSkeleton'
})
export default class DialogBaseSkeleton extends Vue {
  /**
   * By Using prop size provide the maximum width to the component.
   */
  @Prop({ type: Number, default: 700 })
  readonly size!: number

  /**
   * Provide title to the dialog.
   */
  @Prop({
    type: [String, Function],
    required: true
  })
  readonly title!:
    | string
    | TranslateResult
    | ((
        params: Route['params'],
        queries: Route['query'],
        remoteDataFetchers: Partial<AnyObject>
      ) => string | TranslateResult)

  /**
   * Add close icon to the dialog which is optional to provide it.
   */
  @Prop({ type: Boolean, default: false })
  readonly hideCloseIcon!: boolean

  /**
   * Set different icon before the title
   */
  @Prop(String) readonly titleIcon?: string

  /**
   * Set different color to the dialog icon
   */
  @Prop(String) readonly colorIcon?: string

  /**
   * Set different color to dialog title
   */
  @Prop(String) readonly titleColor?: string

  /**
   * Set page title of the provided page using remote data.
   */
  @Prop(Object) remoteData?: AnyObject

  /**
   * Get page title of the provided page, params, and remote data.
   */
  get getTitle() {
    if (this.title) {
      return generateDynamicTitle(
        this.title,
        this.$route?.params,
        this.remoteData
      )
    }
    return ''
  }

  /**
   * Event that fires when clicking on ❌ close icon.
   */
  @Emit('close')
  close() {
    return
  }
}
