import { ModelApi } from '@/services/api/modelApi'
import { IVariant } from '@/modules/inventory/variant/types/variantTypes'

/**
 * Variant endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class VariantEndpoints extends ModelApi<IVariant> {
  constructor(urlPath: string) {
    super(urlPath)
  }
}
