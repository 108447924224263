import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IStockProfile } from '@/modules/seller-management/stock-profile/types/stockProfileTypes'

/**
 * dynamic component generator method
 * @param stockProfile is IStockProfile interface type which
 * @return An Array of IPreviewComponent stock profile type
 */
export function stockProfilePreviewComponents(
  stockProfile: IStockProfile
): PreviewComponent<IStockProfile>[] {
  return [
    PreviewComponent.labelValue<IStockProfile>(
      'profileName',
      'profileName',
      stockProfile.profileName
    ).setTitle('stockProfileDetail'),
    PreviewComponent.labelValue<IStockProfile>(
      'upperLimit',
      'upperLimit',
      ContentFormatter.$formatToPercentage(stockProfile.upperLimit, true)
    ),
    PreviewComponent.labelValue<IStockProfile>(
      'description',
      'description',
      stockProfile.description
    )
      .setCol('12')
      .setBreak(true),
    ...metadataLabelValues(stockProfile)
  ]
}
