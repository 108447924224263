import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { denominationEndpoints } from '@/modules/arbela-gift-card/denomination'
import TheUpsertDenomination from '@/modules/arbela-gift-card/denomination/views/TheUpsertDenomination.vue'
import { denominationPreviewComponents } from '@/modules/arbela-gift-card/denomination/helpers/previewComponents'

/**
 * Denomination module routes
 */
export const denominationRoutes: RouteConfig[] = [
  {
    path: '/gift-card-denomination',
    name: 'denomination',
    component: () =>
      import(
        /* webpackChunkName: "TheDenominationChunk" */ '@/modules/arbela-gift-card/denomination/views/TheDenomination.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.denominationMetaTitle'
    }
  },
  {
    path: '/gift-card-denomination/preview-details/:id',
    name: 'preview-denomination',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewDenominationChunk" */ '@/modules/arbela-gift-card/denomination/views/ThePreviewDenomination.vue'
      ),
    meta: {
      title: 'navigations.denominationMetaTitle',
      parentRouteName: 'denomination',
      logPage: {
        endpoint: denominationEndpoints,
        previewDynamicComponents: denominationPreviewComponents
      }
    }
  }
]
export const denominationModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertDenomination,
    persistent: true,
    name: 'edit-denomination',
    requiredParams: ['id']
  },
  {
    component: TheUpsertDenomination,
    persistent: true,
    name: 'add-denomination',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-denomination',
    requiredParams: ['id'],
    onDelete: q => denominationEndpoints.delete(q.id.toString()),
    dialogTitle: 'denominationTitle'
  }
]
