/**
 *  gets value of the given key Mostly used for nested object
 * @param object
 * @param keyPath
 * @param throwIfNotFound
 * @param hasArray
 * */
export function getValueAtKeyPath(
  object: Record<string, unknown>,
  keyPath: string,
  throwIfNotFound = false,
  hasArray = false
): unknown {
  const split = keyPath.split('.')

  return split.reduce((previous: unknown, current, i, arr) => {
    if (Array.isArray(previous) && hasArray && previous.length > 0) {
      return previous[0][current]
    } else if (typeof previous !== 'object' || previous === null) {
      // error throw if set to true
      if (throwIfNotFound) {
        throw new Error(
          'can not get value at a key path that which has a non object value in the path'
        )
      } else {
        // eject from to reduce
        arr.splice(1)
        return null
      }
    }
    return (previous as Record<string, unknown>)[current]
  }, object)
}
