import { CustomerEndpoints } from '@/modules/customer/api/customerEndpoints'
import {
  ICustomer,
  ICustomerAddress
} from '@/modules/customer/types/customerTypes'

/**
 * Customer endpoint instance
 * instantiate customer api class with different api path
 */

export const customerEndpoints = new CustomerEndpoints<ICustomer>('customer')
export const customerAddressEndpoints = new CustomerEndpoints<ICustomerAddress>(
  'customer_address'
)
