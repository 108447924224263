
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'

import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { Id } from '@/shared/types/builtInTypes'
import { IBatch } from '@/modules/arbela-gift-card/batch/types/batchTypes'
import { batchEndpoints } from '@/modules/arbela-gift-card/batch'
import { denominationEndpoints } from '@/modules/arbela-gift-card/denomination'

@Component({
  name: 'TheAddingBatch',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheAddingBatch extends Vue {
  data = {} as IBatch
  originalData = {} as IBatch
  id: Id = ''
  loading = false

  /**
   * function will be passed to child components
   * @params batch which modules type the fetched Batch will be passed to it in the child component. (base form)
   */
  fields(): Field<IBatch>[] {
    return [
      Field.dropdown<IBatch>(
        'denomination',
        'denominationId',
        () => denominationEndpoints.list(),
        'label',
        'id'
      )
        .setCol('col-12')
        .required(),
      Field.text<IBatch>('batchNumber', 'batchNumber')
        .setCol('col-12')
        .required(),
      Field.currency<IBatch>('quantity', 'quantity')
        .setCol('col-12')
        .setRules('max_value:100000')
        .required(),
      Field.currency<IBatch>('expiryInterval', 'expiryInterval')
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint.*/
  async handleSubmit(values: IBatch) {
    await batchEndpoints.post(values, true).then(() => {
      popUpRoute.removeModalPath(['id'])
    })
    eventBus.refreshData()
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.id = id
        this.data = await batchEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
