import { PreviewComponent } from '@/services/utils/basePreview'
import { IApiRequest } from '@/modules/preference/api-integration/types/apiIntegration'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import i18n from '@/plugins/i18n'

/**
 * dynamic component generator method
 * @param request is IApiRequest interface type which
 * @return An Array of IPreviewComponent request type
 */
export function apiRequestPreviewComponents(
  request: IApiRequest
): PreviewComponent<IApiRequest>[] {
  return [
    PreviewComponent.labelValue<IApiRequest>(
      'apiUrl',
      'apiUrl',
      request.apiUrl
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'httpMethod',
      'httpMethod',
      request.httpMethod
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'requestType',
      'requestType',
      request.requestType
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'executionDecision',
      'executionDecision',
      request.executionDecision
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'executionOrder',
      'executionOrder',
      request.executionOrder
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'tagRequest',
      'tag',
      request.tag?.displayName
    ),
    PreviewComponent.labelValue<IApiRequest>(
      'executionCondition',
      'executionCondition',
      request.executionCondition
    ).setCol('pa-3 col-12'),
    PreviewComponent.table<IApiRequest>(
      'fieldMapping',
      [
        {
          text: i18n.t('header.mappingType'),
          value: 'mappingType',
          key: 'mappingType',
          formatValue: 'text',
          width: '200px'
        },
        {
          text: i18n.t('header.apiField'),
          value: 'apiField',
          key: 'apiField',
          formatValue: 'text',
          width: '300px'
        },
        {
          text: i18n.t('header.defaultValue'),
          value: 'defaultValue',
          key: 'defaultValue',
          formatValue: 'text'
        },

        {
          text: i18n.t('header.arbelaField'),
          value: 'arbelaField',
          key: 'arbelaField',
          formatValue: 'text'
        }
      ],
      request.fieldMapping
    ).setProp({ class: 'col-12' }),
    ...metadataLabelValues(request)
  ]
}
