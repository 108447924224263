import { ModelApi } from '@/services/api/modelApi'
import { IPageModule } from '@/modules/page/page/types/pageTypes'

/**
 * Collection endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class PageEndpoints extends ModelApi<IPageModule> {
  constructor() {
    super('page')
  }
}
