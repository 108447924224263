import Vue from 'vue'
import Vuex from 'vuex'

import navigation from '@/core/store/navigationStore'
import locale from '@/core/store/localeStore'
import gender from '@/shared/store/genderStore'
import notification from '@/core/store/notificationStore'
import footer from '@/core/store/footerStore'
import auth from '@/modules/login/store/loginStore'
import profile from '@/modules/profile/store/profileStore'
import platform from '@/shared/store/platformStore'
import weightUnit from '@/shared/store/weightUnitStore'

Vue.use(Vuex)

/**
 * Export vuex store module.
 */
export default new Vuex.Store({
  modules: {
    locale,
    navigation,
    gender,
    auth,
    notification,
    footer,
    profile,
    platform,
    weightUnit
  }
})
