import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { categoryEndpoint } from '@/modules/inventory/category'
import { categoryPreviewComponents } from '@/modules/inventory/category/herlpers/previewComponents'

//FixME Refactor these path

/** Categories module routes */
export const categoryRoutes: RouteConfig[] = [
  {
    path: '/inventory/categories',
    name: 'category',
    component: () =>
      import(
        /* webpackChunkName: "TheCategoryChunk" */ '@/modules/inventory/category/views/TheCategory.vue'
      ),
    meta: {
      title: 'navigations.category',
      parentRouteName: null
    }
  },
  {
    path: '/inventory/categories/:parentId/sub-categories',
    name: 'subcategories',
    component: () =>
      import(
        /* webpackChunkName: "TheCategoryChunk" */ '@/modules/inventory/category/views/TheCategory.vue'
      ),
    meta: {
      title: 'navigations.subcategoryMetaTitle',
      parentRouteName: 'category'
    },
    //We use the props because we reuse the same components
    props({ params }) {
      return {
        parentId: params.parentId
      }
    }
  },
  {
    path: '/inventory/categories/:parentId/sub-categories/:subParentId/sub-categories-child',
    name: 'subcategoriesChild',
    component: () =>
      import(
        /* webpackChunkName: "TheCategoryChunk" */ '@/modules/inventory/category/views/TheCategory.vue'
      ),
    meta: {
      title: 'navigations.subcategoryMetaTitle',
      parentRouteName: 'subcategories'
    },
    props({ params }) {
      return {
        subParentId: params.subParentId
      }
    }
  },
  {
    path: '/inventory/categories/preview-details/:id',
    name: 'preview-category',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCategoryChunk" */ '@/modules/inventory/category/views/ThePreviewCategory.vue'
      ),
    meta: {
      title: 'navigations.category',
      parentRouteName: 'category',
      logPage: {
        endpoint: categoryEndpoint,
        previewDynamicComponents: categoryPreviewComponents
      }
    }
  },
  {
    path: '/inventory/categories/:id?/upsert-category',
    name: 'upsert-category',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertCategoryChunk" */ '@/modules/inventory/category/views/TheUpsertCategory.vue'
      ),
    meta: {
      title: 'navigations.category',
      parentRouteName: 'category'
    }
  },
  {
    path: '/inventory/categories/:id?/upsert-category/upsert-sub-category/:parentId?',
    name: 'upsert-subcategories',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertCategoryChunk" */ '@/modules/inventory/category/views/TheUpsertCategory.vue'
      ),
    meta: {
      title: 'navigations.category',
      parentRouteName: 'subcategories'
    }
  }
]

export const categoryModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-category',
    requiredParams: ['id'],
    onDelete: q => categoryEndpoint.delete(q.id.toString()),
    dialogTitle: 'categoryTitle'
  }
]
