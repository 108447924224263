
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IStockProfile } from '@/modules/seller-management/stock-profile/types/stockProfileTypes'
import { stockProfileEndpoints } from '@/modules/seller-management/stock-profile'

@Component({
  name: 'TheUpsertStockProfile',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertStockProfile extends Vue {
  loading = false
  data = {} as IStockProfile
  id = this.$route.params.id || ''
  originalData = {} as IStockProfile

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.stockProfile.edit')}`
      : `${this.$t('dialog.stockProfile.addNew')}`

  /**
   * function will be passed to child components
   */
  fields(): Field<IStockProfile>[] {
    return [
      Field.text<IStockProfile>('profileName', 'profileName')
        .setCol('col-12')
        .required(),
      Field.currency<IStockProfile>('upperLimit', 'upperLimit')
        .setProps({
          prefix: '%'
        })
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setRules(`between:0,100`)
        .setCol('col-12')
        .required(),
      Field.textarea<IStockProfile>('description', 'description').setCol(
        'col-12'
      )
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IStockProfile,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (this.originalData.upperLimit !== values.upperLimit)
      values.upperLimit = +(values.upperLimit / 100).toFixed(4)

    if (q.id) {
      await stockProfileEndpoints
        .patch(q.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await stockProfileEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await stockProfileEndpoints.get(id).then(res => {
          //on edit convert it to percentage format
          res.upperLimit = Number(
            ContentFormatter.$formatToPercentage(res.upperLimit)
          )
          return res
        })
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
