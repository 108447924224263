import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import ResetPassword from '@/modules/seller/components/ResetPassword.vue'
import { sellerBranchEndpoints, sellerEndpoints } from '@/modules/seller'
import { sellerPreviewComponents } from '@/modules/seller/helpers/previewComponents'
import { sellerBranchPreviewLogComponents } from '@/modules/seller/helpers/sellerBranchPreviewLogComponents'
import TheUpsertSellerBranch from '@/modules/seller/components/TheUpsertSellerBranch.vue'
import UpdateEstimationArrival from '@/modules/seller/components/UpdateEstimationArrival.vue'
import UpdateInternationalLogistic from '@/modules/seller/components/UpdateInternationalLogistic.vue'

/** Seller module routes */
export const sellerRoutes: RouteConfig[] = [
  {
    path: '/seller',
    name: 'seller',
    component: () =>
      import(
        /* webpackChunkName: "TheSellerChunk" */ '@/modules/seller/views/TheSeller.vue'
      ),
    meta: {
      title: 'navigations.sellerMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/seller/preview-details/:id',
    name: 'preview-seller',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewSellerChunk" */ '@/modules/seller/views/ThePreviewSeller.vue'
      ),
    meta: {
      title: 'navigations.sellerMetaTitle',
      parentRouteName: 'seller',
      logPage: {
        endpoint: sellerEndpoints,
        previewDynamicComponents: sellerPreviewComponents
      }
    }
  },
  {
    path: '/seller/upsert-seller/:id?/customerId/:customerId?',
    name: 'upsert-seller',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertSellerChunk" */ '@/modules/seller/views/TheUpsertSeller.vue'
      ),
    meta: {
      title: 'navigations.sellerMetaTitle',
      parentRouteName: 'seller'
    }
  },
  {
    path: '/seller/seller-management/:id',
    name: 'update-sellerManagement',
    component: () =>
      import(
        /* webpackChunkName: "SellerManagementChunk" */ '@/modules/seller/components/SellerManagement.vue'
      ),
    meta: {
      title: 'navigations.sellerMetaTitle',
      parentRouteName: 'seller'
    }
  },
  {
    path: '/seller/seller-branch/preview-details/:merchantId/:id',
    name: 'preview-sellerBranch',
    component: () =>
      import(
        /* webpackChunkName: "TheSellerBranchChunk" */ '@/modules/seller/components/TheSellerBranch.vue'
      ),
    meta: {
      title: 'navigations.sellerMetaTitle',
      parentRouteName: 'seller',
      logPage: {
        endpoint: sellerBranchEndpoints,
        previewDynamicComponents: sellerBranchPreviewLogComponents
      }
    }
  },
  {
    path: '/seller/seller-transaction/:trnId/:id/:instanceType',
    name: 'seller-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionChunk" */ '@/modules/transaction/views/Transaction.vue'
      ),
    meta: {
      title: 'navigations.sellerTransactionMetaTitle',
      parentRouteName: 'seller'
    }
  },
  {
    path: '/seller/seller-transaction/preview-details/:trnId/:id/:instanceType',
    name: 'preview-merchant-transaction',
    component: () =>
      import(
        /* webpackChunkName: "PreviewTransactionChunk" */ '@/modules/transaction/views/PreviewTransaction.vue'
      ),
    meta: {
      title: 'navigations.sellerTransactionMetaTitle',
      parentRouteName: 'seller-transaction'
    }
  },
  {
    path: '/seller/seller-transaction/:actionType/:trnId/:id/:instanceType',
    name: 'reload-merchant-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionActionChunk" */ '@/modules/transaction/views/TransactionAction.vue'
      ),
    meta: {
      title: 'navigations.sellerTransactionMetaTitle',
      parentRouteName: 'seller-transaction'
    }
  },
  {
    path: '/seller/seller-transaction/:actionType/:trnId/:id/:instanceType',
    name: 'cash-out-merchant-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionActionChunk" */ '@/modules/transaction/views/TransactionAction.vue'
      ),
    meta: {
      title: 'navigations.sellerTransactionMetaTitle',
      parentRouteName: 'seller-transaction'
    }
  }
]

export const sellerModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-seller',
    requiredParams: ['id'],
    onDelete: q => sellerEndpoints.delete(q.id.toString()),
    dialogTitle: 'sellerTitle'
  },
  {
    component: ResetPassword,
    persistent: true,
    name: 'reset-seller-password',
    requiredParams: ['id']
  },
  {
    component: TheUpsertSellerBranch,
    size: 700,
    persistent: true,
    name: 'edit-seller-branch',
    requiredParams: ['id']
  },
  {
    component: TheUpsertSellerBranch,
    size: 700,
    persistent: true,
    name: 'add-seller-branch',
    requiredParams: []
  },
  {
    component: UpdateEstimationArrival,
    persistent: true,
    name: 'edit-estimation-arrival',
    requiredParams: []
  },
  {
    component: UpdateInternationalLogistic,
    persistent: true,
    name: 'edit-international-logistic',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-seller-branch',
    requiredParams: ['id'],
    onDelete: q => sellerBranchEndpoints.delete(q.id.toString()),
    dialogTitle: 'sellerBranchTitle'
  }
]
