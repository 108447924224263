
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'

import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { transactionFeeEndpoints } from '@/modules/accounting/transaction-fee'
import {
  AmountType,
  IAmountType,
  ITransactionFee,
  ITypeTransaction,
  TypeTransaction
} from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'
import { AnyObject } from '@/shared/types/builtInTypes'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

@Component({
  name: 'TheUpsertTransactionFee',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertTransactionFee extends Vue {
  loading = false
  data = {} as ITransactionFee
  id = this.$route.params.id || ''
  originalData = {} as ITransactionFee

  typeTransactionList: ITypeTransaction[] = [
    {
      id: 1,
      name: TypeTransaction.fee
    },
    {
      id: 2,
      name: TypeTransaction.commission
    }
  ]

  amountTypeList: IAmountType[] = [
    {
      id: 1,
      name: AmountType.fixed
    },
    {
      id: 2,
      name: AmountType.percentage
    }
  ]

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.transactionFee.edit')}`
      : `${this.$t('dialog.transactionFee.addNew')}`

  /**
   * function will be passed to child components
   * @params transactionFee which modules type the fetched Transaction Fee will be passed to it in the child component. (base form)
   */
  fields(transactionFee: ITransactionFee): Field<ITransactionFee>[] {
    const isPercentage =
      transactionFee && transactionFee.amountType === AmountType.percentage

    return [
      Field.text<ITransactionFee>('label', 'label').setCol('col-12').required(),
      Field.dropdown<ITransactionFee>(
        'type',
        'type',
        () => Promise.resolve(this.typeTransactionList),
        (item: AnyObject) => `${this.$t('label.' + item.name)}`,
        'name'
      )
        .setCol('col-12')
        .required(),
      Field.dropdown<ITransactionFee>(
        'amountType',
        'amountType',
        () => Promise.resolve(this.amountTypeList),
        (item: AnyObject) => `${this.$t('label.' + item.name)}`,
        'name'
      )
        .setCol('col-12')
        .required(),
      Field.currency<ITransactionFee>('amount', 'amount')
        .setProps({
          prefix: isPercentage ? '%' : `${getCurrencySymbol()}`
        })
        .setRules(`${isPercentage ? `between:0,100` : ''}`)
        .setHidden(!transactionFee.amountType)
        .setCol('col-12')
        .required(),
      Field.text<ITransactionFee>('code', 'code').setCol('col-12')
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: ITransactionFee,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (
      this.originalData.amount !== values.amount &&
      values.amountType === AmountType.percentage
    )
      values.amount = +(values.amount / 100).toFixed(8)

    if (q.id) {
      await transactionFeeEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await transactionFeeEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await transactionFeeEndpoints.get(id).then(res => {
          //on edit check if it is amount type percentage convert it to percentage format
          if (res.amountType === AmountType.percentage)
            res.amount = Number(
              ContentFormatter.$formatToPercentage(res.amount)
            )
          return res
        })
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
