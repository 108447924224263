
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'

import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IWorkflowAction } from '@/modules/seller-management/workflow-action/types/workflowActionTypes'
import { workflowActionEndpoints } from '@/modules/seller-management/workflow-action'
import { registryEndpoints } from '@/modules/seller-management/registry'
import { workflowManagementListEndpoints } from '@/modules/seller-management/workflow-management'

@Component({
  name: 'TheUpsertWorkflowAction',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertWorkflowAction extends Vue {
  loading = false
  data = {} as IWorkflowAction
  id = this.$route.params.id || ''
  originalData = {} as IWorkflowAction

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.workflowAction.edit')}`
      : `${this.$t('dialog.workflowAction.addNew')}`

  /** function will be passed to child components */
  fields(): Field<IWorkflowAction>[] {
    return [
      Field.dropdown<IWorkflowAction>(
        'actions',
        'registryId',
        () => registryEndpoints.get('') as Promise<any>
      )
        .setCol('col-12')
        .required(),
      Field.dropdown<IWorkflowAction>(
        'workflow',
        'workflowId',
        () => workflowManagementListEndpoints.get('') as Promise<any>
      )
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IWorkflowAction,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await workflowActionEndpoints
        .patch(q.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await workflowActionEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await workflowActionEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
