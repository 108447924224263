
import { Component, Vue } from 'vue-property-decorator'
import { FooterLink } from '@/modules/login/types/loginTypes'
import appStore from '@/AppStore'

@Component({
  name: 'TheFooter'
})
export default class TheFooter extends Vue {
  fullYear: number = new Date().getFullYear()
  // Vuex Getters
  footerLinks: FooterLink[] = appStore.getters['footer/getFooterLinks']
}
