
import { Component, Vue } from 'vue-property-decorator'
import {
  IProductVariantOptions,
  IVariantItem,
  ProductType
} from '@/modules/inventory/product/types/productTypes'
import { Field } from '@/services/utils/baseForm'
import BasePage from '@/stories/base-page/BasePage.vue'
import { ImgType } from '@/shared/types/imageTypes'
import { Route } from 'vue-router'
import { freezeObject } from '@/shared/helpers/freezeObject'
import CustomButtons from '@/stories/custom-buttons/CustomButtons.vue'
import { getDiff } from '@/shared/helpers/getDifference'
import AppStore from '@/AppStore'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { productVariantEndpoints } from '@/modules/inventory/product-variant'
import {
  ICategory,
  IVariantCategory
} from '@/modules/inventory/category/types/categoryTypes'
import { categoryEndpoint } from '@/modules/inventory/category'
import { organizeImagesToBeFilesType } from '@/modules/inventory/product/helpers/organizeImagesToBeFilesType'
import eventBus from '@/eventBus'

@Component({
  name: 'TheUpsertProductVariant',
  components: {
    BasePage,
    CustomButtons,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class TheUpsertProductVariant extends Vue {
  loading = false
  data = {} as IVariantItem
  originalData = {} as IVariantItem
  categoryData = {} as ICategory
  submitLoading = false

  get variantFields() {
    return this.optionalAndRequiredVariant.map((variant, index) => {
      return Field.dropdown(
        variant.name,
        `variantOptions.` + variant.name,
        () => Promise.resolve(variant.variantOptions),
        'name',
        'codename'
      )
        .setSectionTitle(index === 0 ? 'productVariants' : '')
        .setCol('col-6')
        .required(variant?.required)
    })
  }

  get optionalAndRequiredVariant() {
    return [
      ...((this.categoryData?.optionalVariants as IVariantCategory[])?.map(
        variant => ({
          ...variant,
          required: false
        })
      ) ?? []),
      ...((this.categoryData?.requiredVariants as IVariantCategory[])?.map(
        variant => ({
          ...variant,
          required: true
        })
      ) ?? [])
    ]
  }

  bindSubmitTitle(params: Route['params'], query: Route['query']): string {
    return query.productVariantId ? 'update' : 'add'
  }

  isEdit(params: Route['params'], query: Route['query']): boolean {
    return !!query.productVariantId
  }

  async created() {
    await Promise.all([this.fetchRemoteData(), this.fetchCategoryData()])
  }

  async fetchRemoteData() {
    const productVariantId = parsePathQueryString(
      location.search
    )?.productVariantId

    this.loading = true
    try {
      if (productVariantId) {
        this.data = await productVariantEndpoints
          .getProductVariantById(
            productVariantId as string,
            this.$route?.params?.productId
          )
          .then((res: IVariantItem) => res)

        this.data.images = this.data.variantImages

        // Convert the format to sync with input values
        this.data.variantOptions = this.data.variantOptions.reduce(
          (obj: any, variant: IProductVariantOptions) => {
            obj[variant.name] = variant.value
            return obj
          },
          {}
        )
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  async fetchCategoryData() {
    this.loading = true
    try {
      if (this.$route.params.productCategoryId) {
        const res = await categoryEndpoint
          .get(this.$route.params.productCategoryId)
          .then((res: ICategory) => res)

        this.categoryData = res
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  /**
   * function will be passed to child components
   * @params item which modules type the fetched product will be passed to it in the child component. (base form)
   */
  fields(productVariant: IVariantItem) {
    return [
      //VARIANT
      ...this.variantFields,
      //INVENTORY
      Field.currency<IVariantItem>('price', 'price', [], true)
        .setCol('col-6')
        .required()
        .setProps({ disabled: !!productVariant.id })
        .setSectionTitle('inventory'),
      Field.currency<IVariantItem>('quantity', 'quantity')
        .setCol('col-6')
        .setProps({ disabled: !!productVariant.id })
        .required()
        .setProps({ decimalLength: 0 })
        .setRules('min_value:0'),
      Field.text<IVariantItem>('sellerSKU', 'sellerSku')
        .setCol('col-6')
        .required(),
      Field.text<IVariantItem>('barcode', 'barcode')
        .setCol('col-6')
        .setBreak(true)
        .required(),
      Field.currency<IVariantItem>('weightKg', 'weight')
        .setCol('col-6')
        .setHidden(this.$route.params.productType === ProductType.digital)
        .setBreak(true)
        .required(),
      //MEDIA
      Field.multiPhoto<IVariantItem>('media', 'images')
        .setProps({
          imageType: ImgType.file
        })
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: IVariantItem) {
    // Ensure values is defined, if not, use an empty object
    const safeValues = values || { variantOptions: {} }
    const differences = getDiff(
      this.originalData as Record<string, unknown>,
      safeValues as Record<string, unknown>
    ) as IVariantItem
    const { productVariantId } = parsePathQueryString(location.search)
    const preparedData = { ...differences }
    this.submitLoading = true

    try {
      // Only include variantOptions if they've changed or if we're creating a new variant
      if (differences?.variantOptions || !productVariantId) {
        const variantOptions = safeValues?.variantOptions || {}
        preparedData.variantOptions = Object.keys(variantOptions)
          .filter(key => variantOptions[key as keyof typeof variantOptions])
          .map(key => ({
            name: key,
            value: variantOptions[key as keyof typeof variantOptions]
          })) as []
        // Remove any individual variantOptions properties
        Object.keys(preparedData).forEach(key => {
          if (key.startsWith('variantOptions.')) {
            delete preparedData[key]
          }
        })
      } else {
        delete preparedData.variantOption
      }

      if (preparedData.images && preparedData.images.length > 0) {
        preparedData.images = await organizeImagesToBeFilesType(
          preparedData.images
        )
      }

      // Only proceed if there are actual changes or if we're creating a new variant
      if (Object.keys(preparedData).length === 0 && productVariantId) {
        AppStore.dispatch('notification/pushNotification', {
          type: 'error',
          messages: 'No changes detected'
        })
        this.submitLoading = false
        return
      }

      if (productVariantId) {
        await productVariantEndpoints.updateProductVariant(
          preparedData,
          productVariantId,
          this.$route.params?.productId
        )
      } else {
        // Check if product type is digital and set weight to zero

        if (this.$route.params.productType === ProductType.digital) {
          preparedData.weight = 0
        }
        await productVariantEndpoints.addProductVariant(
          preparedData,
          this.$route.params?.productId
        )
      }

      // If we reach this point, it means the request was successful
      this.closePopUp()
      eventBus.refreshData()
    } finally {
      this.submitLoading = false
    }
  }

  /** closePopUp function pass to the baseModal component*/
  closePopUp() {
    popUpRoute.removeModalPath(['productVariantId'])
  }
}
