import { ApiService } from '@/services/api/apiService'
import { ApiListResponse } from '@/shared/types/apiListResponse'
import axios from 'axios'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import { ILog } from '@/shared/types/logTypes'
import { FilterSchema } from '@/shared/types/FilterTypes'
import { stringToCamelCase } from '@/shared/helpers/stringCaseHelpers'
import { IBalance } from '@/modules/transaction/types/transactionTypes'

/**
 * Read only api service that contains only get methods.
 */
export class ReadOnlyApi<T> extends ApiService {
  constructor(resource: string, modelName = '') {
    super(resource, modelName)
  }

  /**
   * Data table method that gets data list with pagination that is suitable for
   * Vue data table.
   * @param params Request parameters that will be appended to the endpoint.
   */
  dataTable(params = {}): Promise<ApiListResponse<T>> {
    return new Promise((resolve, reject) => {
      axios
        .get(this.getUrl(), {
          params: this.transformOptions(params)
        })
        .then(response => {
          this.dismissErrorNotification()
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Logs Data table method that gets data list with pagination that is suitable for
   * Vue log data table.
   * @param params Request parameters that will be appended to the endpoint.
   * @param recordId
   */
  logsDataTable(
    recordId: Id,
    params = {}
  ): Promise<ApiListResponse<ILog<AnyObject>>> {
    return axios
      .get(`/log/${this.getUrl()}`, {
        params: { recordId, ...this.transformOptions(params) }
      })
      .then(r => r.data)
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
        throw error
      })
  }

  /**
   * List method that gets data list without pagination and that is suitable for
   * Vue VLists like (VAutoComplete, VSelect, and VComboBox)
   * @param params Request parameters that will be appended to the endpoint.
   */
  list(params = {}): Promise<T[]> {
    return axios
      .get(`${this.getUrl()}${this.resource}_list/`, params)
      .then(response => {
        return response.data
      })
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
        throw error.response.data
      })
  }

  /**
   * Get a record of information from the backend.
   * @param id Id of the selected record.
   */
  get(id: number | string): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .get(this.getUrl(id))
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Get a record of information from the backend.
   * @param id Id of the selected record.
   */
  logsGet(id: number | string): Promise<ILog<AnyObject>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/log/${this.getUrl(id)}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Gets the balance
   * @param id
   */
  getBalance(id: string | number): Promise<IBalance> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   *Getting the filed that can be filtered
   * @param endpoint
   **/
  getFilterFields(endpoint = '') {
    return new Promise((resolve, reject) => {
      axios
        .options(`${endpoint}/`)
        .then(response => {
          const options = response.data

          // convert key values to camel case
          options.filterableFields.forEach((f: FilterSchema) => {
            f.itemValue = stringToCamelCase(f.itemValue || 'id')
            f.itemText = stringToCamelCase(f.itemText || 'name')
          })

          resolve(options.filterableFields)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
