import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { localizationEndpoints } from '@/modules/preference/localization'
import TheUpsertLocalization from '@/modules/preference/localization/views/TheUpsertLocalization.vue'
import ImportLocalization from '@/modules/preference/localization/components/ImportLocalization.vue'
import { localizationPreviewComponents } from '@/modules/preference/localization/helpers/previewComponents'
import ExportLocalization from '@/modules/preference/localization/components/ExportLocalization.vue'

/**
 * Localization module routes
 */
export const localizationRoutes: RouteConfig[] = [
  {
    path: '/localization',
    name: 'localization',
    component: () =>
      import(
        /* webpackChunkName: "TheLocalizationChunk" */ '@/modules/preference/localization/views/TheLocalization.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.localizationMetaTitle'
    }
  },
  {
    path: '/localization/preview-details/:id',
    name: 'preview-localization',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewLocalizationChunk" */ '@/modules/preference/localization/views/ThePreviewLocalization.vue'
      ),
    meta: {
      title: 'navigations.localizationMetaTitle',
      parentRouteName: 'localization',
      logPage: {
        endpoint: localizationEndpoints,
        previewDynamicComponents: localizationPreviewComponents
      }
    }
  }
]

export const localizationModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertLocalization,
    size: 600,
    persistent: true,
    name: 'edit-localization',
    requiredParams: ['id']
  },
  {
    component: TheUpsertLocalization,
    size: 600,
    persistent: true,
    name: 'add-localization',
    requiredParams: []
  },
  {
    component: ImportLocalization,
    size: 600,
    persistent: true,
    name: 'import-localization',
    requiredParams: []
  },
  {
    component: ExportLocalization,
    size: 600,
    persistent: true,
    name: 'export-localization',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-localization',
    requiredParams: ['id'],
    onDelete: q => localizationEndpoints.delete(q.id.toString()),
    dialogTitle: 'localizationTitle'
  }
]
