
import { Component, Vue } from 'vue-property-decorator'
import { IStaff } from '@/modules/staff/types/staffTypes'
import { popUpRoute } from '@/services/utils/popupRoute'
import { staffEndpoints } from '@/modules/staff'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'

@Component({
  name: 'ResetPassword',
  components: {
    PreviewAvatar,
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ResetPassword extends Vue {
  loading = false
  isResetBtnLoading = false
  staffData = { isActive: true } as IStaff
  data = {} as IStaff

  /** submit the data to the desired endpoint. */
  async handleSubmit() {
    const { id } = parsePathQueryString(location.search)
    this.isResetBtnLoading = true
    if (id)
      await staffEndpoints
        .setPassword(id)
        .then(() => {
          this.isResetBtnLoading = false
          this.closePopUp()
        })
        .finally(() => (this.isResetBtnLoading = false))
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    if (id)
      try {
        this.staffData = await staffEndpoints.get(id)
      } catch (error) {
        throw new Error(`Failed to fetch form data. Due to ${error}`)
      } finally {
        this.loading = false
      }
    else throw new Error('Please, Provide User ID ')
  }
}
