
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IBreadcrumb } from '@/shared/types/basePage'
import { AnyObject } from '@/shared/types/builtInTypes'
import { newBreadcrumbFactory } from '@/services/utils/serviceRoutes'
import { Route, RouteConfig } from 'vue-router'
import { generateDynamicTitle } from '@/shared/helpers/generateDynamicTitle'
import appRouter from '@/AppRouter'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { TranslateResult } from 'vue-i18n'

@Component({
  name: 'BasePage',
  components: {
    titleBreadcrumb: () =>
      import(
        /* webpackChunkName:  "TitleBreadcrumbChunk" */ '@/stories/title-breadcrumb/TitleBreadcrumb.vue'
      )
  }
})
export default class BasePage extends Vue {
  /**
   * Display the page title
   */
  @Prop([String, Function]) readonly title?:
    | string
    | TranslateResult
    | ((
        params: Route['params'],
        queries: Route['query'],
        remoteDataFetchers: Partial<AnyObject>
      ) => string | TranslateResult)

  /**
   * Add a loading while handle the data.
   */
  @Prop({ type: Boolean, default: false })
  readonly loading?: boolean

  /**
   * Set title for the banner
   */
  @Prop(String) readonly bannerTitle?: string

  /**
   * Display balance in the right of the banner
   */
  @Prop(Number) readonly balance?: number

  /**
   * Adds a loading while handle the banner data.
   */
  @Prop({ type: Boolean, default: false })
  readonly bannerLoading?: boolean

  /**
   * Add a permission to decide when display banner
   */
  @Prop({ type: Boolean, default: false })
  readonly bannerPermit?: boolean

  /**
   * Contain data that fetched from API
   * Which we using it to generate page title and breadcrumb
   */
  @Prop({ type: Object, default: () => ({}) })
  remoteData?: AnyObject

  // Get breadcrumb dynamically from routes
  get breadcrumb() {
    const breadcrumb = newBreadcrumbFactory(
      appRouter.currentRoute as RouteConfig,
      appRouter.currentRoute.params,
      this.remoteData as AnyObject
    )
    if (breadcrumb.length > 1) {
      breadcrumb[breadcrumb.length - 1].disabled = true

      // If the title prop passed immediately use it for showing the last breadcrumb.
      // It will be more useful when you use the same route to many pages especially upsert page
      if (this.getTitle) {
        breadcrumb[breadcrumb.length - 1].text = this.getTitle as string
      }
    }

    return breadcrumb.length > 1
      ? breadcrumb.map((br: IBreadcrumb) => {
          return { ...br, text: this.$t(`page.${br.text}`) }
        })
      : []
  }

  get getTitle(): string {
    if (this.title) {
      return generateDynamicTitle(
        this.title,
        this.$route?.params,
        this.remoteData
      )
    }
    return this.$route?.name || ''
  }

  get formatItemCurrency() {
    return ContentFormatter.$formatToCurrency(this.balance, true)
  }

  /**
   * to add page. prefix in order to translate the string
   */
  addPageLabel(text: string): string {
    return 'page.' + text
  }
}
