/**
 * @param items contains the all items of details
 * @param item contains the name of key to transfer to the array
 * @return list of number
 */
export function changeObjectsValToArray<T>(
  items: Array<T>,
  item: string
): number[] {
  const newVal: number[] = []
  Object.values(items).forEach(values => {
    Object.entries(values).forEach(([key, value]) => {
      if (key === item) {
        newVal.push(value as number)
      }
    })
  })
  return newVal
}
