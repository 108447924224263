import { SellerEndpoints } from '@/modules/seller/api/sellerEndpoints'
import { ISeller, ISellerBranch } from '@/modules/seller/types/sellerTypes'

/**
 * seller endpoint instance
 * instantiate seller api class with a different api path
 */
export const sellerEndpoints = new SellerEndpoints<ISeller>('merchant')
export const sellerBranchEndpoints = new SellerEndpoints<ISellerBranch>(
  'merchant_branch'
)
