import axios from 'axios'
import appStore from '@/AppStore'

export function uploadImagesAndConvertToFile(imagePath = ''): Promise<File> {
  const startIndex = imagePath.lastIndexOf('/') + 1
  const fileName = imagePath.slice(startIndex)

  return new Promise(resolve => {
    axios
      .get(imagePath, {
        responseType: 'blob'
      })
      .then(res => {
        if (res.data instanceof Blob && res.data) {
          const imgFile = new File([res.data], fileName)
          resolve(imgFile)
        }
      })
      .catch(() => {
        appStore
          .dispatch('notification/pushNotification', {
            type: 'error',
            messages: 'Failed to fetch image due to Invalid URL'
          })
          .finally()
      })
  })
}
