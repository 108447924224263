import { ModelApi } from '@/services/api/modelApi'
import { IEstimationArrival } from '@/modules/seller-management/estimation-arrival/types/estimationArrivalTypes'

/**
 * Estimation Arrival endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class EstimationArrivalEndpoints extends ModelApi<IEstimationArrival> {
  constructor() {
    super('estimation_arrival')
  }
}
