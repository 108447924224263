
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import eventBus from '@/eventBus'
import { sellerRequestEndpoints } from '@/modules/request/seller-request'
import {
  ISellerRequest,
  RequestStatus,
  requestStatusText
} from '@/modules/request/seller-request/types/sellerRequestTypes'

@Component({
  name: 'TheUpdateStatusSellerRequest',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpdateStatusSellerRequest extends Vue {
  loading = false
  trackingId = this.$route.params.trackingId || ''

  requestStatusList = [
    {
      id: RequestStatus.APPROVED,
      name: requestStatusText.approved
    },
    {
      id: RequestStatus.REVISED,
      name: requestStatusText.revised
    },
    {
      id: RequestStatus.REJECTED,
      name: requestStatusText.rejected
    }
  ]
  /** function will be passed to child components */
  fields(sellerRequest: ISellerRequest): Field<ISellerRequest>[] {
    return [
      Field.dropdown<ISellerRequest>('status', 'status', () =>
        Promise.resolve(this.requestStatusList)
      )
        .setCol('col-12')
        .required(),
      Field.textarea<ISellerRequest>('note', 'note').required(
        sellerRequest.status === RequestStatus.REVISED
      )
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: ISellerRequest,
    p: Record<string, number>,
    q: Record<string, number>
  ) {
    const payload = {
      status: values.status,
      note: values.note
    }
    values.note && Object.assign(payload, { note: values.note })

    if (p.id)
      await sellerRequestEndpoints
        .updateStatus(p.id, payload, q.elementIds)
        .then(() => {
          popUpRoute.removeModalPath(['trackingId', 'elementIds'])
          this.$router.back()
        })
    eventBus.refreshData()
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['trackingId', 'elementIds'])
  }
}
