/** Seller interface type based on backend api response */
import { Id, IMetadata } from '@/shared/types/builtInTypes'
import {
  AmountType,
  ICommissionFeeList,
  ITransferFeeList
} from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'
import { ICityList } from '@/modules/preference/city/types/cityTypes'
import { ICountryList } from '@/modules/seller-management/country/types/countryTypes'
import { IDiscountProfile } from '@/modules/seller-management/discount-profile/types/discountProfileTypes'
import { IStockProfile } from '@/modules/seller-management/stock-profile/types/stockProfileTypes'
import { IEscalationProfile } from '@/modules/seller-management/escalation-profile/types/escalationProfileTypes'
import { IPricingLimitProfile } from '@/modules/seller-management/pricing-limit-profile/types/pricingLimitProfileTypes'
import { IEstimationArrival } from '@/modules/seller-management/estimation-arrival/types/estimationArrivalTypes'
import { IInternationalLogistic } from '@/modules/seller-management/international-logistic/types/internationalLogisticTypes'
import { ICommission } from '@/modules/accounting/commission/types/commissionTypes'

export interface ICashoutCommission extends ICommissionFeeList {
  amount: number
  amountType: AmountType
}

export interface ITransferFee extends ITransferFeeList {
  amount: number
  amountType: AmountType
}

export enum IDType {
  NATIONAL_ID = 'national_id',
  PASSPORT = 'passport'
}
export const idTypeText: Record<IDType | string, string> = {
  [IDType.NATIONAL_ID]: 'National ID',
  [IDType.PASSPORT]: 'Passport'
}

export enum UserType {
  CUSTOMER = 'customer',
  SELLER = 'merchant'
}

export interface ISellerManagement {
  cashOutCommissionId: Id
  cashOutCommission: ICashoutCommission
  transferFeeId: Id
  transferFee: ITransferFee
  merchantCommissionId: Id
  merchantCommission: ICommission
  discountProfileId: Id
  discountProfile: Pick<
    IDiscountProfile,
    'id' | 'profileName' | 'percentageAmount' | 'fixedAmount'
  >
  stockProfileId: Id
  stockProfile: Pick<IStockProfile, 'id' | 'profileName' | 'upperLimit'>
  escalationProfileId: Id
  escalationProfile: Pick<
    IEscalationProfile,
    'id' | 'profileName' | 'orderProcessingDelay' | 'orderPackagingDelay'
  >
  estimationArrival: Pick<IEstimationArrival, 'minEstimation' | 'maxEstimation'>
  internationalLogistic: Pick<IInternationalLogistic, 'cost'>
  pricingProfileId: Id
  pricingProfile: Pick<
    IPricingLimitProfile,
    'id' | 'profileName' | 'upperLimit' | 'lowerLimit'
  >
}

export interface ISeller extends IMetadata, ISellerManagement {
  id: Id
  firstName: string
  lastName: string
  fullName: string
  phone: string
  secondaryPhone: string
  email: string
  gender: string
  arbelaBalance: number
  isStaff: boolean
  isActive: boolean
  type: UserType
  country: ICountryList
  countryId: Id
  address: string
  articlesOfAssociation: string
  bankAccountOwner: string
  bankName: string
  businessLicense: string
  companyAddress: string
  companyEmail: string
  companyLogo: string
  companyName: string
  language: {
    id: Id
    name: string
    symbol: string
  }
  languageId: Id
  description: string
  iban: string
  returnAddress: string
  storeName: string
  swift: string
  idBack: string
  idFront: string
  idNumber: string
  idType: IDType
  customerId?: Id
  password: string
  confirmPassword: string
  //local key
  sellerType?: UserType
}

export interface ISellerBranch extends IMetadata {
  id: Id
  name: string
  description: string
  address: string
  merchantId: Id
  city: ICityList
  cityId: Id
}
