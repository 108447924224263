import { RouteConfig } from 'vue-router'
import { stockEndpoints } from '@/modules/inventory/stock'
import { stockPreviewComponents } from '@/modules/inventory/stock/helpers/previewComponents'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import ImportFile from '@/modules/celery-task/components/ImportFile.vue'
import ExportFile from '@/modules/celery-task/components/ExportFile.vue'

/** Stock And Price module routes */
export const stockRoutes: RouteConfig[] = [
  {
    path: '/inventory/stock-and-price',
    name: 'stockAndPrice',
    component: () =>
      import(/*TheStockChunk*/ '@/modules/inventory/stock/views/TheStock.vue'),
    meta: {
      parentRouteName: null,
      title: 'navigations.stockAndPriceMetaTitle'
    }
  },
  {
    path: '/inventory/stock-and-price/celery-stock-and-price/:celeryApiType',
    name: 'celery-stock-and-price',
    component: () =>
      import(
        /*TheCeleryTaskChunk*/ '@/modules/celery-task/views/TheCeleryTask.vue'
      ),
    meta: {
      parentRouteName: 'stockAndPrice',
      title: 'navigations.stockAndPriceMetaTitle'
    }
  },
  {
    path: '/inventory/stock-and-price/preview-details/:id',
    name: 'preview-stock-and-price',
    component: () =>
      import(
        /*ThePreviewStockChunk*/ '@/modules/inventory/stock/views/ThePreviewStock.vue'
      ),
    meta: {
      title: 'navigations.stockAndPriceMetaTitle',
      parentRouteName: 'stockAndPrice',
      logPage: {
        endpoint: stockEndpoints,
        previewDynamicComponents: stockPreviewComponents
      }
    }
  },
  {
    path: '/inventory/stock-and-price/update-price-and-quantity/:id?',
    name: 'update-stock-price-and-quantity',
    component: () =>
      import(
        /*TheUpdateStockQuantityChunk*/ '@/modules/inventory/stock/views/TheUpdateStockQuantity.vue'
      ),
    meta: {
      title: 'navigations.stockAndPriceMetaTitle',
      parentRouteName: 'stockAndPrice'
    }
  }
]
export const stockModalRoute: PopUpRouteConfig[] = [
  {
    component: ImportFile,
    size: 600,
    persistent: true,
    name: 'import-stock_and_price',
    requiredParams: []
  },
  {
    component: ExportFile,
    size: 600,
    persistent: true,
    name: 'export-stock_and_price',
    requiredParams: []
  }
]
