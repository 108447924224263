import { ModelApi } from '@/services/api/modelApi'
import {
  IProduct,
  IProductVariantOptions,
  IVariantItem
} from '@/modules/inventory/product/types/productTypes'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import {
  addProductToFormData,
  addToFormData
} from '@/modules/inventory/product/api/productEndpoints'

/** Product variant endpoint extends model api to inherit its methods and custom methods up to its needs */
export class ProductVariantEndpoints extends ModelApi<IProduct> {
  constructor() {
    super('product_variant')
  }

  /**
   * Note: please refactor this into a helper function.
   * make the error more readable
   * @param errorData
   * @param parentKey
   */
  extractErrorMessages(errorData: AnyObject, parentKey = '') {
    const messages: unknown[] = []

    function traverse(data: AnyObject, keyPath: string) {
      if (typeof data === 'string') {
        messages.push(`${keyPath}: ${data}`)
      } else if (Array.isArray(data)) {
        data.forEach(item => traverse(item, keyPath))
      } else if (typeof data === 'object' && data !== null) {
        Object.entries(data).forEach(([key, value]) => {
          traverse(value as AnyObject, key)
        })
      }
    }

    traverse(errorData, parentKey)
    return messages.join(' ')
  }

  /**
   * get product variants
   * @param productId
   **/
  getProductVariant(productId: Id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`product_variant/?product_id=${productId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * get product variant bt Id
   * @param variantId
   * @param productVariantId
   **/
  getProductVariantById(
    variantId: Id,
    productVariantId: Id
  ): Promise<IVariantItem> {
    return new Promise((resolve, reject) => {
      axios
        .get(`product_variant/${variantId}/?product_id=${productVariantId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  async addProductVariant(data: IVariantItem, productId: string) {
    const { variantOptions, ...resValues } = data
    const variantDataSnake = snakeCaseKeys(resValues as any, {
      deep: false
    })
    const formData = new FormData()
    addProductToFormData(variantDataSnake, formData)
    variantOptions?.length !== 0
      ? variantOptions.forEach(
          (variant: IProductVariantOptions, index: number) => {
            addToFormData(variant, formData, 'variant_options', index)
          }
        )
      : addToFormData(['[]'], formData, 'variant_options', 0)

    return new Promise((resolve, reject) => {
      axios
        .post(`/product_variant/?product_id=${productId}`, formData)
        .then(() => {
          this.responseNotify('notifications.addedSuccessfully')
          resolve({})
        })
        .catch(error => {
          this.responseNotify(
            this.extractErrorMessages(error.response.data),
            'error'
          )
          reject({})
        })
    })
  }

  async updateProductVariant(
    data: IVariantItem,
    variantId: string,
    productId: string
  ) {
    const { variantOptions, ...resValues } = data
    const variantDataSnake = snakeCaseKeys(resValues as any, {
      deep: false
    })
    const formData = new FormData()
    addProductToFormData(variantDataSnake, formData)

    variantOptions?.length !== 0
      ? variantOptions?.forEach(
          (variant: IProductVariantOptions, index: number) => {
            addToFormData(variant, formData, 'variant_options', index)
          }
        )
      : addToFormData(['[]'], formData, 'variant_options', 0)

    try {
      await axios.patch(
        `/product_variant/${variantId}/?product_id=${productId}`,
        formData
      )
      this.responseNotify('notifications.updatedSuccessfully')
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMessage = this.extractErrorMessages(error.response.data)
        this.responseNotify(errorMessage, 'error')
        throw new Error(errorMessage) // Throw the error with the specific message
      }
    }
  }
}
