import {
  IPreviewComponents,
  PreviewComponentType
} from '@/shared/types/basePreviewTypes'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import { TranslateResult } from 'vue-i18n'
import { createImagePath } from '@/shared/helpers/createImagePath'
import { ISimpleTableHeader } from '@/shared/types/simpleTableTypes'

export class PreviewComponent<T> {
  private type: PreviewComponentType
  private key: keyof T
  private props: AnyObject = {}
  private hidden?: boolean
  private break?: boolean
  private title?: string
  private items?: () => Promise<unknown[]>

  constructor(type: PreviewComponentType, key: keyof T) {
    this.type = type
    this.key = key
  }

  // ############################### Builders
  static labelValue<T>(
    label: string,
    key: keyof T,
    value:
      | string
      | (() => string)
      | TranslateResult
      | boolean
      | number
      | undefined
  ) {
    const newPreview = new PreviewComponent<T>(
      PreviewComponentType.labelValue,
      key
    )
    newPreview.props.label = label
    newPreview.props.value = value

    return newPreview
  }

  static photo<T>(key: keyof T, src: string | (() => string)) {
    const newPreview = new PreviewComponent<T>(PreviewComponentType.image, key)
    newPreview.props.src = src
    return newPreview
  }

  static multiPhoto<T>(key: keyof T, src: string[] | (() => string)) {
    const newPreview = new PreviewComponent<T>(
      PreviewComponentType.multiImage,
      key
    )
    newPreview.props.imagesSrc = src
    return newPreview
  }

  static avatar<T>(
    key: keyof T,
    profileImage: string,
    gender = 'm',
    size = 150,
    outlineColor?: string
  ) {
    const newPreview = new PreviewComponent(PreviewComponentType.avatar, key)
    newPreview.props.profileImage = createImagePath(profileImage)
    newPreview.props.size = size
    newPreview.props.outlineColor = outlineColor
    newPreview.props.gender = gender

    return newPreview
  }

  static table<T>(
    key: keyof T,
    headers: ISimpleTableHeader<T>[],
    data: AnyObject[]
  ) {
    const newPreview = new PreviewComponent(PreviewComponentType.table, key)
    newPreview.props.headers = headers
    newPreview.props.value = data
    return newPreview
  }

  static flagIcon<T>(key: keyof T, countryCode: string | (() => string)) {
    const newPreview = new PreviewComponent<T>(
      PreviewComponentType.flagIcon,
      key
    )
    newPreview.props.countryCode = countryCode
    return newPreview
  }

  static fileLink<T>(label: string, key: keyof T, link: string) {
    const newPreview = new PreviewComponent<T>(
      PreviewComponentType.fileLink,
      key
    )
    newPreview.props.label = label
    newPreview.props.link = link

    return newPreview
  }

  static treeView<T>(
    key: keyof T,
    value: Id[],
    items: IPreviewComponents<T>['items'],
    itemKey: string,
    itemText: string
  ) {
    const newPreview = new PreviewComponent<T>(
      PreviewComponentType.treeView,
      key
    )

    newPreview.props.value = value
    newPreview.items = items
    newPreview.props.itemKey = itemKey
    newPreview.props.itemText = itemText
    newPreview.props.selectable = true
    newPreview.props.disabled = true
    newPreview.props.selectedColor = 'dark'
    return newPreview
  }

  // ############################ Modifiers
  loading(isLoading: boolean) {
    this.props.loading = isLoading
    return this
  }

  setProp(newProps: AnyObject) {
    this.props = { ...this.props, ...newProps }
    return this
  }

  setClass(cl: string) {
    this.props.valueClass = cl //Todo this prop should be 'class' to apply in general, should be improve it. for now it's working.
    return this
  }

  setHidden(isHidden: boolean) {
    this.hidden = isHidden
    return this
  }

  setCol(col: string) {
    this.props.cols = col
    return this
  }

  setTitle(title = '') {
    this.title = title
    return this
  }

  setBreak(point = true) {
    this.break = point
    return this
  }
}
