import { PreviewComponent } from '@/services/utils/basePreview'
import { ITax } from '@/modules/accounting/tax/types/taxTypes'
import i18n from '@/plugins/i18n'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

/**
 * dynamic component generator method
 * @param  tax is ITax interface type which
 * @return An Array of IPreviewComponent tax type
 */
export function taxPreviewComponents(tax: ITax): PreviewComponent<ITax>[] {
  return [
    PreviewComponent.labelValue<ITax>(
      'valueAddedTax',
      'valueAddedTax',
      ContentFormatter.$formatToCurrency(tax.valueAddedTax, true)
    )
      .setCol('pa-3 col-3')
      .setTitle('taxDetails'),
    PreviewComponent.table<ITax>(
      'details',
      [
        {
          text: `${i18n.t('header.moreThan')}  (${getCurrencySymbol()})`,
          value: 'moreThan',
          key: 'moreThanes',
          formatValue: 'currency',
          width: '200px'
        },
        {
          text: `${i18n.t('header.lessThanOrEqual')}  (${getCurrencySymbol()})`,
          value: 'lessThanOrEqual',
          key: 'lessThanOrEquals',
          formatValue: 'currency',
          width: '200px'
        },
        {
          text: `${i18n.t('header.rate')} (${getCurrencySymbol()})`,
          value: 'rate',
          key: 'rates',
          formatValue: 'currency',
          width: '200px'
        }
      ],
      tax.details
    )
      .setProp({ class: 'col-12' })
      .setTitle('tax'),
    ...metadataLabelValues(tax)
  ]
}
