import { ModelApi } from '@/services/api/modelApi'
import { IBrand } from '@/modules/preference/brand/type/brandTypes'

/**
 * Brand endpoint extends model api to inherit its methods and custom methods up to its needs
 */
export class BrandEndpoints extends ModelApi<IBrand> {
  constructor() {
    super('brand')
  }
}
