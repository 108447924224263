
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { escalationProfileEndpoints } from '@/modules/seller-management/escalation-profile'
import { IEscalationProfile } from '@/modules/seller-management/escalation-profile/types/escalationProfileTypes'

@Component({
  name: 'TheUpsertEscalationProfile',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertEscalationProfile extends Vue {
  loading = false
  data = {} as IEscalationProfile
  id = this.$route.params.id || ''
  originalData = {} as IEscalationProfile

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.escalationProfile.edit')}`
      : `${this.$t('dialog.escalationProfile.addNew')}`

  /**
   * function will be passed to child components
   * @param escalationProfile
   */
  fields(escalationProfile: IEscalationProfile): Field<IEscalationProfile>[] {
    return [
      Field.text<IEscalationProfile>('profileName', 'profileName')
        .setCol('col-12')
        .required(),
      Field.text<IEscalationProfile>(
        'orderProcessingDelayDay',
        'orderProcessingDelay'
      )
        .setProps({
          type: 'number'
        })
        .setRules('min_value:0')
        .setCol('col-12')
        .required(),
      Field.text<IEscalationProfile>(
        'orderPackagingDelayDay',
        'orderPackagingDelay'
      )
        .setProps({
          type: 'number'
        })
        .setRules(
          `min_value:${Number(escalationProfile.orderProcessingDelay) + 1}`
        )
        .setCol('col-12')
        .required(),
      Field.textarea<IEscalationProfile>('description', 'description').setCol(
        'col-12'
      )
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IEscalationProfile,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await escalationProfileEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await escalationProfileEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await escalationProfileEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
