import { AnyObject } from '@/shared/types/builtInTypes'

export function setValueAtKeyPath(
  object: AnyObject,
  keyPath: string,
  value: unknown
): void {
  const keys = keyPath.split('.')
  let ref = object
  keys.forEach((key, i) => {
    // if this is the last key, assign the key to the value
    if (i === keys.length - 1) {
      ref[key] = value
    } else {
      // if the key on the object is undefined or null, assign object
      if (object[key] === undefined || object[key] === null) {
        object[key] = {}
      }

      // hold a reference to the current path on object
      ref = object[key] as AnyObject
    }
  })
}
