import { ModelApi } from '@/services/api/modelApi'
import { IBatch } from '@/modules/arbela-gift-card/batch/types/batchTypes'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import axios from 'axios'

/**
 * Batch endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class BatchEndpoints extends ModelApi<IBatch> {
  constructor() {
    super('batch')
  }

  /**
   * Update status of each batch
   * @param id
   * @param payload
   **/
  setBatchStatus(id: Id, payload: AnyObject = {}): Promise<IBatch> {
    return new Promise((resolve, reject) => {
      axios
        .post(`batch/${id}/set_status/`, payload)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Export each batch as csv file
   * @param batchId
   */
  async exportBatch(batchId: Id) {
    return axios
      .post(`/batch/${batchId}/export_batch/`, {}, { responseType: 'blob' })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const today = new Date()
          .toISOString()
          .substring(0, 10)
          .replace('_', '-')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Gift-Card-Batches-${today}.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
      .catch(async error => {
        //`.text()` method is used to read the contents of a Blob object as text.
        const errorResponse = await error.response.text()
        const parseResponse = JSON.parse(errorResponse)
        this.responseNotify(parseResponse.data.detail, 'error')
      })
  }
}
