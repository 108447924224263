
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IPricingLimitProfile } from '@/modules/seller-management/pricing-limit-profile/types/pricingLimitProfileTypes'
import { pricingLimitProfileEndpoints } from '@/modules/seller-management/pricing-limit-profile'

@Component({
  name: 'TheUpsertPricingLimitProfile',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertPricingLimitProfile extends Vue {
  loading = false
  data = {} as IPricingLimitProfile
  id = this.$route.params.id || ''
  originalData = {} as IPricingLimitProfile

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.pricingLimitProfile.edit')}`
      : `${this.$t('dialog.pricingLimitProfile.addNew')}`

  /** function will be passed to child components */
  fields(
    pricingLimitProfile: IPricingLimitProfile
  ): Field<IPricingLimitProfile>[] {
    return [
      Field.text<IPricingLimitProfile>('profileName', 'profileName')
        .setCol('col-12')
        .required(),
      Field.currency<IPricingLimitProfile>('upperLimit', 'upperLimit')
        .setProps({
          prefix: '%'
        })
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setRules(`between:0,100`)
        .setCol('col-12')
        .required(),
      Field.currency<IPricingLimitProfile>('lowerLimit', 'lowerLimit')
        .setProps({
          prefix: '%'
        })
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setRules(
          `between:0,100|max_value:${
            Number(pricingLimitProfile.upperLimit) - 0.1
          }`
        )
        .setCol('col-12')
        .required(),
      Field.textarea<IPricingLimitProfile>('description', 'description').setCol(
        'col-12'
      )
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IPricingLimitProfile,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (this.originalData.upperLimit !== values.upperLimit)
      values.upperLimit = +(values.upperLimit / 100).toFixed(4)

    if (this.originalData.lowerLimit !== values.lowerLimit)
      values.lowerLimit = +(values.lowerLimit / 100).toFixed(4)

    if (q.id) {
      await pricingLimitProfileEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await pricingLimitProfileEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await pricingLimitProfileEndpoints.get(id).then(res => {
          //on edit convert it to percentage format
          res.upperLimit = Number(
            ContentFormatter.$formatToPercentage(res.upperLimit)
          )
          res.lowerLimit = Number(
            ContentFormatter.$formatToPercentage(res.lowerLimit)
          )
          return res
        })
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
