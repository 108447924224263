import { PreviewComponent } from '@/services/utils/basePreview'
import { IRoles } from '@/modules/preference/role/types/roleTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { roleEndpoints } from '@/modules/preference/role'

/**
 * dynamic component generator method
 * @param roles is IRoles interface type which
 * @return An Array of IPreviewComponent roles type
 */
export function rolesPreviewComponents(
  roles: IRoles
): PreviewComponent<IRoles>[] {
  return [
    PreviewComponent.labelValue<IRoles>('roleName', 'name', roles.name),
    ...metadataLabelValues(roles),
    PreviewComponent.treeView<IRoles>(
      'permissionIds',
      roles.permissionIds,
      () => roleEndpoints.getAllPermissions(),
      'id',
      'codename'
    )
      .setCol('6')
      .setProp({
        translatedKeyPrefix: 'permissions.',
        openOnClick: true
      })
      .setTitle('grantedPermissions')
  ]
}
