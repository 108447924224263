import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertInternationalLogistic from '@/modules/seller-management/international-logistic/views/TheUpsertInternationalLogistic.vue'
import { internationalLogisticEndpoints } from '@/modules/seller-management/international-logistic'
import { internationalLogisticPreviewComponents } from '@/modules/seller-management/international-logistic/helpers/previewComponents'

/** International Logistic module routes*/
export const internationalLogisticRoutes: RouteConfig[] = [
  {
    path: '/international-logistic',
    name: 'internationalLogistic',
    component: () =>
      import(
        /* webpackChunkName: "TheInternationalLogisticChunk" */ '@/modules/seller-management/international-logistic/views/TheInternationalLogistic.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.internationalLogisticMetaTitle'
    }
  },
  {
    path: '/international-logistic/preview-details/:id',
    name: 'preview-internationalLogistic',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewInternationalLogisticChunk" */ '@/modules/seller-management/international-logistic/views/ThePreviewInternationalLogistic.vue'
      ),
    meta: {
      title: 'navigations.internationalLogisticMetaTitle',
      parentRouteName: 'internationalLogistic',
      logPage: {
        endpoint: internationalLogisticEndpoints,
        previewDynamicComponents: internationalLogisticPreviewComponents
      }
    }
  }
]
export const internationalLogisticModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertInternationalLogistic,
    persistent: true,
    name: 'upsert-internationalLogistic',
    requiredParams: [],
    size: 500
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-internationalLogistic',
    requiredParams: ['id'],
    onDelete: q => internationalLogisticEndpoints.delete(q.id.toString()),
    dialogTitle: 'internationalLogisticTitle',
    size: 500
  }
]
