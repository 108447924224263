import { Module, VuexModule } from 'vuex-module-decorators'
import { ILocale } from '@/core/types/coreTypes'

/**
 * Locale vuex class.
 */
@Module({ namespaced: true, name: 'locale' })
export default class LocaleStore extends VuexModule {
  localeList: ILocale[] = [
    {
      id: 1,
      text: 'english',
      value: 'en',
      dir: 'ltr'
      // iconSrc:
      //   'https://icons.iconarchive.com/icons/custom-icon-design/round-world-flags/256/Liberia-icon.png'
    },
    {
      id: 2,
      text: 'kurdish',
      value: 'ckb',
      dir: 'rtl'

      // iconSrc:
      //   'https://icons.iconarchive.com/icons/custom-icon-design/round-world-flags/256/Iraq-icon.png'
    },
    {
      id: 3,
      text: 'arabic',
      value: 'ar',
      dir: 'rtl'
      // iconSrc:
      //   'https://icons.iconarchive.com/icons/custom-icon-design/round-world-flags/256/Hungary-icon.png'
    }
  ]

  /**
   * Get locale list from vuex store.
   */
  get getLocaleList() {
    return this.localeList
  }
}
