import { RegistryEndpoints } from '@/modules/seller-management/registry/api/registryEndpoints'
import { IRegistry } from '@/modules/seller-management/registry/types/registryTypes'

/**
 * Registry endpoint instance
 * instantiate Registry api class with a different api path
 */
export const registryEndpoints = new RegistryEndpoints<IRegistry>(
  'registry/registry_list',
  'maker_checker'
)
