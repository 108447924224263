
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { AnyObject } from '@/shared/types/builtInTypes'
import {
  IReturnedOrder,
  returnedOrderStatus
} from '@/modules/order-management/returned-order/types/returnedOrderTypes'
import { returnedOrderEndpoints } from '@/modules/order-management/returned-order'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

@Component({
  name: 'SetReturnStatus',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class SetReturnStatus extends Vue {
  loading = false
  data = {} as IReturnedOrder
  originalData = {} as IReturnedOrder

  ReturnStatusTypeList: AnyObject[] = [
    {
      id: returnedOrderStatus.rInitiated,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rInitiated)
    },
    {
      id: returnedOrderStatus.rInspection,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rInspection)
    },
    {
      id: returnedOrderStatus.rCollected,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rCollected)
    },
    {
      id: returnedOrderStatus.rCompleted,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rCompleted)
    },
    {
      id: returnedOrderStatus.rConfirmed,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rConfirmed)
    },
    {
      id: returnedOrderStatus.rCanceled,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rCanceled)
    },
    {
      id: returnedOrderStatus.rDeclined,
      name: ContentFormatter.$customizeText(returnedOrderStatus.rDeclined)
    }
  ]

  async created() {
    await this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    try {
      if (id) this.data = await returnedOrderEndpoints.get(id)
      this.originalData = freezeObject(this.data)
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  fields(): Field<IReturnedOrder>[] {
    return [
      Field.dropdown<IReturnedOrder>('returnedStatus', 'returnStatus', () =>
        Promise.resolve(this.ReturnStatusTypeList)
      )
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
        .setCol('col-12'),
      Field.textarea<IReturnedOrder>('note', 'note')
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit() {
    const { id } = parsePathQueryString(location.search)
    if (id)
      await returnedOrderEndpoints
        .setReturnedStatusActions(id.toString(), this.data, this.originalData)
        .then(() => this.closePopUp())
        .finally(() => eventBus.refreshData())
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
