
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseDialog from '@/stories/base-dialog/BaseDialog.vue'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import TheLogoutConfirm from '@/core/components/TheLogoutConfirm.vue'
import { namespace } from 'vuex-class'
import { IUser } from '@/modules/force-change-password/types/ForceChangePasswordType'

const meStore = namespace('auth')
@Component({
  name: 'TheAccountMenu',
  components: { PreviewAvatar, BaseDialog, TheLogoutConfirm }
})
export default class TheAccountMenu extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @meStore.Getter('getMeInfo')
  readonly meInfo!: IUser

  showLogoutConfirm = false

  /**
   * Check the current theme to know that it is dark or not.
   * @returns boolean indicates that it is dark theme or not.
   */
  get isDark() {
    return this.$vuetify.theme.dark
  }

  /**
   * Toggles theme mode between dark and light. It also saves the setting
   * in localstorage
   */
  toggleTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    localStorage.setItem('dark', String(this.$vuetify.theme.dark))
  }
}
