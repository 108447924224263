
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { IStaff } from '@/modules/staff/types/staffTypes'
import { popUpRoute } from '@/services/utils/popupRoute'
import { staffEndpoints } from '@/modules/staff'
import { Route } from 'vue-router'
import { roleEndpoints } from '@/modules/preference/role'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { Id } from '@/shared/types/builtInTypes'
import eventBus from '@/eventBus'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'

@Component({
  name: 'AssignRole',
  components: {
    PreviewAvatar,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class AssignRole extends Vue {
  roleId: Id = -1

  data = {} as IStaff
  loading = false

  fields = () => [
    Field.dropdown<IStaff>('role', 'roleId', () => roleEndpoints.list())
      .required()
      .setCol('col-12')
  ]

  created() {
    this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    if (id)
      try {
        this.data = await staffEndpoints.get(id)
        this.roleId = await staffEndpoints.get(id).then(res => res.roleId)
      } catch (error) {
        throw new Error(`Failed to fetch form data. Due to ${error}`)
      } finally {
        this.loading = false
      }
    else throw new Error('Please, Provide User ID ')
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(
    values: Pick<IStaff, 'roleId'>,
    params: Route['params'],
    queries: Route['query']
  ) {
    try {
      if (queries.id)
        await staffEndpoints
          .assignRole(queries.id.toString(), values.roleId)
          .then(() => this.closePopUp())
    } catch {
      throw new Error('Please, Provide id')
    } finally {
      eventBus.refreshData()
    }
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
