import { RouteConfig } from 'vue-router'
import { taxEndpoints } from '@/modules/accounting/tax'
import { taxPreviewComponents } from '@/modules/accounting/tax/helpers/previewComponents'

/** Taxes module routes */
export const taxRoutes: RouteConfig[] = [
  {
    path: '/tax',
    name: 'tax',
    component: () =>
      import(
        /* webpackChunkName: "TheTaxChunk" */ '@/modules/accounting/tax/views/TheTax.vue'
      ),
    meta: {
      title: 'navigations.taxMetaTitle',
      parentRouteName: null,
      logPage: {
        endpoint: taxEndpoints,
        previewDynamicComponents: taxPreviewComponents
      }
    }
  }
]
