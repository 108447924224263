import { RouteConfig } from 'vue-router'

/**
 * Order Invoice module routes
 */
export const localOrderRoutes: RouteConfig[] = [
  {
    path: '/order',
    name: 'order',
    component: () =>
      import(
        /* webpackChunkName: "TheOrderChunk" */ '@/modules/order-management/local-orders/views/TheOrder.vue'
      ),
    meta: {
      title: 'navigations.orderMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/order/preview-details/:id',
    name: 'preview-order',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewOrderChunk" */ '@/modules/order-management/local-orders/views/ThePreviewOrder.vue'
      ),
    meta: {
      title: 'navigations.orderMetaTitle',
      parentRouteName: 'order'
    }
  }
]
