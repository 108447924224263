import { AnyObject } from '@/shared/types/builtInTypes'

/**
 * To get rid of access modifiers in the preview component class type we created & used this interface
 * in base preview component properties
 */
export interface IPreviewComponents<T> {
  type: PreviewComponentType
  props: Record<string, unknown>
  break?: boolean
  hidden?: boolean
  key: keyof T
  listeners?: AnyObject
  title?: string
  items?: () => Promise<unknown[]>
}

/**
 * Preview Component type
 */
export enum PreviewComponentType {
  labelValue = 'labelValue',
  image = 'image',
  multiImage = 'multiImage',
  avatar = 'avatar',
  table = 'table',
  flagIcon = 'flagIcon',
  fileLink = 'fileLink',
  treeView = 'treeView'
}
