import {
  IkeyTranslation,
  ILocalization
} from '@/modules/preference/localization/types/localizationTypes'

/**
 * transform keyTranslations array into translations object based on their languageId
 * */
export function keyTranslationsTransformer(arr: IkeyTranslation[]) {
  return arr.reduce((acc, item) => {
    switch (item.languageId) {
      case 1:
        acc['en'] = item.translation
        break
      case 2:
        acc['ckb'] = item.translation
        break
      case 3:
        acc['ar'] = item.translation
    }
    return acc
  }, {} as ILocalization)
}
