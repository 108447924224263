import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import TheUpsertCity from '@/modules/preference/city/views/TheUpsertCity.vue'
import { cityEndpoints } from '@/modules/preference/city'
import { cityPreviewComponents } from '@/modules/preference/city/helpers/previewComponents'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'

/**
 * Cities module routes
 */
export const cityRoutes: RouteConfig[] = [
  {
    path: '/city',
    name: 'city',
    component: () =>
      import(
        /* webpackChunkName: "TheCityChunk" */ '@/modules/preference/city/views/TheCity.vue'
      ),
    meta: {
      title: 'navigations.cityMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/city/preview-details/:id',
    name: 'preview-city',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCityChunk" */ '@/modules/preference/city/views/ThePreviewCity.vue'
      ),
    meta: {
      title: 'navigations.cityMetaTitle',
      parentRouteName: 'city',
      logPage: {
        endpoint: cityEndpoints,
        previewDynamicComponents: cityPreviewComponents
      }
    }
  }
]
export const cityModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertCity,
    persistent: true,
    name: 'edit-city',
    requiredParams: ['id']
  },
  {
    component: TheUpsertCity,
    persistent: true,
    name: 'add-city',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-city',
    requiredParams: ['id'],
    onDelete: q => cityEndpoints.delete(q.id.toString()),
    dialogTitle: 'cityTitle'
  }
]
