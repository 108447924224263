// Configure axios library
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseHeaders,
  AxiosResponseTransformer,
  InternalAxiosRequestConfig
} from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import appStore from '@/AppStore'
import { stringToSnakeCase } from '@/shared/helpers/stringCaseHelpers'
import appRouter from '@/AppRouter'
import { dotToDoubleUnderDash } from '@/shared/helpers/dotToDoubleUnderDash'
import { authEndpoints } from '@/modules/login'
import AuthEndpoints from '@/modules/login/api/authEndpoints'
import { IUser } from '@/modules/force-change-password/types/ForceChangePasswordType'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.withCredentials = true
axios.defaults.timeout = 100000

/*
 * axios default data transformer from snake_case to camel Case
 * */
axios.defaults.transformResponse = [
  (data, headers?: AxiosResponseHeaders) => {
    if (data instanceof Blob) {
      return data
    }
    // update all keys from backend to camelCase
    if (data && headers && headers['content-type'] === 'application/json') {
      return camelCaseKeys(JSON.parse(data), { deep: true })
    } else return data
  },
  ...(axios.defaults.transformResponse as AxiosResponseTransformer[])
]

/*
 * axios default data transformer from camel Case to snake_case
 * */
axios.defaults.transformRequest = [
  (data, headers?: AxiosRequestHeaders) => {
    if (data instanceof FormData && headers) {
      headers['content-type'] = 'multipart/form-data'
      return data
    } else return snakeCaseKeys(data ?? {}, { deep: true })
  },
  ...(axios.defaults.transformRequest as AxiosRequestTransformer[])
]

/* Add a response Interceptor */
axios.interceptors.response.use(
  async function (response: AxiosResponse) {
    if (
      response.config.url == 'auth/login/' ||
      response.config.url == 'auth/refresh/'
    ) {
      const { data } = response.data
      AuthEndpoints.handleAuthentication(data)
      AuthEndpoints.handleForcePassword(data, response.config.url)
      !data.changePasswordRequired &&
        // await me endpoint then execute the rest of the code
        (await authEndpoints
          .authMe()
          .then((userResponse: AxiosResponse<IUser>) =>
            AuthEndpoints.handleUserMeAuth(userResponse.data)
          ))
    }

    if (response.config.url == 'me/change_password/') {
      await authEndpoints
        .authMe()
        .then((userResponse: AxiosResponse<IUser>) =>
          AuthEndpoints.handleUserMeAuth(userResponse.data)
        )
    }

    /* Dismiss any error notifications on Success response */
    appStore.commit('notification/dismissError')

    /** reset auth me on every successful logout */
    if (['auth/logout/'].includes(response.config.url || '')) {
      appStore.commit('auth/setAuthentication', false)
    }

    return response.data.data ? { ...response.data } : response
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function
    // to trigger.
    if (
      error.response &&
      error.response.status === 401 &&
      error.response?.config.url !== 'auth/refresh/' &&
      appRouter.currentRoute.fullPath !== '/login'
    ) {
      appRouter.push('/login').finally()
      appStore.commit('auth/setAuthentication', false)
    }

    const parseError = {
      ...error,
      response: error.response?.data
    }

    return Promise.reject(parseError)
  }
)

/* Add a request Interceptor*/
axios.interceptors.request.use(
  function (request: InternalAxiosRequestConfig) {
    //  Convert the query params, keys to snake case
    if (request.params) {
      request.params = snakeCaseKeys(request.params || {}, {
        deep: false
      })

      // convert string values to snake case
      for (const [key, value] of Object.entries(request.params)) {
        if (typeof value == 'string' && key !== 'search') {
          const snakeCase = stringToSnakeCase(value)
          if (key === 'ordering' || key === '-ordering')
            request.params[key] = dotToDoubleUnderDash(snakeCase)
          else request.params[key] = snakeCase
        }
      }
    }
    return request
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function
    // to trigger.
    const parseError = {
      ...error,
      request: error.request.data
    }
    return Promise.reject(parseError)
  }
)
