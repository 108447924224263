import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IWorkflowAction } from '@/modules/seller-management/workflow-action/types/workflowActionTypes'

/**
 * dynamic component generator method
 * @param workflowAction is IWorkflowAction interface type which
 * @return An Array of IPreviewComponent workflow action type
 */
export function workflowActionPreviewComponents(
  workflowAction: IWorkflowAction
): PreviewComponent<IWorkflowAction>[] {
  return [
    PreviewComponent.labelValue<IWorkflowAction>(
      'requestType',
      'registryData',
      workflowAction?.registryData?.name
    ).setTitle('requestDetails'),
    PreviewComponent.labelValue<IWorkflowAction>(
      'requestFlow',
      'workflowId',
      workflowAction.workflow?.name
    ).setBreak(true),
    PreviewComponent.labelValue<IWorkflowAction>(
      'description',
      'description',
      workflowAction.description
    ).setCol('12'),
    ...metadataLabelValues(workflowAction)
  ]
}
