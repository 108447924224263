
import { Component, Prop, Vue } from 'vue-property-decorator'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IRoles } from '@/modules/preference/role/types/roleTypes'
import { roleEndpoints } from '@/modules/preference/role'
import Buttons from '@/stories/custom-buttons/CustomButtons.vue'
import { Route } from 'vue-router'
import { Field } from '@/services/utils/baseForm'
import eventBus from '@/eventBus'

@Component({
  name: 'TheUpsertRoleManagement',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm,
    Buttons
  }
})
export default class TheUpsertRoleManagement extends Vue {
  @Prop({ type: Number }) readonly roleId!: number
  loadingSubmit = false
  loading = false
  data = {} as IRoles
  queries = parsePathQueryString(location.search)
  originalData = {} as IRoles

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  fields(): Field<IRoles>[] {
    return [
      Field.text<IRoles>('roleName', 'name').setCol('col-12').required(),
      Field.treeView<IRoles>(
        'permissionIds',
        () => roleEndpoints.getAllPermissions(),
        'id',
        'codename'
      )
        .setProps({ translatedKeyPrefix: 'permissions.' })
        .setCol('12')
        .required()
        .setSectionTitle('grantedPermissions*')
    ]
  }

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.role.edit')}`
      : `${this.$t('dialog.role.addNew')}`

  mounted() {
    this.fetchRemoteData()
  }

  /** call given fetch data function*/
  async fetchRemoteData() {
    this.loading = true
    try {
      if (this.queries.id) this.data = await roleEndpoints.get(this.queries.id)
      this.originalData = freezeObject(this.data)
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  /** submit the data to the desired endpoint. */
  async submit() {
    this.loadingSubmit = true
    try {
      if (this.queries.id) {
        await roleEndpoints
          .patch(this.queries.id, this.originalData, this.data)
          .then(() => {
            popUpRoute.removeModalPath(['id'])
            eventBus.refreshData()
          })
      } else {
        await roleEndpoints.post(this.data).then(() => {
          popUpRoute.removeModalPath(['id'])
          eventBus.refreshData()
        })
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loadingSubmit = false
    }
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
