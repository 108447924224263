import { Id, IMetadata } from '@/shared/types/builtInTypes'
import { IVariant } from '@/modules/inventory/variant/types/variantTypes'

/** Categories type enum */
export enum CategoriesType {
  PARENT = 'parent',
  SUB_CATEGORY = 'sub_category',
  LEAF = 'leaf'
}

export type IVariantCategory = Omit<
  IVariant,
  'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'
> & {
  checked?: boolean
}

export interface ICategory extends IMetadata {
  id: Id
  name: string
  image: string
  categoryType: CategoriesType
  parentId: Id
  requiredVariants?: IVariantCategory[] | Id[]
  optionalVariants?: IVariantCategory[] | Id[]
  order: number
  children: {
    categoryType: CategoriesType
    name: string
  }[]
  isReturnable: boolean
}

export interface ICategoryList {
  id: Id
  name: string
  path: string
}

//Todo: remove this when the usage is totally removed from nested menu
export interface ICategoryNestedMenu extends IMetadata {
  id: Id
  name: string
  image: string
  searchKey: string
  marketplaceId: Id
  parentId: Id
  variantIds: Id[]
  marketplace: {
    id: Id
    name: string
  }
  variants: [
    {
      id: Id
      name: string
      variantOptions: [
        {
          id: Id
          name: string
          code: string
          variantId: Id
        }
      ]
    }
  ]
  order: number
  children: ICategoryNestedMenu[]
  categoryType: CategoriesType
}
