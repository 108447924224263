
import { Component, Prop, Vue } from 'vue-property-decorator'
import DialogBaseSkeleton from '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
import { popUpRoute } from '@/services/utils/popupRoute'
import eventBus from '@/eventBus'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { Route } from 'vue-router'

@Component({
  name: 'DialogDeleteConfirmation',
  components: { DialogBaseSkeleton }
})
export default class DialogDeleteConfirmation extends Vue {
  /**
   * Used as await function, wait until that promise settles and return the result.
   * which all this happens when click on `delete` button.
   */
  @Prop(Function) onDelete!: (query: Route['query']) => void

  /**
   * Set title for your dialog.
   */
  @Prop({ type: String, default: 'confirmation' })
  readonly dialogTitle!: string

  loading = false

  close() {
    popUpRoute.removeModalPath(['id'])
  }

  /**
   * global delete dialog id.
   */
  async confirm(): Promise<void> {
    try {
      this.loading = true
      await this.onDelete(parsePathQueryString(location.search))
      eventBus.refreshData()
      this.close()
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
