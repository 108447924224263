import { ModelApi } from '@/services/api/modelApi'
import { getDiff } from '@/shared/helpers/getDifference'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import axios from 'axios'

/**
 * order endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class OrderItemEndpoints<T> extends ModelApi<T> {
  constructor() {
    super('order_item')
  }

  /**
   * set Returned Order
   * @param id
   * @param data
   * @param originalData
   **/
  setReturnedOrder(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .post(`order_item/${id}/return_order_item/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   *Set Status Orders actions
   * @param id Id of the order
   * @param data
   * @param originalData
   **/
  setStatusActions(id: Id, data: AnyObject = {}, originalData: AnyObject = {}) {
    return new Promise((resolve, reject) => {
      const itemStatusChanged = originalData.itemStatus !== data.itemStatus
      if (!itemStatusChanged) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }
      const differences = getDiff(originalData, data)
      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }
      if (+id <= 0 || newData === undefined) {
        return reject('Id must be greater than 0')
      }
      axios
        .post(`order_item/${id}/change_status/`, newData)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   *Set Orders actions
   * @param id Id of the order
   * @param action type of actions
   * @param payload set the payload for post request
   **/
  setOrderActions(id: Id, action = '', payload: AnyObject = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`order_item/${id}/${action}/`, payload)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
