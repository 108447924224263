
import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { TranslateResult } from 'vue-i18n'
import { variantOptionsEndpoints } from '@/modules/inventory/variant'
import {
  IVariant,
  IVariantOptions
} from '@/modules/inventory/variant/types/variantTypes'

@Component({
  name: 'TheUpsertVariantOptions',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertVariantOptions extends Vue {
  loading = false
  data = {} as IVariant
  id = this.$route.params.id || ''
  variantId = this.$route.params.variantId || ''
  originalData = {}

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.variantOption.edit')}`
      : `${this.$t('dialog.variantOption.addNew')}`

  bindSubmitTitle(
    params: Route['params'],
    query: Route['query']
  ): TranslateResult {
    return query.id ? 'update' : 'addOption'
  }

  /**
   * function will be passed to child components
   * @params Variant Option which modules type the fetched Variant Option will be passed to it in the child component. (base form)
   */
  fields(): Field<IVariantOptions>[] {
    return [
      Field.text<IVariantOptions>('name', 'name').setCol('col-12').required(),
      Field.text<IVariantOptions>('code', 'code').setCol('col-12').required()
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(
    values: IVariantOptions,
    p: Record<string, number>,
    q: Record<string, number>
  ) {
    try {
      if (q.id) {
        await variantOptionsEndpoints.patch(
          q.id,
          this.originalData,
          values,
          false
        )
      } else
        await variantOptionsEndpoints.post(
          { ...values, variantId: this.variantId },
          false
        )
    } catch {
      throw new Error(`Failed to fetch data table data.`)
    } finally {
      this.closePopUp()
      eventBus.refreshData()
    }
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id

    this.loading = true
    try {
      if (id) {
        this.data = await variantOptionsEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
