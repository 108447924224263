import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import {
  IProduct,
  weightEstimationStatusColors
} from '@/modules/inventory/product/types/productTypes'
import appRouter from '@/AppRouter'
import i18n from '@/plugins/i18n'

/**
 * dynamic component generator method
 * @param product is IProduct interface type which
 * @return An Array of IPreviewComponent product type
 */
export function productVariantPreviewComponent(
  product: IProduct
): PreviewComponent<IProduct>[] {
  const productId = appRouter.currentRoute?.params?.productId
  return [
    PreviewComponent.labelValue<IProduct>(
      'name',
      'name',
      product.productName
    ).setTitle('information'),
    PreviewComponent.labelValue<IProduct>('productId', 'id', productId),
    PreviewComponent.labelValue<IProduct>(
      'sellerId',
      'name',
      product.merchant && product.merchant.id
    ),
    PreviewComponent.labelValue<IProduct>(
      'storeName',
      'merchant',
      product.merchant && product.merchant.storeName
    ),
    PreviewComponent.labelValue<IProduct>(
      'sellerSku',
      'sellerSku',
      product.sellerSku
    ),
    PreviewComponent.labelValue<IProduct>('systemSku', 'systemSku', productId),
    PreviewComponent.labelValue<IProduct>(
      'barcode',
      'barcode',
      product.barcode
    ),
    PreviewComponent.labelValue<IProduct>('weightKg', 'weight', product.weight),
    PreviewComponent.labelValue<IProduct>(
      'quantity',
      'quantity',
      product.quantity
    ),
    PreviewComponent.labelValue<IProduct>('price', 'price', product.price),
    PreviewComponent.labelValue<IProduct>(
      'weightEstimation',
      'weightEstimationStatus',
      product.weightEstimationStatus
    )
      .setClass(
        `${
          product && product.weightEstimationStatus
            ? weightEstimationStatusColors[product.weightEstimationStatus]
            : ''
        }--text`
      )
      .setBreak(true),
    PreviewComponent.multiPhoto<IProduct>(
      'variantImages',
      product.variantImages &&
        product.variantImages.map(variant => variant.image)
    ).setCol('12'),
    PreviewComponent.table<IProduct>(
      'variantOptions',
      [
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.value'),
          value: 'value',
          key: 'value',
          formatValue: 'text'
        }
      ],
      product.variantOptions
    )
      .setProp({ class: 'col-12' })
      .setTitle('variantOptions')
      .setBreak(),
    //META DATA
    ...metadataLabelValues(product)
  ]
}
