
import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { TranslateResult } from 'vue-i18n'
import { IVariantOptions } from '@/modules/inventory/variant/types/variantTypes'
import { ISellerBranch } from '@/modules/seller/types/sellerTypes'
import { sellerBranchEndpoints } from '@/modules/seller'
import { cityEndpoints } from '@/modules/preference/city'

@Component({
  name: 'TheUpsertSellerBranch',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertSellerBranch extends Vue {
  loading = false
  data = {} as ISellerBranch
  id = this.$route.params.id || ''
  sellerId = this.$route.params.merchantId || ''
  originalData = {}

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.sellerBranch.edit')}`
      : `${this.$t('dialog.sellerBranch.addNew')}`

  bindSubmitTitle(
    _params: Route['params'],
    query: Route['query']
  ): TranslateResult {
    return query.id ? 'update' : 'add'
  }

  /** function will be passed to child components*/
  fields(): Field<ISellerBranch>[] {
    return [
      Field.text<ISellerBranch>('name', 'name').setCol('col-6').required(),
      Field.dropdown<ISellerBranch>('city', 'cityId', () =>
        cityEndpoints.list()
      )
        .setCol('col-6')
        .required(),
      Field.textarea<ISellerBranch>('description', 'description')
        .setCol('col-12')
        .required(),
      Field.text<ISellerBranch>('address', 'address')
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint.*/
  async handleSubmit(
    values: IVariantOptions,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    try {
      if (q.id) {
        await sellerBranchEndpoints.patch(
          q.id,
          this.originalData,
          values,
          false
        )
      } else
        await sellerBranchEndpoints.post(
          { ...values, merchantId: this.sellerId },
          false
        )
    } catch {
      throw new Error(`Failed to fetch data table data.`)
    } finally {
      this.closePopUp()
      eventBus.refreshData()
    }
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id

    this.loading = true
    try {
      if (id) {
        this.data = await sellerBranchEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
