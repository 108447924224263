
import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { customerAddressEndpoints } from '@/modules/customer'
import { ICustomerAddress } from '@/modules/customer/types/customerTypes'
import customizePhoneNumber from '@/shared/helpers/customizePhoneNumber'
import { cityEndpoints } from '@/modules/preference/city'

@Component({
  name: 'TheUpsertCustomerAddress',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertCustomerAddress extends Vue {
  loading = false
  data = {} as ICustomerAddress
  id = this.$route.params.id || ''
  customerId = this.$route.params.customerId || ''
  originalData = {}

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.customer.edit')}`
      : `${this.$t('dialog.customer.addNew')}`

  /**
   * function will be passed to child components
   * @params Customer Address which modules type the fetched customer address will be passed to it in the child component. (base form)
   */
  fields(): Field<ICustomerAddress>[] {
    return [
      Field.text<ICustomerAddress>('addressTitle', 'addressTitle')
        .setCol('col-12')
        .required(),
      Field.dropdown<ICustomerAddress>('city', 'cityId', () =>
        cityEndpoints.list()
      )
        .setCol('col-12')
        .required(),
      Field.text<ICustomerAddress>('location', 'location').setCol('col-12'),
      Field.phone<ICustomerAddress>('phone').setCol('col-12').required(),
      Field.checkbox<ICustomerAddress>('preferredAddress', 'preferredAddress')
        .setProps({
          color: 'section'
        })
        .setCol('col-12')
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(
    values: ICustomerAddress,
    p: Record<string, number>,
    q: Record<string, number>
  ) {
    let payload: ICustomerAddress = {
      ...values,
      ...{ phone: customizePhoneNumber(values.phone) }
    }
    if (q.id) {
      await customerAddressEndpoints
        .patch(q.id, this.originalData, payload, false)
        .then(() => {
          this.closePopUp()
          eventBus.refreshData()
        })
    }
    //Merge the customerId with other value then add it
    else
      await customerAddressEndpoints
        .post({ ...payload, customerId: this.customerId }, false)
        .then(() => {
          this.closePopUp()
          eventBus.refreshData()
        })
  }

  bindSubmitTitle(params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id', 'customerId'])
  }

  created() {
    this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id

    this.loading = true
    try {
      if (id) {
        this.data = await customerAddressEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
