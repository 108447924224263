import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertWeightEstimation from '@/modules/inventory/weight-estimation/views/TheUpsertWeightEstimation.vue'
import { weightEstimationPreviewComponents } from '@/modules/inventory/weight-estimation/helpers/previewComponents'
import { weightEstimationEndpoints } from '@/modules/inventory/weight-estimation'

/** Weight Estimation module routes */
export const weightEstimationRoutes: RouteConfig[] = [
  {
    path: '/inventory/weight-estimation',
    name: 'weightEstimation',
    component: () =>
      import(
        /* webpackChunkName: "TheWeightEstimation" */ '@/modules/inventory/weight-estimation/views/TheWeightEstimation.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.weightEstimationMetaTitle'
    }
  },
  {
    path: '/inventory/weight-estimation-keyword/preview-details/:id',
    name: 'preview-weightEstimation',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewWeightEstimation" */ '@/modules/inventory/weight-estimation/views/ThePreviewWeightEstimation.vue'
      ),
    meta: {
      title: 'navigations.weightEstimationMetaTitle',
      parentRouteName: 'weightEstimation',
      logPage: {
        endpoint: weightEstimationEndpoints,
        previewDynamicComponents: weightEstimationPreviewComponents
      }
    }
  }
]
export const weightEstimationModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertWeightEstimation,
    size: 500,
    persistent: true,
    name: 'edit-weightEstimation',
    requiredParams: ['id']
  },
  {
    component: TheUpsertWeightEstimation,
    size: 500,
    persistent: true,
    name: 'add-weightEstimation',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-weightEstimation',
    requiredParams: ['id'],
    onDelete: q => weightEstimationEndpoints.delete(q.id.toString()),
    dialogTitle: 'weightEstimationTitle'
  }
]
