import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import { workflowActionEndpoints } from '@/modules/seller-management/workflow-action'
import TheUpsertWorkflowAction from '@/modules/seller-management/workflow-action/views/TheUpsertWorkflowAction.vue'
import { workflowActionPreviewComponents } from '@/modules/seller-management/workflow-action/helpers/previewComponents'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'

/** Workflow Actions module routes */
export const workflowActionRoutes: RouteConfig[] = [
  {
    path: '/workflow-action',
    name: 'workflowAction',
    component: () =>
      import(
        /* webpackChunkName: "TheWorkflowActionChunk" */ '@/modules/seller-management/workflow-action/views/TheWorkflowAction.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.workflowActionMetaTitle'
    }
  },
  {
    path: '/workflow-action/preview-details/:id',
    name: 'preview-workflowAction',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewWorkflowActionChunk" */ '@/modules/seller-management/workflow-action/views/ThePreviewWorkflowAction.vue'
      ),
    meta: {
      title: 'navigations.workflowActionMetaTitle',
      parentRouteName: 'workflowAction',
      logPage: {
        endpoint: workflowActionEndpoints,
        previewDynamicComponents: workflowActionPreviewComponents
      }
    }
  }
]
export const workflowActionModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertWorkflowAction,
    persistent: true,
    name: 'add-workflowAction',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-workflowAction',
    requiredParams: ['id'],
    onDelete: q => workflowActionEndpoints.delete(q.id.toString()),
    dialogTitle: 'workflowActionProfileTitle'
  }
]
