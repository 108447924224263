
import { Component, Vue } from 'vue-property-decorator'
import { authEndpoints } from '@/modules/login'
import { ILoginForm } from '@/modules/login/types/loginTypes'
import TheFooter from '@/core/components/TheFooter.vue'
import { VueRecaptcha } from 'vue-recaptcha'

@Component({
  name: 'TheLogin',
  components: {
    TheFooter,
    VueRecaptcha
  }
})
export default class TheLogin extends Vue {
  recaptchaToken = ''
  form = {
    username: process.env.VUE_APP_DEFAULT_EMAIL,
    password: process.env.VUE_APP_DEFAULT_PASSWORD,
    channel: 'Staff Admin',
    recaptcha: this.recaptchaToken
  } as ILoginForm
  showPassword = false
  loading = false

  $refs!: {
    recaptcha: VueRecaptcha
  }
  isReCaptchaEnabled = process.env.VUE_APP_IS_RECAPTCHA_ENABLED === 'true'
  isProductionMode = process.env.NODE_ENV === 'production'
  siteKey = process.env.VUE_APP_SITE_KEY

  /**
   * @returns boolean - true in production if captcha is not valid
   * */
  get isCaptchaInvalid() {
    if (this.isProductionMode && this.isReCaptchaEnabled)
      return !this.recaptchaToken || !this.form.username || !this.form.password
    return false
  }

  /**
   * Handle Submit on login
   * */
  login() {
    this.loading = true
    authEndpoints
      .login({
        username: this.form.username,
        password: this.form.password,
        channel: 'Staff Admin',
        recaptcha: this.recaptchaToken
      })
      .finally(() => {
        this.loading = false
      })
  }

  /*
   * @token assign recaptchaToken to the token that has been passed to the function after recaptcha has been verified
   * */
  verifyCaptcha(response: string) {
    this.recaptchaToken = response
  }

  errorCaptcha() {
    this.resetCaptcha()
  }

  expiredCaptcha() {
    this.resetCaptcha()
  }

  /*
   * reset the variable and the verified token capture itself
   * */
  resetCaptcha() {
    this.recaptchaToken = ''
    this.$refs.recaptcha.reset()
  }
}
