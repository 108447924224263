
import { Component, Vue } from 'vue-property-decorator'
import { popUpRoute } from '@/services/utils/popupRoute'
import { Field } from '@/services/utils/baseForm'
import eventBus from '@/eventBus'
import { IApiRequest } from '@/modules/preference/api-integration/types/apiIntegration'
import { apiRequestEndpoint } from '@/modules/preference/api-integration'

@Component({
  name: 'ImportApiRequest',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ImportApiRequest extends Vue {
  /** function will be passed to child components */
  fields = () => [
    Field.file<IApiRequest>('file', 'uploadAPIRequestAttachment')
      .setProps({
        accept: '.csv'
      })
      .setRules('ext:csv')
      .required()
      .setCol('col-12')
  ]

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: IApiRequest) {
    await apiRequestEndpoint
      .importApiRequest({ file: values.file })
      .then(() => this.closePopUp())
    eventBus.refreshData()
  }

  closePopUp() {
    popUpRoute.removeModalPath()
  }
}
