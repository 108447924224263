import { Module, VuexModule } from 'vuex-module-decorators'
import VueI18n from 'vue-i18n'
import TranslateResult = VueI18n.TranslateResult

/**
 * Gender vuex store module.
 */
@Module({ namespaced: true, name: 'gender' })
export default class GenderStore extends VuexModule {
  genderList: {
    text: string | TranslateResult
    value: string
  }[] = [
    {
      text: 'male',
      value: 'm'
    },
    {
      text: 'female',
      value: 'f'
    }
  ]

  /**
   * Get gender list from vuex store.
   */
  get getGenderList() {
    return this.genderList
  }
}
