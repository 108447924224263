
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { estimationArrivalEndpoints } from '@/modules/seller-management/estimation-arrival'
import { IEstimationArrival } from '@/modules/seller-management/estimation-arrival/types/estimationArrivalTypes'
import { countryEndpoints } from '@/modules/seller-management/country'

@Component({
  name: 'TheUpsertEstimationArrival',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertEstimationArrival extends Vue {
  loading = false
  data = {} as IEstimationArrival
  id = this.$route.params.id || ''
  originalData = {} as IEstimationArrival

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.estimationArrival.edit')}`
      : `${this.$t('dialog.estimationArrival.addNew')}`

  /**
   * function will be passed to child components
   * @param estimationArrival
   */
  fields(estimationArrival: IEstimationArrival): Field<IEstimationArrival>[] {
    return [
      Field.dropdown<IEstimationArrival>('countryNameField', 'countryId', () =>
        countryEndpoints.list()
      )
        .required()
        .setCol('12'),
      Field.currency<IEstimationArrival>('minEstimationDays', 'minEstimation')
        .setProps({ decimalLength: 0 })
        .setRules('min_value:1')
        .setCol('col-12')
        .required(),
      Field.currency<IEstimationArrival>('maxEstimationDays', 'maxEstimation')
        .setProps({ decimalLength: 0 })
        .setRules(`min_value:${Number(estimationArrival.minEstimation) + 1}`)
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IEstimationArrival,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await estimationArrivalEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await estimationArrivalEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await estimationArrivalEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
