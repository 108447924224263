import { uploadImagesAndConvertToFile } from '@/modules/inventory/product/helpers/uploadImagesAndConvertToFile'
import { Images } from '@/modules/page/collection/types/collectionTypes'

export const organizeImagesToBeFilesType = (
  images: (File | string)[] | Images[]
): Promise<File[]> => {
  const promises =
    images &&
    images.map(async (img: File | string | any) => {
      if (typeof img === 'string')
        return await uploadImagesAndConvertToFile(img).then()
      if (typeof img === 'object') {
        return typeof img.image === 'string'
          ? await uploadImagesAndConvertToFile(img.image).then()
          : img
      } else return img
    })
  return Promise.all(promises)
}
