
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import {
  VAutocomplete,
  VCheckbox,
  VSwitch,
  VTextarea,
  VTextField
} from 'vuetify/lib'
import { VCombobox } from 'vuetify/lib/components'
import { FieldType, IField } from '@/shared/types/baseFormTypes'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import { Route } from 'vue-router'
import { generateDynamicTitle } from '@/shared/helpers/generateDynamicTitle'
import { getValueAtKeyPath } from '@/shared/helpers/getValueAtKeyPath'
import { setValueAtKeyPath } from '@/shared/helpers/setValueAtKeyPath'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { searchFilter } from '@/shared/helpers/searchFilter'
import { currencyToNum } from '@/shared/helpers/currencyToNum'
import { SimpleTableInputArg } from '@/shared/types/simpleTableTypes'

@Component({
  name: 'BaseForm',
  components: {
    FieldImageUpload: () =>
      import(
        /* webpackChunkName: "ImageUploadFieldChunk" */ '@/stories/field-image-upload/FieldImageUpload.vue'
      ),
    FieldMultiImageUpload: () =>
      import(
        /* webpackChunkName: "MultiImageUploadFieldChunk" */ '@/stories/field-multi-image-upload/FieldMultiImageUpload.vue'
      ),
    FieldAvatar: () =>
      import(
        /* webpackChunkName: "AvatarFieldChunk" */ '@/stories/field-avatar-upload/FieldAvatarUpload.vue'
      ),
    FieldRadio: () =>
      import(
        /* webpackChunkName: "RadioFieldChunk" */ '@/stories/field-radio/FieldRadio.vue'
      ),
    FieldFlaggedAutocomplete: () =>
      import(
        /* webpackChunkName: "FlaggedAutocompleteFieldChunk" */ '@/stories/field-flagged-autocomplete/FieldFlaggedAutocomplete.vue'
      ),
    FieldDateTimePicker: () =>
      import(
        /* webpackChunkName: "DateTimePickerFieldChunk" */ '@/stories/field-date-time-picker/FieldDateTimePicker.vue'
      ),
    FieldFileUpload: () =>
      import(
        /* webpackChunkName: "FileUploadFieldChunk" */ '@/stories/field-file-upload/FieldFileUpload.vue'
      ),
    FieldColorPicker: () =>
      import(
        /* webpackChunkName: "ColorPickerFieldChunk" */ '@/stories/field-color-picker/FieldColorPicker.vue'
      ),
    FieldTextEditor: () =>
      import(
        /* webpackChunkName: "FieldTextEditorChunk" */ '@/stories/field-text-editor/FieldTextEditor.vue'
      ),
    SimpleTable: () =>
      import(
        /* webpackChunkName: "SimpleTable" */ '@/stories/simple-table/SimpleTable.vue'
      ),
    FieldMappingTable: () =>
      import(
        /* webpackChunkName: "FieldMappingTable" */ '@/stories/field-mapping-table/FieldMappingTable.vue'
      ),
    BaseButton: () =>
      import(
        /* webpackChunkName: "BaseButton" */ '@/stories/custom-buttons/CustomButtons.vue'
      ),
    FieldNestedDropdown: () =>
      /* webpackChunkName: "FieldNestedDropdown" */ import(
        '@/stories/field-nested-dropdown/FieldNestedDropdown.vue'
      ),
    FieldVariantOptionsUpload: () =>
      /* webpackChunkName: "FieldVariantOptionsUpload" */ import(
        '@/stories/field-variant-options-upload/FieldVariantOptionsUpload.vue'
      ),
    FieldInternationalPhone: () =>
      import(
        /* webpackChunkName: "FieldInternationalPhone" */ '@/stories/field-international-phone/FieldInternationalPhone.vue'
      ),
    FieldAutocompleteFetch: () =>
      import(
        /* webpackChunkName: "FieldAutocompleteFetch" */ '@/shared/components/FieldAutocompleteFetch.vue'
      ),
    TreeView: () =>
      /* webpackChunkName: "TreeView" */ import(
        '@/stories/treeView/TreeView.vue'
      ),
    VAutocomplete,
    VTextarea,
    VTextField,
    VSwitch,
    VCheckbox,
    VCombobox
  }
})
export default class BaseForm extends Vue {
  /**
   * Set title for submitted button, which by default button title is 'confirm',
   * we could set another string or conditionally set title using function
   */
  @Prop({ type: Function || String, default: () => 'confirm' })
  readonly submitBtnTitle!:
    | string
    | ((
        params: Route['params'],
        queries: Route['query'],
        remoteDataFetchers: Partial<AnyObject>
      ) => string)

  /**
   * Set color for submit button, which by default button color is 'primary black--text'
   * pass a color for cancel button and as a class for submitted button
   */
  @Prop({ type: String, default: 'primary black--text' })
  readonly submitBtnColor?: string

  /** Click on `update` or `add` button, post the data to desired endpoint or response */
  @Prop({ type: Function, required: true })
  readonly submit!: (
    newValues: AnyObject,
    params: Route['params'],
    queries: Route['query']
  ) => Promise<void>

  /**
   * is a function return array(IField) of any object will be passed to child field components
   * @params values which modules type, the fetched endpoint will be passed to it in the child component.
   */
  @Prop({ type: Function, required: true })
  readonly fields!: (values: AnyObject) => IField<AnyObject>[]

  /** override column of an image section to 12*/
  @Prop({ type: Boolean, default: false })
  readonly column?: boolean

  /** Is an array of object which used to assign response to data. */
  @Prop({ type: Object, default: () => ({}) })
  readonly data!: AnyObject

  /** Add a loading while handling data */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  /** For Hiding submit button */
  @Prop(Boolean) readonly hideSubmit?: boolean
  /** For Hiding cancel button */
  @Prop(Boolean) readonly hideCancel?: boolean

  refreshLoading: Record<string, boolean> = {}
  items: Record<string, unknown> = {}
  fieldWithItemFunctionTypeKeys: string[] = []
  dependantKeyItems: string[] = []
  loadingSubmit = false
  tableDisableActions = false

  /** Maps field types to component names */
  fieldTypeToComponentMapper: Record<IField<AnyObject>['type'], string> = {
    dropdown: 'v-autocomplete',
    relation: 'v-autocomplete',
    dateTime: 'field-date-time-picker',
    file: 'field-file-upload',
    preview: 'app-data-preview',
    radio: 'field-radio',
    switch: 'v-switch',
    currency: 'v-currency-field',
    internationalPhone: 'field-international-phone',
    text: 'v-text-field',
    textarea: 'v-textarea',
    checkbox: 'v-checkbox',
    flaggedCountries: 'field-flagged-autocomplete',
    photo: 'field-image-upload',
    multiPhoto: 'field-multi-image-upload',
    avatar: 'field-avatar',
    colorPicker: 'field-color-picker',
    combobox: 'v-combobox',
    banner: 'app-banner',
    table: 'simple-table',
    mapping: 'field-mapping-table',
    textEditor: 'field-text-editor',
    nestedDropdown: 'field-nested-dropdown',
    treeView: 'tree-view',
    variantOption: 'field-variant-options-upload',
    autoCompleteFetch: 'field-autocomplete-fetch'
  }

  /** root v-col size changes for images fields and non-image fields */
  get imageDependentLayout(): string {
    return this.photoFields && this.photoFields.length
      ? this.column
        ? 'col-12'
        : 'col-8 col-lg-9'
      : 'col-12'
  }

  /** filter non hidden fields to get only photo fields */
  get photoFields() {
    return this.nonHiddenFields.filter(f =>
      [FieldType.photo, FieldType.avatar, FieldType.multiPhoto].includes(f.type)
    )
  }

  /** filter non hidden fields to get none photo fields */
  get nonPhotoFields() {
    return this.nonHiddenFields.filter(
      f =>
        ![FieldType.photo, FieldType.avatar, FieldType.multiPhoto].includes(
          f.type
        )
    )
  }

  /** if there was no input in the form, hide the star required info */
  get shouldShowRequired() {
    // check for only those fields that are not banner type
    return this.nonHiddenFields.filter(f => f.type !== FieldType.banner).length
  }

  /** Retrieve only unhidden fields with handling fetch items with function that passed with dropdown types */
  get nonHiddenFields() {
    return this.fields(this.data).filter(field => {
      this.fields(this.data)
      if (
        field.items &&
        field.key &&
        field.hidden !== true &&
        typeof field.items === 'function'
      ) {
        if (
          !this.fieldWithItemFunctionTypeKeys.includes(field.key) ||
          this.dependantKeyItems.includes(field.key)
        ) {
          this.fieldWithItemFunctionTypeKeys.push(field.key)
          this.fetchItemProp(field.key, field.items)
        }
      }
      return !field.hidden
    })
  }

  /** Check if it has a cancel listener or not */
  get hasCancelListener() {
    return this.$listeners && this.$listeners.cancel
  }

  get bindSubmitTitle() {
    return generateDynamicTitle(this.submitBtnTitle, this.$route?.params, {})
  }

  bindValue(key: string) {
    return getValueAtKeyPath(this.data, key)
  }

  bindColorProp(props: AnyObject = {}): string {
    if (props.color) return `${props.color}`
    else return `black`
  }

  /** column size for fields */
  defaultColSize(field: IField<AnyObject>): string {
    if (this.photoFields && this.photoFields.length) {
      return this.column ? 'col-12' : 'col-12 col-md-6 col-lg-4 px-3 py-1'
    } else if (field.type === FieldType.table) {
      return 'col-12 px-3 py-1'
    }
    return 'col-6 col-md-4 col-lg-3 px-3 py-1'
  }

  /**
   * fetch endpoints that were given for fields in their item props.
   * @params key field key which is a string type
   * @params fetcher is item property function in the IField function returns a promise
   */
  async fetchItemProp(
    key: string,
    fetcher: () => Promise<unknown>
  ): Promise<void> {
    try {
      this.refreshLoading = { [key]: true }
      const refreshedItems = await fetcher()
      Object.assign(this.items, { [key]: refreshedItems })
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.refreshLoading = { [key]: false }
    }
  }

  /** Delete the field that has been dependant by other keys */
  async deleteFieldValue(field: IField<AnyObject>): Promise<void> {
    if (
      field &&
      field.key &&
      field.dependantKey &&
      field.dependantKey.length > 0
    ) {
      field.dependantKey.forEach((dependantKey: string) => {
        this.dependantKeyItems.push(dependantKey)
        //Deleting the hidden dependent key only if isResettable true
        if (this.data[dependantKey] && field.isResettableDependantKey)
          delete this.data[dependantKey]
      })
    }
  }

  /**
   * triggers after user stop typing, playing with the field Or clicked somewhere on the page.
   * @param event can be anything based on the field that user typed or selected etc.
   * @param field IField type all the props are available here of the selected field
   */
  handleChange(event: unknown, field: IField<AnyObject>): void {
    this.dependantKeyItems = []
    // set reactivity to property object inside values i.g values:{values:{}}
    field.key && this.$set(this.data, field.key, event)
    if (
      (field.type === FieldType.dropdown ||
        field.type === FieldType.nestedDropdown ||
        field.type === FieldType.autoCompleteFetch) &&
      field.key
    ) {
      setValueAtKeyPath(this.data, field.key, event)
      this.deleteFieldValue(field)
    } else if (field.type === FieldType.mapping && field.key) {
      this.data[field.key] = event
    } else if (field.type === FieldType.table) {
      this.handleTableInputChange(event as never, field)
    } else {
      if (!field.key) {
        throw Error('input key must be defined')
      }
      // check if value is a currency string format it to number
      const checkForCurrency =
        field.type === FieldType.currency ? currencyToNum(event) : event
      setValueAtKeyPath(this.data, field.key, checkForCurrency)
    }
  }

  /** bind rules if they were an array join them */
  bindRules(field: IField<AnyObject>) {
    const props = this.bindProps(field)
    if (!props?.disabled && !props?.readonly) {
      return field.rules && field.rules.join('|')
    } else {
      return ''
    }
  }

  bindProps(field: IField<AnyObject>) {
    const bindValue = field.key && this.bindValue(field.key)
    let customProps: AnyObject

    switch (field.type) {
      case FieldType.currency:
        customProps = {
          allowNegative: false,
          value: bindValue
        }
        break
      case FieldType.combobox:
        customProps = {
          chips: true,
          multiple: true,
          clearable: true,
          deletableChips: true,
          smallChips: true,
          value: bindValue
        }
        break
      case FieldType.relation:
        customProps = {
          multiple: true,
          chips: true,
          clearable: true,
          autoSelectFirst: true,
          smallChips: true,
          value: bindValue
        }
        break
      case FieldType.table:
        customProps = {
          disableAllActions: this.tableDisableActions
        }
        break
      case FieldType.dropdown:
        customProps = {
          clearable: true,
          autoSelectFirst: true,
          filter: searchFilter,
          value: bindValue
        }
        break
      case FieldType.checkbox:
      case FieldType.switch:
        customProps = {
          'input-value': bindValue
        }
        break
      default:
        customProps = { value: bindValue }
    }
    return {
      ...customProps,
      ...field.props
    } as AnyObject
  }

  /** translate fields labels and check if it's required */
  bindLabel(field: IField<AnyObject>): string {
    if (
      field.type == FieldType.table ||
      field.type == FieldType.variantOption ||
      field.type == FieldType.treeView ||
      field.type == FieldType.radio
    )
      return ''
    else if (field.rules && field.rules.includes('required')) {
      return `${this.$t('label.' + field.label)} *`
    } else {
      return `${this.$t('label.' + field.label)}`
    }
  }

  handleTableInputChange(
    { header, rowIndex, value }: SimpleTableInputArg<AnyObject>,
    field: IField<AnyObject>
  ) {
    if (!field.key) {
      throw Error('key must be defined')
    }

    const newValues = { ...this.data }
    if (!newValues[field.key]) {
      newValues[field.key] = [{}]
    }

    const items = newValues[field.key] as AnyObject[]
    this.$set(items[rowIndex], header.key as string, value)
  }

  addRow(field: IField<AnyObject>) {
    if (!field.key) {
      throw Error('key must be defined')
    }

    let tableData = this.data[field.key] as unknown[]
    // if the value for the table data does not exist yet, add it first
    if (!tableData) {
      tableData = []
    }

    let newRowIndex =
      field &&
      field.props &&
      field.props.value &&
      Array.isArray(field.props.value)
        ? field?.props.value.length
        : 0
    this.$set(tableData, newRowIndex, {})
  }

  deleteRow(field: IField<AnyObject>, event: { id: Id; index: number }) {
    if (!field.key) {
      throw Error('key must be defined')
    }

    const items = this.data[field.key] as AnyObject[]
    items.splice(event.index, 1)
  }

  saveRow(field: IField<AnyObject>, event: AnyObject) {
    if (!field.key) {
      throw Error('key must be defined')
    }
    this.tableDisableActions = true
    this.submit(event, {}, {})?.finally(
      () => (this.tableDisableActions = false)
    )
  }

  /** handle submit function property */
  submitHandler(): void {
    this.loadingSubmit = true
    this.submit(
      this.data,
      this.$route?.params,
      parsePathQueryString(location.search)
    )?.finally(() => (this.loadingSubmit = false))
  }

  /**
   * Is a custom events used with <code>handleTableInputChange</code> and <code>deleteRow</code> function,
   * Which is handle the change event.
   * @param event
   */
  @Emit('input')
  inputChange(event: AnyObject) {
    return event
  }

  /**
   * Is a custom events used with <code>FieldVariantOptionsUpload</code>,
   * Which is handle the change event.
   * @param event
   */
  @Emit('sync-variant-options')
  syncVariantOptions(event: AnyObject) {
    return event
  }

  /** Is a custom events handle cancel action */
  @Emit('cancel')
  cancel() {
    //
  }
}
