
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IEstimationArrival } from '@/modules/seller-management/estimation-arrival/types/estimationArrivalTypes'
import { sellerEndpoints } from '@/modules/seller'

@Component({
  name: 'UpdateEstimationArrival',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class UpdateEstimationArrival extends Vue {
  loading = false
  data = {} as Pick<IEstimationArrival, 'minEstimation' | 'maxEstimation'>
  originalData = {} as Pick<
    IEstimationArrival,
    'minEstimation' | 'maxEstimation'
  >

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)

    this.loading = true
    try {
      if (id) {
        const res = await sellerEndpoints.get(id)
        this.data = res.estimationArrival
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  /**
   * function will be passed to child components
   * @param estimationArrival
   */
  fields(estimationArrival: IEstimationArrival): Field<IEstimationArrival>[] {
    return [
      Field.currency<IEstimationArrival>('minEstimationDays', 'minEstimation')
        .setProps({ decimalLength: 0 })
        .setRules('min_value:1')
        .setCol('col-12')
        .required(),
      Field.currency<IEstimationArrival>('maxEstimationDays', 'maxEstimation')
        .setProps({ decimalLength: 0 })
        .setRules(`min_value:${Number(estimationArrival.minEstimation) + 1}`)
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: Pick<IEstimationArrival, 'minEstimation' | 'maxEstimation'>
  ) {
    const { id } = parsePathQueryString(location.search)
    if (id) {
      await sellerEndpoints
        .updateSellerEstimationOrLogistic(
          id,
          'estimation_arrival',
          values,
          this.originalData
        )
        .then(() => popUpRoute.removeModalPath(['id']))
    }
    eventBus.refreshData()
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
