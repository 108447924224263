/**
 * Makes a deep clone of the object by stringify and then parsing it
 * @param object
 */
import { AnyObject } from '@/shared/types/builtInTypes'

function compareValues(
  value1: unknown,
  value2: unknown
): 'created' | 'updated' | 'deleted' | 'unchanged' {
  const number1 = Number(value1)
  const number2 = Number(value2)

  // if any of them is not a number, just strict check the original values
  if (Number.isNaN(number1) || Number.isNaN(number2)) {
    if (value1 === value2) {
      return 'unchanged'
    }
  }
  // if both are numbers then strict check the numbers
  else if (number1 === number2) {
    return 'unchanged'
  }

  if (
    isDate(value1) &&
    isDate(value2) &&
    (value1 as Date).getTime() === (value2 as Date).getTime()
  ) {
    return 'unchanged'
  }
  if (value1 === undefined) {
    return 'created'
  }
  if (value2 === undefined) {
    return 'deleted'
  }
  return 'updated'
}

function isFunction(x: unknown) {
  return Object.prototype.toString.call(x) === '[object Function]'
}

function isDate(x: unknown) {
  return Object.prototype.toString.call(x) === '[object Date]'
}

function isObject(x: unknown) {
  return Object.prototype.toString.call(x) === '[object Object]'
}

function isValue(x: unknown) {
  return !isObject(x) && !Array.isArray(x)
}

/**
 * Gets difference between two objects, returning the added, deleted or updated
 * values in the same structure as the new object.
 * NOTE: 'false' to '' or null to '' is not marked as change
 * @param original
 * @param newObject
 */
export function getDiff<T>(
  original: T,
  newObject: T
): Partial<T> | undefined | '' {
  if (isFunction(original) || isFunction(newObject)) {
    throw 'Invalid argument. Function given, object expected.'
  }
  if (isValue(original) || isValue(newObject)) {
    const comparison = compareValues(original, newObject)
    // add the second value if its changed
    if (comparison !== 'unchanged') {
      return comparison === 'deleted' ? '' : newObject
    } else {
      return undefined
    }
  }

  // if object is array, compare the objects as a whole and not per field
  if (Array.isArray(original)) {
    if (JSON.stringify(original) !== JSON.stringify(newObject)) {
      return newObject
    }
    return
  }

  const diff = {} as T

  for (const key in original) {
    if (isFunction(original[key])) {
      continue
    }

    let value2 = undefined
    if (newObject[key] !== undefined) {
      value2 = newObject[key]
    }

    const difference = getDiff((original as AnyObject)[key], value2)
    if (difference !== undefined) {
      // @ts-ignore
      diff[key] = difference
    }
  }

  for (const key in newObject) {
    if (isFunction(newObject[key]) || diff[key] !== undefined) {
      continue
    }

    // @ts-ignore
    const difference = getDiff(original[key], newObject[key])
    if (difference !== undefined) {
      // @ts-ignore
      diff[key] = difference
    }
  }
  // if nothing has changed, objects will be empty, so return nothing
  if (Object.keys(diff).length !== 0) {
    return diff
  }
}
