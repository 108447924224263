import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { ITransactionFee } from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'
import { mergeAmountWithType } from '@/shared/helpers/amountWithAmountType'

/**
 * dynamic component generator method
 * @param transactionFee is ITransactionFee interface type which
 * @return An Array of IPreviewComponent transaction fee type
 */
export function transactionFeePreviewComponents(
  transactionFee: ITransactionFee
): PreviewComponent<ITransactionFee>[] {
  return [
    PreviewComponent.labelValue<ITransactionFee>(
      'label',
      'label',
      transactionFee.label
    ),
    PreviewComponent.labelValue<ITransactionFee>(
      'type',
      'type',
      ContentFormatter.$customizeText(transactionFee.type)
    ),
    PreviewComponent.labelValue<ITransactionFee>(
      'amountType',
      'amountType',
      ContentFormatter.$customizeText(transactionFee.amountType)
    ),
    PreviewComponent.labelValue<ITransactionFee>(
      'amount',
      'amount',
      mergeAmountWithType(transactionFee)
    ),
    PreviewComponent.labelValue<ITransactionFee>(
      'code',
      'code',
      transactionFee.code
    ),
    ...metadataLabelValues(transactionFee)
  ]
}
