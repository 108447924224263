/**
 * Converts camelCase to snake_case excluding PascalCase strings
 */

export const stringToSnakeCase = (string: string) => {
  // only convert those that do not start with a capital letter
  if (!/^[A-Z]/.test(string)) {
    return string.replace(/[A-Z]/g, substring => {
      return '_' + substring.toLowerCase()
    })
  }
  return string
}

export const stringToCamelCase = (string: string) => {
  const separated = string.split('_')
  // do not touch the first letter
  let rejoined = separated[0]
  // capitalize after the first letter
  separated.slice(1).forEach(substr => {
    // capitalize first letter
    const capitalized = substr[0].toUpperCase() + substr.slice(1)
    rejoined = rejoined + capitalized
  })
  return rejoined
}
