import { TranslateResult } from 'vue-i18n'
import { AnyObject } from '@/shared/types/builtInTypes'
import { FieldType, IField } from '@/shared/types/baseFormTypes'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'
import { ISimpleTableHeader } from '@/shared/types/simpleTableTypes'

export class Field<T> {
  private type: FieldType
  private rules: string[] = []
  private label = ''
  private key: keyof T
  private props: AnyObject = {}
  private items?: () => Promise<unknown[]>
  private searchFetcher?: (params: Record<string, string>) => Promise<unknown[]>
  private listeners: AnyObject = {}
  private hidden?: boolean
  private cols?: string
  private break?: boolean
  private sectionTitle?: string
  private divider?: boolean
  private dependantKey: string[] | null = null
  private isResettableDependantKey = true
  private slots: string[] | null = null

  constructor(type: FieldType, key: keyof T) {
    this.type = type
    this.key = key
  }

  // ############################### Builders
  static combobox<T>(
    label: string,
    key: keyof T,
    items?: IField<T>['items'],
    itemText: string | ((item: keyof unknown) => unknown) = 'name',
    itemValue: string | ((item: keyof unknown) => unknown) = 'id',
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.combobox, key)
    field.dependantKey = dependantKey
    field.items = items
    field.props = {
      itemText,
      itemValue
    }
    field.label = label
    return field
  }

  static table<T>(
    key: keyof T,
    data: AnyObject[],
    headers: ISimpleTableHeader<T>[],
    fetcher?: () => Promise<AnyObject[]>,
    fetcherDependentValues?: unknown[],
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.table, key)
    field.props.value = data
    field.props.headers = headers
    field.dependantKey = dependantKey
    field.props.fetcher = fetcher
    field.props.fetcherDependentValues = fetcherDependentValues
    return field
  }

  /*
   * used only in field mapping api request module
   * it has multiple rows
   * */
  static mapping<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.mapping, key)
    field.dependantKey = dependantKey
    field.label = label
    return field
  }

  static flaggedCountries<T>(
    label: string,
    key: keyof T,
    items: IField<T>['items'],
    dependantKey: string[] = []
  ) {
    const field = new Field<T>(FieldType.flaggedCountries, key)
    field.label = label
    field.items = items
    field.dependantKey = dependantKey
    return field
  }

  static currency<T>(
    label: string,
    key: keyof T,
    dependantKey: string[] = [],
    isSymbol = false
  ) {
    const field = new Field<T>(FieldType.currency, key)
    field.label = label
    field.dependantKey = dependantKey
    isSymbol && (field.props.prefix = getCurrencySymbol())
    return field
  }

  static text<T>(
    label: string,
    key: keyof T,
    dependantKey: string[] = [],
    isResettableDependantKey = true
  ) {
    const field = new Field<T>(FieldType.text, key)
    field.label = label
    field.dependantKey = dependantKey
    field.isResettableDependantKey = isResettableDependantKey
    return field
  }

  static textarea<T>(
    label: string,
    key: keyof T,
    dependantKey = <string[]>[],
    isResettableDependantKey = true
  ) {
    const field = new Field<T>(FieldType.textarea, key)
    field.label = label
    field.props.rows = 3
    field.dependantKey = dependantKey
    field.isResettableDependantKey = isResettableDependantKey
    field.cols = 'col-12 px-3 py-1'
    return field
  }

  static dropdown<T>(
    label: string,
    key: keyof T,
    items: IField<T>['items'],
    itemText: string | ((item: keyof unknown) => unknown) = 'name',
    itemValue: string | ((item: keyof unknown) => unknown) = 'id',
    dependantKey: string[] = [],
    isResettableDependantKey = true,
    slots: string[] = []
  ) {
    const field = new Field<T>(FieldType.dropdown, key)
    field.label = label
    field.dependantKey = dependantKey
    field.isResettableDependantKey = isResettableDependantKey
    field.slots = slots
    field.items = items
    field.props = {
      itemText,
      itemValue
    }
    return field
  }

  static relation<T>(
    label: string,
    key: keyof T,
    items: IField<T>['items'],
    itemText: string | ((item: keyof unknown) => unknown) = 'name',
    itemValue: string | ((item: keyof unknown) => unknown) = 'id',
    dependantKey: string[] = [],
    isResettableDependantKey = true
  ) {
    const field = new Field<T>(FieldType.relation, key)
    field.label = label
    field.dependantKey = dependantKey
    field.isResettableDependantKey = isResettableDependantKey
    field.items = items
    field.props = {
      itemText,
      itemValue
    }
    return field
  }

  static autocompleteScroll<T>(
    label: string,
    key: keyof T,
    items: IField<T>['items'],
    itemText: string | ((item: keyof unknown) => unknown) = 'name',
    itemValue: string | ((item: keyof unknown) => unknown) = 'id',
    options?: {
      searchFetcher?: (params: Record<string, string>) => Promise<unknown[]>
      slot?: string[]
    },
    dependantKey: string[] = [],
    isResettableDependantKey = true
  ) {
    const field = new Field<T>(FieldType.autoCompleteFetch, key)
    field.label = label
    field.items = items
    field.isResettableDependantKey = isResettableDependantKey
    field.props = {
      itemText,
      itemValue,
      autoSelectFirst: true,
      slots: options?.slot || {}
    }
    field.searchFetcher = options?.searchFetcher
    field.dependantKey = dependantKey

    // Add any slot-specific configurations
    if (options?.slot) {
      field.slots = options.slot
    }

    return field
  }

  static nestedDropdown<T>(
    label: string,
    key: keyof T,
    items: IField<T>['items']
  ) {
    const field = new Field<T>(FieldType.nestedDropdown, key)
    field.label = label
    field.items = items

    return field
  }

  static file<T>(
    key: keyof T,
    label = 'uploadAttachment',
    dependantKey = <string[]>[],
    isResettableDependantKey = true
  ) {
    const field = new Field<T>(FieldType.file, key)
    field.label = label
    field.dependantKey = dependantKey
    field.isResettableDependantKey = isResettableDependantKey
    field.cols = 'col-12'
    return field
  }

  static dateTime<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.dateTime, key)
    field.dependantKey = dependantKey
    field.label = label
    return field
  }

  static preview<T>(
    label: string,
    text: string,
    key = label,
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.preview, key as keyof T)
    field.label = label
    field.dependantKey = dependantKey
    field.props = { text }
    return field
  }

  static radio<T>(
    key: keyof T,
    radioButtons: { value: unknown; label: string | TranslateResult }[],
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.radio, key)
    field.props = { radioButtons }
    field.dependantKey = dependantKey
    field.cols = 'col-12 px-3'
    return field
  }

  static switch<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.switch, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static checkbox<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.checkbox, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static photo<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.photo, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static multiPhoto<T>(
    label: string,
    key: keyof T,
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.multiPhoto, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static avatar<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = new Field<T>(FieldType.avatar, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static banner<T>(label: string, key: keyof T) {
    const field = new Field<T>(FieldType.banner, key)
    field.label = label
    return field
  }

  static phone<T>(key: keyof T, dependantKey = <string[]>[]) {
    const field = Field.text<T>('phone', key)
    field.rules = ['phone']
    field.dependantKey = dependantKey
    /* this deleted + for international phone number. */
    // field.props = { type: 'number' }
    return field
  }

  static internationalPhone<T>(
    label: string,
    key: keyof T,
    dependantKey: string[] = []
  ) {
    const field = new Field<T>(FieldType.internationalPhone, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static url<T>(key: keyof T, dependantKey = <string[]>[]) {
    const field = Field.text<T>('url', key)
    field.rules = ['url']
    field.dependantKey = dependantKey
    return field
  }

  static email<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = Field.text<T>('email', key)
    field.rules = ['email']
    field.label = label
    field.dependantKey = dependantKey
    field.props = { prependInnerIcon: 'fal fa-at' }
    return field
  }

  static username<T>(label: string, key: keyof T, dependantKey = <string[]>[]) {
    const field = Field.text<T>(label, key)
    field.rules = ['phone_or_email']
    field.dependantKey = dependantKey
    return field
  }

  static colorPicker<T>(
    label: string,
    key: keyof T,
    dependantKey = <string[]>[]
  ) {
    const field = new Field<T>(FieldType.colorPicker, key)
    field.label = label
    field.dependantKey = dependantKey
    return field
  }

  static address<T>(key: keyof T, dependantKey = <string[]>[]) {
    const field = Field.text<T>('address', key)
    field.dependantKey = dependantKey
    field.props.prependInnerIcon = 'fal fa-location-dot'
    return field
  }

  /**
   * Password Field
   *@param key
   * @param label
   * @param isShowingPassword
   * @param options
   * dependantVId --> vid of another dependant field
   * rule --> name of rule
   * hasParam --> check if it is a param pass @ as a prefix (We need it to compare_password but not for confirmed)
   * onChangePasswordVisibility --> handle the visibility of inner icon
   * @param dependantKey
   * */
  static password<T>(
    key: keyof T,
    label: string,
    isShowingPassword = false,
    options: {
      dependantVId?: string
      rule?: string
      hasParam?: boolean
      onChangePasswordVisibility?: () => void
    } = {},
    dependantKey = <string[]>[]
  ) {
    const field = Field.text<T>(label, key)
    field.rules = ['password']
    field.props.prependInnerIcon = 'fal fa-lock-keyhole'
    field.props.type = isShowingPassword ? 'text' : 'password'
    //If rule has a param pass @ as a prefix
    const hasParam = options.hasParam ? '@' : ''
    //Handle adding another rule with depending on another field vid

    if (options.dependantVId && options.rule)
      field.rules.push(`${options.rule}:${hasParam}${options.dependantVId}`)

    field.props.vid = key
    if (options.onChangePasswordVisibility)
      field.props.appendIcon = isShowingPassword
        ? 'fa-light fa-eye'
        : 'fa-light fa-eye-slash'
    field.listeners['click:append'] = () => {
      options.onChangePasswordVisibility?.()
    }
    field.dependantKey = dependantKey
    return field
  }

  static deleteBanner() {
    return Field.banner('delete.message', 'deleteMessage')
  }

  static textEditor<T>(label: string, key: keyof T) {
    const field = new Field<T>(FieldType.textEditor, key)
    field.label = label
    return field
  }

  static variantOption<T>(key: keyof T, items: IField<T>['items']) {
    const field = new Field<T>(FieldType.variantOption, key)
    field.items = items
    field.cols = '12'
    return field
  }

  static treeView<T>(
    key: keyof T,
    items: IField<T>['items'],
    itemKey: string | ((item: keyof unknown) => unknown) = 'id',
    itemText: string | ((item: keyof unknown) => unknown) = 'name',
    dependantKey: string[] = []
  ) {
    const field = new Field<T>(FieldType.treeView, key)

    field.dependantKey = dependantKey

    field.items = items
    field.props = {
      itemText,
      itemKey,
      selectable: true,
      selectedColor: 'primary'
    }
    return field
  }

  // ############################ Modifiers
  setLoading(isLoading: boolean) {
    this.props.loading = isLoading
    return this
  }

  required(isRequired = true) {
    const index = this.rules.indexOf('required')

    if (index === -1 && isRequired) {
      this.rules.push('required')
    } else if (index !== -1 && !isRequired) {
      this.rules.splice(index, 1)
    }
    return this
  }

  setHidden(isHidden = true) {
    this.hidden = isHidden
    return this
  }

  setProps(props: AnyObject) {
    this.props = { ...this.props, ...props }
    return this
  }

  setCol(colClass: string) {
    this.cols = colClass
    return this
  }

  setBreak(isBreak = true) {
    this.break = isBreak
    return this
  }

  setDivider(isDivider = true) {
    this.divider = isDivider
    return this
  }

  setSectionTitle(title = '') {
    this.sectionTitle = title
    return this
  }

  /**
   * Set New Rule
   * @param rule
   * */
  setRules(rule: string) {
    this.rules.push(rule)
    return this
  }
}

/**
 * new feature - base form
 * now you can use vuetify slots inside modules as base form slots
 * usage:
 * your script field builder is like Field.dropdown(....,['item'])
 * while item is vuetify slots name
 *
 * 2. in your template or html
 * <base-form>
 *     <template #item="{ field }">
 *         <v-list-item-content>
 *           <v-list-item-title>{{ field.item.text }} </v-list-item-title>
 *         </v-list-item-content>
 *     </template>
 *--------
 * while field has all vuetify scoped slots features like data.item, data.count etc.
 *
 * */
