/**
 * Api config endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
import { ModelApi } from '@/services/api/modelApi'
import axios from 'axios'
import { AnyObject } from '@/shared/types/builtInTypes'

export class ApiIntegrationEndpoint<Type> extends ModelApi<Type> {
  constructor(sectionPath: string, moduleName?: string) {
    super(sectionPath, moduleName)
  }

  async reorderApiRequests(apiRequestIds: Array<number>) {
    return axios
      .post(`${this.getUrl()}reorder/`, { restApiIds: apiRequestIds })
      .then(response => {
        this.responseNotify('notifications.updatedSuccessfully')
        return response.data.data
      })
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
        throw error.response.data
      })
  }

  async exportApiRequest() {
    return axios
      .post(`/integration/rest_api/export_rest_api/`, { responseType: 'blob' })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const today = new Date()
          .toISOString()
          .substring(0, 10)
          .replace('_', '-')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `API-Configuration-${today}.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
      .catch(async error => {
        //`.text()` method is used to read the contents of a Blob object as text.
        const errorResponse = await error.response.text()
        const parseResponse = JSON.parse(errorResponse)
        this.responseNotify(parseResponse.data?.detail, 'error')
      })
  }

  async importApiRequest(data: AnyObject): Promise<unknown> {
    const form = ModelApi.rearrangeData(data, true)
    return axios
      .post(`/integration/rest_api/import_rest_api/`, form)
      .then(() => this.responseNotify('notifications.updatedSuccessfully'))
      .catch(error => {
        this.responseNotify(error.message, 'error')
        throw error.message
      })
  }
}
