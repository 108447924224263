import { PreviewComponent } from '@/services/utils/basePreview'
import { IApiConfig } from '@/modules/preference/api-integration/types/apiIntegration'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param config is IApiConfig interface type which
 * @return An Array of IPreviewComponent config type
 */
export function apiConfigPreviewComponents(
  config: IApiConfig
): PreviewComponent<IApiConfig>[] {
  return [
    PreviewComponent.labelValue<IApiConfig>('name', 'name', config.name),
    PreviewComponent.labelValue<IApiConfig>(
      'timeout',
      'timeout',
      config.timeout
    ),
    PreviewComponent.labelValue<IApiConfig>(
      'successCode',
      'successCode',
      config.successCode
    ),
    PreviewComponent.labelValue<IApiConfig>(
      'errorCodeKeyName',
      'failureCodes',
      config.failureCodes?.keyName
    ),
    PreviewComponent.labelValue<IApiConfig>(
      'failureCodes',
      'failureCodes',
      () => config.failureCodes.failureCodes.join(', ')
    ),
    PreviewComponent.labelValue<IApiConfig>(
      'description',
      'description',
      config.description
    ).setCol('pa-3 col-12'),
    ...metadataLabelValues(config)
  ]
}
