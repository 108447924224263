import { ModelApi } from '@/services/api/modelApi'
import { Id } from '@/shared/types/builtInTypes'
import axios from 'axios'
import { ISellerManagement } from '@/modules/seller/types/sellerTypes'
import { getDiff } from '@/shared/helpers/getDifference'

/**
 * Merchant endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class SellerEndpoints<T> extends ModelApi<T> {
  constructor(urlPath: string) {
    super(urlPath)
  }

  updateSellerManagement(id: Id, payload = {} as ISellerManagement) {
    return new Promise((resolve, reject) => {
      axios
        .post(`merchant/${id}/seller_management/`, payload)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  updateSellerEstimationOrLogistic(
    id: Id,
    endpoint: string,
    data = {},
    originalData = {}
  ) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }

      //send all data when endpoint equal too `estimation_arrival`
      const customData = endpoint === 'international_logistic' ? newData : data

      axios
        .post(`merchant/${id}/${endpoint}/`, customData)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
