import { AmountType } from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { ITransferFee } from '@/modules/seller/types/sellerTypes'

/**
 * format amount to currency if `amountType === IQD`
 * format to percentage if `amountType === %`
 * @param item
 */
export function mergeAmountWithType(item: ITransferFee): string | undefined {
  if (item && item.amountType === AmountType.fixed)
    return ContentFormatter.$formatToCurrency(item.amount, true)
  else if (item && item.amountType === AmountType.percentage)
    return ContentFormatter.$formatToPercentage(item.amount, true)
  else return '-'
}
