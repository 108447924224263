import appStore from '@/AppStore'
import {
  DataTableOptions,
  DataTableParams
} from '@/shared/types/baseDataTableTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import { stringToSnakeCase } from '@/shared/helpers/stringCaseHelpers'
import snakeCaseKeys from 'snakecase-keys'

/**
 * Api Service class that is a base for api model.
 */
export class ApiService {
  protected resource: string
  protected modelName: string

  constructor(resource: string, modalName = '') {
    this.resource = resource
    this.modelName = modalName
  }

  static addTrailingSlash(path: string) {
    return path.endsWith('/') ? path : path + '/'
  }

  static addToFormData<T extends AnyObject>(
    data: T,
    formData: FormData,
    parentKey?: string
  ) {
    Object.entries(data).forEach(([key, value]) => {
      const parent = parentKey ? parentKey + '.' : ''
      if (Array.isArray(value)) {
        value.forEach(elem => {
          if (elem instanceof File) formData.append(parent + key, elem)
          else if (typeof elem === 'object' && elem) {
            this.addToFormData(elem as AnyObject, formData, parent + key)
          } else {
            formData.append(parent + key, elem)
          }
        })
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof File) &&
        !(value instanceof Date)
      ) {
        this.addToFormData(value as AnyObject, formData, parent + key)
      } else {
        formData.append(parent + key, value as string)
      }
    })
  }

  static snakeCaseRequestKeys<T>(object: T, options?: { deep: boolean }): T {
    const newObject = {} as AnyObject
    Object.entries(object).forEach(([key, value]) => {
      const snakeCaseKey = stringToSnakeCase(key)

      if (options && options.deep) {
        if (
          typeof value === 'object' &&
          value !== null &&
          !(value instanceof File) &&
          !(value instanceof Date) &&
          !Array.isArray(value)
        ) {
          newObject[snakeCaseKey] = snakeCaseKeys(value as AnyObject, options)
          return
        }
      }

      newObject[snakeCaseKey] = value
    })
    return newObject as T
  }

  /**
   * Get url that contains base url with the selected endpoint.
   * @param id Trailing id that append to the end of the endpoint.
   */
  getUrl(id?: string | number): string {
    return id ? `${this.getPathResource()}${id}/` : this.getPathResource()
  }

  /**
   * Api response notification handler that used as app notification to notify
   * user about the request response.
   * @param message Notification message.
   * @param type Notification type either 'Success' or 'Error'
   */
  responseNotify(message: string, type = 'success') {
    appStore
      .dispatch('notification/pushNotification', {
        type: type,
        messages: message
      })
      .finally()
  }

  dismissErrorNotification() {
    appStore.commit('notification/dismissError')
  }

  /**
   * A Function that takes Vuetify datatable options object and transforms it
   * into a params object that mock api can understand
   */
  transformOptions(
    options: DataTableOptions & {
      search?: string
      filters?: Record<string, unknown>
      merchantId?: Id
    }
  ): DataTableParams {
    // clone the object
    let params: DataTableParams = {}

    // Add 'ordering'
    if (options.sortBy?.[0]) {
      // if sort is descending, append '-' before the param
      if (
        options.sortDesc?.[0] &&
        ContentFormatter.$toBoolean(options.sortDesc?.[0].toString())
      ) {
        params.ordering = '-' + options.sortBy[0]
      } else {
        params.ordering = options.sortBy[0]
      }
    }

    // add 'limit' and 'offset'
    if (options.itemsPerPage || options.page) {
      const itemPerPage = options.itemsPerPage || 10
      const page = options.page || 1
      params.limit = itemPerPage
      params.offset = itemPerPage * (page - 1)
    }

    // add search
    if (options.search) params.search = options.search
    // add filters
    if (options.filters) params = { ...params, ...options.filters }
    // add merchant Id , TODO should make it dynamic passing another params
    if (options.merchantId) params.merchantId = options.merchantId

    return params
  }

  private getPathResource(): string {
    const slashedResource = ApiService.addTrailingSlash(this.resource)
    return this.modelName
      ? `${this.modelName}/${slashedResource}`
      : slashedResource
  }
}
