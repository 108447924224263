import { ModelApi } from '@/services/api/modelApi'
import { IDenomination } from '@/modules/arbela-gift-card/denomination/types/denominationTypes'

/**
 * Denomination endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class DenominationEndpoints extends ModelApi<IDenomination> {
  constructor() {
    super('denomination')
  }
}
