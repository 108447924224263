import { IDiscountProfile } from '@/modules/seller-management/discount-profile/types/discountProfileTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param discountProfile is IDiscountProfile interface type which
 * @return An Array of IPreviewComponent discount profile type
 */
export function discountProfilePreviewComponents(
  discountProfile: IDiscountProfile
): PreviewComponent<IDiscountProfile>[] {
  return [
    PreviewComponent.labelValue<IDiscountProfile>(
      'limitName',
      'profileName',
      discountProfile.profileName
    ).setTitle('discountProfileDetail'),
    PreviewComponent.labelValue<IDiscountProfile>(
      'percentageAmount',
      'percentageAmount',
      ContentFormatter.$formatToPercentage(
        discountProfile.percentageAmount,
        true
      )
    ),
    PreviewComponent.labelValue<IDiscountProfile>(
      'fixedAmount',
      'fixedAmount',
      ContentFormatter.$formatToCurrency(discountProfile.fixedAmount, true)
    ),
    PreviewComponent.labelValue<IDiscountProfile>(
      'description',
      'description',
      discountProfile.description
    )
      .setCol('12')
      .setBreak(true),
    ...metadataLabelValues(discountProfile)
  ]
}
