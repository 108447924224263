import { ModelApi } from '@/services/api/modelApi'
import axios from 'axios'
import { Id } from '@/shared/types/builtInTypes'
import { getDiff } from '@/shared/helpers/getDifference'
import { IReturnedOrder } from '@/modules/order-management/returned-order/types/returnedOrderTypes'

/**
 *ReturnedOrder endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class ReturnedOrderEndpoints extends ModelApi<IReturnedOrder> {
  constructor() {
    super('return_order')
  }

  /**
   * Set inspection
   * @param id
   * @param data
   **/
  setInspection(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .post(`return_order/${id}/inspection/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   *Set Returned Status Orders actions
   * @param id Id of the order
   * @param data
   * @param originalData
   **/
  setReturnedStatusActions(
    id: Id,
    data = {} as IReturnedOrder,
    originalData = {} as IReturnedOrder
  ) {
    return new Promise((resolve, reject) => {
      const itemReturnStatusChanged =
        originalData.returnStatus !== data.returnStatus
      if (!itemReturnStatusChanged) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }
      const differences = getDiff(originalData, data)
      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }
      if (+id <= 0 || newData === undefined) {
        return reject('Id must be greater than 0')
      }
      axios
        .post(`return_order/${id}/update_status/`, newData)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * set Refund Order
   * @param id
   * @param data
   **/
  setRefundOrder(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .post(`return_order/${id}/refund/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
