import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IDenomination } from '@/modules/arbela-gift-card/denomination/types/denominationTypes'

/**
 * dynamic component generator method
 * @param denomination is IDenomination interface type which
 * @return An Array of IPreviewComponent denomination type
 */
export function denominationPreviewComponents(
  denomination: IDenomination
): PreviewComponent<IDenomination>[] {
  return [
    PreviewComponent.labelValue<IDenomination>(
      'label',
      'label',
      denomination.label
    ),
    PreviewComponent.labelValue<IDenomination>(
      'amount',
      'amount',
      ContentFormatter.$formatToCurrency(denomination.amount, true)
    ),
    PreviewComponent.labelValue<IDenomination>(
      'serialCode',
      'serialCode',
      denomination.serialCode
    ),
    ...metadataLabelValues(denomination)
  ]
}
