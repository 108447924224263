import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IStock } from '@/modules/inventory/stock/types/stockTypes'
import i18n from '@/plugins/i18n'
import { productStatusColors } from '@/modules/inventory/product/types/productTypes'

/**
 * dynamic component generator method
 * @return An Array of IPreviewComponent stock type
 * @param stock
 */
export function stockPreviewComponents(
  stock: IStock
): PreviewComponent<IStock>[] {
  return [
    PreviewComponent.multiPhoto<IStock>(
      'images',
      stock?.images?.map(variant => variant?.image)
    ),
    //VARIANT DETAIL
    PreviewComponent.labelValue<IStock>(
      'sellerSku',
      'sellerSku',
      stock.sellerSku
    ).setTitle('variantDetail'),
    PreviewComponent.labelValue<IStock>(
      'systemSku',
      'systemSku',
      stock.systemSku
    ),
    PreviewComponent.labelValue<IStock>('barcode', 'product', stock.barcode),
    PreviewComponent.labelValue<IStock>(
      'shippingWeightKg',
      'weight',
      stock.weight
    ),
    PreviewComponent.labelValue<IStock>('quantity', 'quantity', stock.quantity),
    PreviewComponent.labelValue<IStock>(
      'unitPriceIQD',
      'price',
      ContentFormatter.$formatToCurrency(stock.price, true)
    ),
    //VARIANT OPTIONS
    PreviewComponent.table<IStock>(
      'variantOptions',
      [
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text',
          width: '600px'
        },
        {
          text: i18n.t('header.value'),
          value: 'value',
          key: 'value',
          formatValue: 'text',
          width: '600px'
        }
      ],
      stock?.variantOptions
    )
      .setProp({
        class: 'col-12'
      })
      .setTitle('variantOptions'),
    //Product Organization
    PreviewComponent.labelValue<IStock>(
      'productName',
      'product',
      stock?.product?.name
    ).setTitle('productInformation'),
    PreviewComponent.labelValue<IStock>(
      'productId',
      'product',
      stock?.product?.id
    ),
    PreviewComponent.labelValue<IStock>(
      'description',
      'product',
      stock?.product?.description
    ).setCol('12'),
    PreviewComponent.labelValue<IStock>(
      'seller',
      'product',
      stock?.product?.merchantFullName
    ).setTitle('productOrganization'),
    PreviewComponent.labelValue<IStock>(
      'sellerBranch',
      'product',
      stock?.product?.merchantBranches.join(', ')
    ),
    PreviewComponent.labelValue<IStock>(
      'storeName',
      'product',
      stock?.product?.storeName
    ),
    PreviewComponent.labelValue<IStock>(
      'sellerId',
      'product',
      stock?.product?.merchantId
    ),
    PreviewComponent.labelValue<IStock>(
      'tags',
      'product',
      stock?.product?.tags.join(', ')
    ),
    PreviewComponent.labelValue<IStock>(
      'brands',
      'product',
      stock?.product?.brandName
    ).setBreak(true),
    //Shipping
    PreviewComponent.labelValue<IStock>(
      'productType',
      'product',
      ContentFormatter.$customizeText(stock?.product?.productType)
    )
      .setTitle('shipping')
      .setBreak(true),
    //Status
    PreviewComponent.labelValue<IStock>(
      'listingStatus',
      'product',
      stock?.product?.status
    )
      .setClass(
        `${productStatusColors[stock?.product?.status]}--text text-capitalize`
      )
      .setTitle('status'),
    PreviewComponent.labelValue<IStock>(
      'note',
      'product',
      stock?.product?.note
    ).setCol('12'),
    //Product Category
    PreviewComponent.labelValue<IStock>(
      'productCategory',
      'product',
      stock?.product?.category?.path
    )
      .setTitle('productCategory')
      .setBreak(true),
    ...metadataLabelValues(stock)
  ]
}
