import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import SetStatus from '@/modules/order-management/order-items/components/SetStatus.vue'
import SetReturnItem from '@/modules/order-management/order-items/components/SetReturnItem.vue'
import TheBarCodeOrder from '@/modules/order-management/order-items/views/TheBarCodeOrder.vue'

/**
 * Order module routes
 */
export const orderItemRoutes: RouteConfig[] = [
  {
    path: '/orderItems',
    name: 'orderItems',
    component: () =>
      import(
        /* webpackChunkName: "TheOrdersChunk" */ '@/modules/order-management/order-items/views/TheOrderItems.vue'
      ),
    meta: {
      title: 'navigations.orderItemsMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/orderItems/preview-details/:id',
    name: 'preview-orderItems',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewOrderChunk" */ '@/modules/order-management/order-items/views/ThePreviewOrderItem.vue'
      ),
    meta: {
      title: 'navigations.orderItemsMetaTitle',
      parentRouteName: 'orderItems'
    }
  }
]

export const orderItemModalRoute: PopUpRouteConfig[] = [
  {
    component: SetReturnItem,
    persistent: true,
    name: 'returned-item',
    requiredParams: ['id'],
    size: 600
  },
  {
    component: SetStatus,
    persistent: true,
    name: 'set-status-item',
    requiredParams: ['id'],
    size: 600
  },
  {
    component: TheBarCodeOrder,
    size: 500,
    persistent: true,
    name: 'barcode-order-items',
    requiredParams: []
  }
]
