import { Id, IMetadata } from '@/shared/types/builtInTypes'
import { ICategoryList } from '@/modules/inventory/category/types/categoryTypes'
import { IVariantsItemProduct } from '@/shared/types/variantTypes'
import { ITag } from '@/modules/preference/tag/type/tagTypes'
import { IBrandList } from '@/modules/preference/brand/type/brandTypes'
import { Images } from '@/modules/page/collection/types/collectionTypes'

export enum ProductStatus {
  freeze = 'freeze',
  active = 'active',
  inactive = 'inactive'
}

export enum WeightEstimationStatus {
  estimated = 'estimated',
  higher = 'higher',
  lower = 'lower'
}

export const weightEstimationStatusColors: Record<
  WeightEstimationStatus | string,
  string
> = {
  [WeightEstimationStatus.estimated]: 'success',
  [WeightEstimationStatus.higher]: 'onErrorContainer',
  [WeightEstimationStatus.lower]: 'warning'
}

export const productStatusColors: Record<ProductStatus | string, string> = {
  [ProductStatus.freeze]: 'link',
  [ProductStatus.active]: 'success',
  [ProductStatus.inactive]: 'error'
}

export enum ProductType {
  physical = 'physical',
  digital = 'digital'
}

export enum WeightUnitType {
  kilogram = 'kg',
  gram = 'g',
  pound = 'lb',
  once = 'oz'
}

export enum BulkActionList {
  productBulkUpdate = 'updateStatus',
  productBulkDelete = 'deleteProduct'
}

export interface ISeller {
  id: Id
  fullName: string
  storeName: string
}

export interface IMerchantBranches {
  id: Id
  name: string
}

export interface IProductVariant {
  variant: IVariantsItemProduct
  variantOptions: IProductVariantOptions[]
}

export interface IVariantItem {
  id: Id
  variantOptions: IProductVariantOptions[]
  skuCode: string
  barcode: string
  salePrice: number
  listPrice: number
  cost: number
  weight: number
  weightUnit: WeightUnitType
  systemWeight: number
  quantity: number
  hasDeleted: boolean | false
  variantItemDetails: string
  images: (string | File)[]
  variantImages: (string | File)[]
  profit: number
  trackQuantity: boolean

  [key: string]:
    | string
    | number
    | boolean
    | IProductVariantOptions[]
    | (string | File)[]
}

export interface IProductVariantOptions {
  id: Id
  name: string
  value?: string
  variantId: Id
  code: string
  variantName?: string
  variantOption?: string
}

export interface IVariantsWithOptions {
  id: Id
  name: string
  variantOptions: {
    id: Id
    name: string
  }[]
}

export interface IProduct extends IMetadata {
  id: Id
  name: string
  description: string
  category: ICategoryList
  categoryId: Id
  images: Images[]
  tags: (ITag | string)[]
  tagIds: Id[]
  status: ProductStatus
  statusTracking: [
    {
      notes: string
      status: ProductStatus
      createdAt: string
      createdBy: string
    }
  ]
  salePrice: number
  listPrice: number
  price: number
  cost: number
  skuCode: string
  barcode: string
  weight: number
  weightUnit: WeightUnitType
  systemWeight: number
  trackQuantity: boolean
  quantity: number
  merchant: ISeller
  merchantId: Id
  merchantBranches: IMerchantBranches[]
  merchantBranchIds: Id[]
  productType: ProductType
  file: string
  note: string
  productVariants: IVariantItem[]
  variants: IVariantItem[]
  variantsWithOptions: (IVariantsWithOptions | IProductVariant)[]
  numOfVariants: number
  brand: IBrandList
  brandId: Id
  bulkActionType?: string
  weightEstimationStatus?: WeightEstimationStatus
  variantImages: { image: string }[]
  systemSku?: string
  sellerSku?: string
  variantOptions: {
    name: string
    value: string
  }[]
  productName: string
}

export type preparedData = {
  [key: string]: any
}

export default interface IUpsertProductData {
  product: IProduct
  variants: IVariantItem[]
}
