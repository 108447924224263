
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { sellerEndpoints } from '@/modules/seller'
import { IProduct } from '@/modules/inventory/product/types/productTypes'
import { stockEndpoints } from '@/modules/inventory/stock'
import { ExportDialogName } from '@/modules/celery-task/types/celeryTaskTypes'
import { productEndpoints } from '@/modules/inventory/product'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'

@Component({
  name: 'ExportFile',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ExportFile extends Vue {
  /** function will be passed to child components */
  fields = () => [
    Field.dropdown<IProduct>(
      'merchant',
      'merchantId',
      () => sellerEndpoints.list(),
      'fullName',
      'id'
    )
      .required()
      .setCol('col-12')
  ]

  /** submit the data to the desired endpoint.*/
  async handleSubmit(values: IProduct) {
    const dialogName = parsePathQueryString(location.search).dialog

    const endpointsMap: Record<ExportDialogName, () => Promise<void>> = {
      [ExportDialogName.PRODUCT]: () =>
        productEndpoints.export({ merchantId: values.merchantId }),
      [ExportDialogName.STOCK]: () =>
        stockEndpoints.export({}, { merchantId: values.merchantId })
    }

    if (dialogName) {
      const exportFunction = endpointsMap[dialogName as ExportDialogName]
      if (exportFunction) {
        await exportFunction().then(() => this.closePopUp())
      }
    }
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
