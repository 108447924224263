import { ModelApi } from '@/services/api/modelApi'
import { IMenu, IRoles } from '@/modules/preference/role/types/roleTypes'
import axios from 'axios'

/**
 * Role endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class RoleEndpoints extends ModelApi<IRoles> {
  constructor() {
    super('role')
  }

  /**
   * Get all permissions that will be used in the set permissions dialog to
   * assign permissions to a Staff member.
   */
  getAllPermissions(): Promise<IMenu[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`sidebar/menu/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
