import {
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators'
import { IBackendNotification, INotification } from '@/core/types/coreTypes'

@Module({ namespaced: true, name: 'notification' })
export default class NotificationsStore extends VuexModule {
  notification: INotification = {} as INotification
  isVisible = false

  /**
   * Get notification data from vuex store.
   */
  get getNotification() {
    return this.notification
  }

  /**
   * Get notification status from vuex store.
   */
  get getIsNotificationVisible() {
    return this.isVisible
  }

  /**
   * Store notification data in the vuex then the notification automatically
   * will be displayed in the app and also the default notification type is
   * 'success'.
   */
  @MutationAction({ mutate: ['notification'], rawError: true })
  async pushNotification(notification: IBackendNotification) {
    const type = notification.type ?? 'success'
    const messageList: string[] = []
    switch (typeof notification.messages) {
      case 'string':
        messageList.push(notification.messages)
        break

      case 'object':
        for (const key in notification.messages) {
          // type is array
          if (Array.isArray(notification.messages[key]))
            messageList.push(
              ...(notification.messages[key] as string[]).map(
                message => `${key}: ${message}`
              )
            )
          // type if string
          else if (typeof notification.messages[key] === 'string')
            messageList.push(notification.messages[key] as string)
          // type is record of number to string array
          else if (typeof notification.messages[key] === 'object') {
            for (const index in notification.messages[key] as Record<
              number,
              string[]
            >) {
              const messages = notification.messages[key][index]
              messageList.push(...messages)
            }
          }
        }
        break
    }

    return { notification: { type, messages: messageList } }
  }

  /**
   * Remove notification data in the vuex store to be ready for the next
   * notification and sometimes the message will be the same if you remove it
   * and showed again user will feel that it is another message. This method
   * will call every time when a notification displayed in the
   * AppNotification component.
   */
  @MutationAction({ mutate: ['notification'] })
  async removeNotificationData() {
    const notification: INotification = { messages: [] }
    return { notification }
  }

  /**
   * Dismiss displayed notification manually because error notification will not
   * isVisible automatically that is why we have to isVisible it manually if we need
   * it.
   */
  @MutationAction({ mutate: ['isVisible'] })
  async dismissNotification() {
    return { isVisible: false }
  }

  @Mutation
  dismissError() {
    if (this.notification.type === 'error') this.isVisible = false
  }

  @MutationAction({ mutate: ['isVisible'] })
  async makeVisible() {
    return { isVisible: true }
  }
}
