import Vue from 'vue'
import { AnyObject } from '@/shared/types/builtInTypes'

// new Vue Instance
const eventBusVue = new Vue()

/**
 * event bus interface with two functions
 * 1). register which takes a handler and a handler's key to set it on the vue instance v.on
 * 2). deregister (unsubscribe) the handler which only needs its key to v.off it
 */
interface IEventBus extends IEventBusMethods {
  register: (
    key: keyof IEventBusMethods,
    handler: (...args: unknown[]) => unknown
  ) => void

  deregister: (key: keyof IEventBusMethods) => void
}

/**
 * a method to emit a key handler
 */
interface IEventBusMethods {
  refreshData: (options: AnyObject) => void
}

/**
 * event bus class that implements even bus interface
 */
class EventBus implements IEventBus {
  register(
    key: keyof IEventBusMethods,
    handler: (...args: AnyObject[]) => unknown
  ) {
    eventBusVue.$on(key, handler)
  }

  deregister(key: keyof IEventBusMethods) {
    eventBusVue.$off(key)
  }

  refreshData() {
    eventBusVue.$emit('refreshData')
  }
}

const eventBus = new EventBus()
export default eventBus
