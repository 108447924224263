import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IWorkflowManagement } from '@/modules/seller-management/workflow-management/types/workflowManagementTypes'
import i18n from '@/plugins/i18n'

/**
 * dynamic component generator method
 * @param workflowManagement is IWorkflowManagement interface type which
 * @return An Array of IPreviewComponent workflow management type
 */
export function workflowManagementPreviewComponents(
  workflowManagement: IWorkflowManagement
): PreviewComponent<IWorkflowManagement>[] {
  return [
    PreviewComponent.labelValue<IWorkflowManagement>(
      'requestFlowName',
      'name',
      workflowManagement.name
    )
      .setTitle('requestFlowDetails')
      .setBreak(true),
    PreviewComponent.labelValue<IWorkflowManagement>(
      'description',
      'description',
      workflowManagement.description
    )
      .setCol('12')
      .setBreak(true),
    PreviewComponent.fileLink<IWorkflowManagement>(
      '',
      'attachment',
      workflowManagement.attachment as string
    )
      .setTitle('requestFlowFile')
      .setClass('col-12')
      .setProp({
        customFileName: i18n.t('label.workflow')
      }),
    ...metadataLabelValues(workflowManagement)
  ]
}
