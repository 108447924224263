import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertEstimationArrival from '@/modules/seller-management/estimation-arrival/views/TheUpsertEstimationArrival.vue'
import { estimationArrivalEndpoints } from '@/modules/seller-management/estimation-arrival'
import { estimationArrivalPreviewComponents } from '@/modules/seller-management/estimation-arrival/helpers/previewComponents'

/** Estimation Arrival module routes*/
export const estimationArrivalRoutes: RouteConfig[] = [
  {
    path: '/estimation-arrival',
    name: 'estimationArrival',
    component: () =>
      import(
        /* webpackChunkName: "TheEstimationArrivalChunk" */ '@/modules/seller-management/estimation-arrival/views/TheEstimationArrival.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.estimationArrivalMetaTitle'
    }
  },
  {
    path: '/estimation-arrival/preview-details/:id',
    name: 'preview-estimationArrival',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewEstimationArrivalChunk" */ '@/modules/seller-management/estimation-arrival/views/ThePreviewEstimationArrival.vue'
      ),
    meta: {
      title: 'navigations.estimationArrivalMetaTitle',
      parentRouteName: 'estimationArrival',
      logPage: {
        endpoint: estimationArrivalEndpoints,
        previewDynamicComponents: estimationArrivalPreviewComponents
      }
    }
  }
]
export const estimationArrivalModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertEstimationArrival,
    persistent: true,
    name: 'upsert-estimationArrival',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-estimationArrival',
    requiredParams: ['id'],
    onDelete: q => estimationArrivalEndpoints.delete(q.id.toString()),
    dialogTitle: 'estimationArrivalTitle'
  }
]
