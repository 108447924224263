
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'

@Component({
  name: 'BaseDialog'
})
export default class BaseDialog extends Vue {
  /**
   * Pass all the properties of an object as props
   *   `size?: number | string`: Specify the size of the dialog,<br/>
   *   `persistent: boolean`: Setting to true means not dismissed when touching outside or pressing esc key, <br/>
   *   `name: string`: Name of component in the route, <br/>
   *   `requiredParams: string[]`: Specify the array of params, <br/>
   *   `params?: Record<string, string>`: Set params,<br/>
   *   `component: Component`: Call the component that you want to be shown inside the dialog, <br/>
   *   `onDelete?: (queries: Route['query']) => void`: Used as await function, wait until that promise settles and return the result, <br/>
   *   `onArrived?: (queries: Route['query']) => void`, <br/>
   *   `dialogTitle?: TranslateResult`: Set title to dialog
   */
  @Prop(Object) readonly dialog!: PopUpRouteConfig

  /**
   * Show is a toggle prop use it to show and hide the dialog.
   */
  @Prop(Boolean) readonly show!: boolean

  /**
   * get dialog width if it was provided as a props else set it to 700px as default
   */
  get getSize() {
    return this.dialog.size ? this.dialog.size : '400'
  }
}
