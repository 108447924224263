
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { internationalLogisticEndpoints } from '@/modules/seller-management/international-logistic'
import { IInternationalLogistic } from '@/modules/seller-management/international-logistic/types/internationalLogisticTypes'
import { countryEndpoints } from '@/modules/seller-management/country'

@Component({
  name: 'TheUpsertInternationalLogistic',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertInternationalLogistic extends Vue {
  loading = false
  data = {} as IInternationalLogistic
  id = this.$route.params.id || ''
  originalData = {} as IInternationalLogistic

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.internationalLogistic.edit')}`
      : `${this.$t('dialog.internationalLogistic.addNew')}`

  fields(): Field<IInternationalLogistic>[] {
    return [
      Field.dropdown<IInternationalLogistic>('country', 'countryId', () =>
        countryEndpoints.list()
      )
        .required()
        .setCol('12'),
      Field.currency<IInternationalLogistic>('cost', 'cost')
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IInternationalLogistic,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await internationalLogisticEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await internationalLogisticEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await internationalLogisticEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
