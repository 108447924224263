
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'

import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IDenomination } from '@/modules/arbela-gift-card/denomination/types/denominationTypes'
import { denominationEndpoints } from '@/modules/arbela-gift-card/denomination'
import { Route } from 'vue-router'

@Component({
  name: 'TheUpsertDenomination',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertDenomination extends Vue {
  data = {} as IDenomination
  originalData = {} as IDenomination
  id = this.$route.params.id || ''
  loading = false

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.denomination.edit')}`
      : `${this.$t('dialog.denomination.addNew')}`

  /**
   * function will be passed to child components
   * @params denomination which modules type the fetched Denomination will be passed to it in the child component. (base form)
   */
  fields(): Field<IDenomination>[] {
    return [
      Field.text<IDenomination>('label', 'label').setCol('col-12').required(),
      Field.currency<IDenomination>('amount', 'amount', [], true)
        .setCol('col-12')
        .setProps({
          readonly: !!this.id
        })
        .required(!this.id)
    ]
  }
  /** submit the data to the desired endpoint.*/
  async handleSubmit(
    values: IDenomination,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await denominationEndpoints
        .patch(q.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await denominationEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.id = id
        this.data = await denominationEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
