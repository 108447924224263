import { ModelApi } from '@/services/api/modelApi'
import { IWeightEstimation } from '@/modules/inventory/weight-estimation/types/weightEstimationTypes'

/**
 * Weight Estimation endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class WeightEstimationEndpoints extends ModelApi<IWeightEstimation> {
  constructor() {
    super('weight_estimation')
  }
}
