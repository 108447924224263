
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { brandEndpoints } from '@/modules/preference/brand'
import { IBrand } from '@/modules/preference/brand/type/brandTypes'

@Component({
  name: 'TheUpsertBrand',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertBrand extends Vue {
  loading = false
  data = {} as IBrand
  id = this.$route.params.id || ''
  originalData = {} as IBrand

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.brand.edit')}`
      : `${this.$t('dialog.brand.addNew')}`

  /** function will be passed to child components */
  fields(): Field<IBrand>[] {
    return [
      Field.photo<IBrand>('brandImage', 'image').setCol('col-12').required(),
      Field.text<IBrand>('brandName', 'name').setCol('col-12').required()
    ]
  }

  /** submit the data to the desired endpoint.*/
  async handleSubmit(
    values: Pick<IBrand, 'id'>,
    _params: Record<string, number>,
    queries: Record<string, number>
  ) {
    if (queries.id) {
      await brandEndpoints
        .patch(queries.id, this.originalData, values, true)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await brandEndpoints.post(values, true).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await brandEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
