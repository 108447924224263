
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { ICommission } from '@/modules/accounting/commission/types/commissionTypes'
import { commissionEndpoints } from '@/modules/accounting/commission'

@Component({
  name: 'TheUpsertCommission',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertCommission extends Vue {
  loading = false
  data = {} as ICommission
  id = this.$route.params.id || ''
  originalData = {} as ICommission

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.commission.edit')}`
      : `${this.$t('dialog.commission.addNew')}`

  /**
   * function will be passed to child components
   */
  fields(): Field<ICommission>[] {
    return [
      Field.text<ICommission>('name', 'name').setCol('col-12').required(),
      Field.currency<ICommission>('amount', 'amount')
        .setProps({
          prefix: '%'
        })
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setRules(`between:0,100`)
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: ICommission,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (this.originalData.amount !== values.amount)
      values.amount = +(values.amount / 100).toFixed(4)

    if (q.id) {
      await commissionEndpoints
        .patch(q.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await commissionEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await commissionEndpoints.get(id).then(res => {
          //on edit convert it to percentage format
          res.amount = Number(ContentFormatter.$formatToPercentage(res.amount))
          return res
        })
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
