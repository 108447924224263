
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'PreviewAvatar' })
export default class PreviewAvatar extends Vue {
  /**
   * Specify a URI for an external file or resource, display circular user profile pictures.
   */
  @Prop(String) readonly profileImage?: string
  /**
   * Adds a loading using placeholder or skeleton while handle the image.
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean
  /**
   * Sets the maximum height and maximum width to the avatar.
   */
  @Prop({ type: Number, default: 150 })
  readonly size!: number
  /**
   * Displaying the default avatar based on gender if "f" = female | "m" = male .
   */
  @Prop({ type: String, default: 'm' })
  readonly gender?: string
  /**
   * Applies specified color to the avatar.
   */
  @Prop({ type: String, required: false })
  readonly outlineColor?: string
}
