import { ModelApi } from '@/services/api/modelApi'
import { ILocalization } from '@/modules/preference/localization/types/localizationTypes'
import axios from 'axios'
import { Id } from '@/shared/types/builtInTypes'

/**
 * Localization endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class LocalizationEndpoints extends ModelApi<ILocalization> {
  constructor() {
    super('localization')
  }

  async exportFile(platformId: Id, platformName: string): Promise<unknown> {
    return axios
      .post(
        `/platform/${platformId}/export_platform_localization/`,
        {},
        { responseType: 'blob' }
      )
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const today = new Date().toISOString().substr(0, 10).replace('_', '-')
        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          `Localization-${platformName}-${today}.csv`
        )
        document.body.appendChild(fileLink)

        fileLink.click()
      })
      .catch(async error => {
        //`.text()` method is used to read the contents of a Blob object as text.
        const errorResponse = await error.response.text()
        const parseResponse = JSON.parse(errorResponse)
        this.responseNotify(parseResponse.data.detail, 'error')
      })
  }

  async importFile(
    platformId: Id,
    data: Record<string, unknown>
  ): Promise<unknown> {
    const form = ModelApi.rearrangeData(data, true)
    try {
      await axios.post(
        `/platform/${platformId}/import_platform_localization/`,
        form
      )
      return this.responseNotify('notifications.updatedSuccessfully')
    } catch (error) {
      this.responseNotify(error.response.data, 'error')
      throw error.response.data
    }
  }
}
