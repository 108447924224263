import { ModelApi } from '@/services/api/modelApi'
import { IInternationalLogistic } from '@/modules/seller-management/international-logistic/types/internationalLogisticTypes'

/**
 * International Logistic endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class InternationalLogisticEndpoints extends ModelApi<IInternationalLogistic> {
  constructor() {
    super('international_logistic')
  }
}
