import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import TheUpdateStatusSellerRequest from '@/modules/request/seller-request/views/TheUpdateStatusSellerRequest.vue'

/** Seller Request module routes */
export const sellerRequestRoutes: RouteConfig[] = [
  {
    path: '/seller-request',
    name: 'sellerRequest',
    component: () =>
      import(
        /* webpackChunkName: "TheSellerRequestChunk" */ '@/modules/request/seller-request/views/TheSellerRequest.vue'
      ),
    meta: {
      title: 'navigations.sellerRequestMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/seller-request/preview-details/:id',
    name: 'preview-sellerRequest',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewSellerRequestChunk" */ '@/modules/request/seller-request/views/ThePreviewSellerRequest.vue'
      ),
    meta: {
      title: 'navigations.sellerRequestMetaTitle',
      parentRouteName: 'sellerRequest'
    }
  },
  {
    path: '/seller-request/preview-details/:id/review-details/tracking-id/:trackingId/elementIds/:elementIds?/action-type/:actionType/status/:status?',
    name: 'review-sellerRequest',
    component: () =>
      import(
        /* webpackChunkName: "TheReviewSellerRequestChunk" */ '@/modules/request/seller-request/views/TheReviewSellerRequest.vue'
      ),
    meta: {
      title: 'navigations.sellerRequestMetaTitle',
      parentRouteName: 'preview-sellerRequest'
    }
  }
]

export const sellerRequestModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpdateStatusSellerRequest,
    size: 600,
    persistent: true,
    name: 'update-seller-request-status',
    requiredParams: ['trackingId', 'elementIds']
  }
]
