import Vue from 'vue'
import {
  configure,
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import {
  between,
  confirmed,
  email,
  ext,
  is_not,
  max_value,
  min,
  min_value,
  numeric,
  required,
  required_if
} from 'vee-validate/dist/rules'
import i18n from './i18n'

import { parsePhoneNumber } from 'awesome-phonenumber'

// Configure validation message translations.
configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = field !== '{field}' ? i18n.t(`label.${field}`) : ''
    return i18n.t(`validation.${values._rule_}`, values).toString()
  }
})

// Sets the time to validate.
// See https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-and-ux
setInteractionMode('eager')

// Install rules.
extend('required', required)
extend('required_if', required_if)
extend('email', email)
extend('min', min)
extend('min_value', min_value)
extend('max_value', max_value)
extend('confirmed', confirmed)
extend('between', between)
extend('numeric', numeric)
extend('is_not', is_not)
extend('ext', ext)

/**
 * Phone Validation
 * User can add space also can use the following pattern:
 * 00964(...), 07(...) or 7(...)
 * ex:
 * 00964 773 553 1800
 * 00964 753 553 1800
 * 0750 553 1800
 * 0750 553 1800
 * 750 553 1800
 * 770 553 1800
 *
 *  International: Use the package with handling the missing rules that it is uncounted 2 letters in the end
 * /^(\+\d{7,}\s?)$/gm --> check if it is more than 7 digits and avoid any letter
 *  Local: any number start with 07 or 7 or 009647 and contain 9 digits
 */
extend('phone', {
  params: ['target'],
  validate(value): boolean {
    // Remove counting space for phone number
    const valueWithoutSpace = value.replace(/\s+/g, '')
    return (
      (parsePhoneNumber(valueWithoutSpace).valid &&
        /^(\+\d{7,}\s?)$/gm.test(valueWithoutSpace)) ||
      /^(?:(009647)|(07)|(7))\d{9}$/gm.test(valueWithoutSpace)
    )
  }
})

extend('password', (value: string) =>
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
)
extend('url', (value: string) =>
  /(https:\/\/.)([-a-zA-Z0-9@:%._+~#=]\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    value
  )
)

/** short alert validation (warning amount must be bigger than danger amount) */
extend('bigger_amount', {
  params: ['target'],
  validate(value, { target }: Record<string, any>): boolean {
    return value > target
  }
})
/** New Password should not match old password */

extend('compare_password', {
  params: ['target'],
  validate(value, { target }: Record<string, any>): boolean {
    return value !== target
  }
})

/**
 * Validates Email And Phone At The Same Time
 * User can add space also can use the following pattern for phone:
 * 00964(...) , 07(...) or 7(...)
 * ex:
 * 00964 773 553 1800
 * 00964 753 553 1800
 * 0750 553 1800
 * 0750 553 1800
 * 750 553 1800
 * 770 553 1800
 *  International : Use the package with handling the missing rules that it is uncounted 2 letters in the end
 * /^(\+\d{7,}\s?)$/gm  --> check if it more than 7 digit and avoid any letter
 *  Local : any number start with 07 or 7 or 009647 and contain 9 digit
 */
extend('phone_or_email', {
  params: ['target'],
  validate(value): boolean {
    // Remove counting space for phone number
    const valueWithoutSpace = value.replace(/\s+/g, '')
    return (
      email.validate(value) ||
      (parsePhoneNumber(valueWithoutSpace).valid &&
        /^(\+\d{7,}\s?)$/gm.test(valueWithoutSpace)) ||
      /^(?:(009647)|(07)|(7))\d{9}$/gm.test(valueWithoutSpace)
    )
  }
})
extend('chip_url', function (val) {
  for (let i = 0; i < val.length; i++) {
    const isValid =
      /^(https:\/\/.)([-a-zA-Z0-9@:%._+~#=]\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
        val[i]
      )
    if (!isValid) {
      return i18n.t('validation.url').toString()
    }
  }
  return true
})

extend('duplicated_variant_name', {
  params: ['isDuplicated'],
  validate: function (value, { isDuplicated }: Record<string, any>) {
    return isDuplicated == 'Yes'
      ? i18n.t('validation.duplicated_variant_name').toString()
      : true
  }
})

// Add ValidationProvider and ValidationObserver component.
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
