import { Id, IMetadata } from '@/shared/types/builtInTypes'
import {
  ICustomerAddress,
  ICustomerList
} from '@/modules/customer/types/customerTypes'

/** refundPrice enum */
export enum refundPriceType {
  basePrice = 'base_price',
  taxes = 'taxes',
  logistics = 'logistics'
}

/** Inspection enum */
export enum inspectionType {
  itemMatchesTheReturnRequest = 'itemMatchesTheReturnRequest',
  originalTaq = 'originalTaq',
  packageDamaged = 'packageDamaged',
  productIsUsed = 'productIsUsed',
  barcodeMatchingRecords = 'barcodeMatchingRecords',
  itemDamaged = 'itemDamaged'
}

/** InspectionOutcome enum */
export enum inspectionOutcomeType {
  acceptReturn = 'accept',
  rejectReturn = 'reject'
}

/** order status enum */
export enum returnedOrderStatus {
  rInitiated = 'r_initiated',
  rCanceled = 'r_canceled',
  rCollected = 'r_collected',
  rInspection = 'r_inspection',
  rConfirmed = 'r_confirmed',
  rCompleted = 'r_completed',
  rDeclined = 'r_declined',
  rRefund = 'r_refund',
  rLocal = 'r_local',
  rInternational = 'r_international'
}

/** order status colors enum */
export const returnedOrderStatusColors: Record<
  returnedOrderStatus | string,
  string
> = {
  [returnedOrderStatus.rInitiated]: 'warning',
  [returnedOrderStatus.rCanceled]: 'error',
  [returnedOrderStatus.rCollected]: 'info',
  [returnedOrderStatus.rInspection]: 'info',
  [returnedOrderStatus.rConfirmed]: 'success',
  [returnedOrderStatus.rCompleted]: 'success',
  [returnedOrderStatus.rDeclined]: 'error',
  [returnedOrderStatus.rRefund]: 'success',
  [returnedOrderStatus.rLocal]: 'onSurfaceMedium',
  [returnedOrderStatus.rInternational]: 'onSurfaceMedium'
}

export interface IReturnedOrder extends IMetadata {
  id: Id
  orderNumber: number
  orderDate: string
  code: string
  customer: ICustomerList
  numberOfItems: number
  totalAmount: number
  cartFee: number
  totalAdminFee: number
  totalTax: number
  totalShipmentCost: number
  totalDeliveryCost: number
  totalDiscountAmount: number
  grandTotalAmount: number
  paymentType: string
  deliveryOption: {
    id: Id
    name: string
  }
  currencyRateName: string
  currencyRate: string
  totalPrice: number
  totalLogistic: number
  totalCommission: number
  createdAt: string
  paymentStatus: string
  paymentMethod: string
  orderStatus: string
  returnedOrder: returnedOrderStatus
  customerFullName: string
  customerId: Id
  customerAddress: Pick<ICustomerAddress, 'location' | 'phone'>
  invoices: {
    id: Id
    sellerFullName: string
    invoiceNumber: string
    totalAmount: number
  }[]
  inspection: inspectionType
  inspectionOutcome: inspectionOutcomeType
  note: string
  returnedStatus: returnedOrderStatus
  refundPrice: refundPriceType
  totalRefund: number
  orderItemId: Id
  returnReason: string
  notes: string
  refundAmount: number
  refundPrices: string
  quantity: number
  unitPrice: number
  refundNote: string
  returnStatus: string
}
