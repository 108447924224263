import axios from 'axios'
import { ApiService } from '@/services/api/apiService'
import { ILoginForm, LoginResponse } from '@/modules/login/types/loginTypes'
import appStore from '@/AppStore'
import appRouter from '@/AppRouter'
import {
  IChangePasswordForm,
  IUser
} from '@/modules/force-change-password/types/ForceChangePasswordType'
import { ModelApi } from '@/services/api/modelApi'
import { getDiff } from '@/shared/helpers/getDifference'

export default class AuthEndpoints extends ApiService {
  constructor() {
    super('auth')
  }

  static handleAuthentication(data: LoginResponse): void {
    appStore.commit('auth/setAuthentication', !!data.token)
    appStore.commit('auth/setExpiryToken', data.expiry)
    appStore.commit('auth/setForceChangePassword', data.changePasswordRequired)
  }

  static handleForcePassword(data: LoginResponse, url: string) {
    if (!data.changePasswordRequired) {
      url !== 'auth/refresh/' && appRouter.push({ path: '/' }).finally()
    } else {
      appRouter.push({ name: 'ForceChangePassword' }).finally()
    }
  }

  static handleUserMeAuth(data: IUser) {
    appStore.commit('auth/setMeInfo', data)
    appStore.dispatch('auth/setUserPermissions', data.userPermissions).finally()
  }

  authMe() {
    return axios.get('me/').finally()
  }

  login(data: ILoginForm) {
    return axios.post('auth/login/', data).catch(error => {
      this.responseNotify(error.response.data.detail, 'error')
      throw error.data
    })
  }

  logout() {
    return axios.post('auth/logout/').catch(error => {
      throw error.data
    })
  }

  refresh() {
    return new Promise((resolve, reject) =>
      axios
        .post(`auth/refresh/`, {})
        .then(res => resolve(res.data.data))
        .catch(error => {
          appStore.commit('auth/setAuthentication', false)
          appRouter.push('/login').finally()
          reject(error.response.data)
        })
    )
  }

  handleChangePassword(data: IChangePasswordForm) {
    return axios
      .post('me/change_password/', {
        oldPassword: data.oldPassword,
        password: data.password,
        confirmPassword: data.confirmPassword
      })
      .then(() => {
        //  FIXME Change these lines when backend return token with me response
        // AuthEndpoints.handleAuthentication(response.data)
        appStore.commit('auth/setAuthentication', true)
        appStore.commit('auth/setForceChangePassword', false)
        this.responseNotify('notifications.updatedSuccessfully')
      })
      .catch(error => {
        this.responseNotify(error.response.data.detail, 'error')
        throw error.data
      })
  }

  updateProfile(data: IUser, originalData: IUser) {
    const differences = getDiff(originalData, data)
    // if there was no change, just return a success promise
    if (!differences) {
      this.responseNotify('notifications.nothingChanged', 'error')
      return Promise.reject('No changed value')
    }

    const newData = ModelApi.rearrangeData(differences, true, true)
    return axios
      .post('/me/update_profile/', newData)
      .then(response => {
        this.responseNotify('notifications.updatedSuccessfully')
        appStore.commit('auth/setMeInfo', data)
        return response.data.data
      })
      .catch(error => {
        this.responseNotify(error.response.data.detail, 'error')
        throw error.data
      })
  }
}
