import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { collectionEndpoints } from '@/modules/page/collection'
import { collectionPreviewComponents } from '@/modules/page/collection/helpers/previewComponents'

/**
 * Collection module routes
 */
export const collectionRoutes: RouteConfig[] = [
  {
    path: '/collection',
    name: 'collection',
    component: () =>
      import(
        /* webpackChunkName: "TheCollectionChunk" */ '@/modules/page/collection/views/TheCollection.vue'
      ),
    meta: {
      title: 'navigations.collectionMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/collection/preview-details/:id',
    name: 'preview-collection',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCollectionChunk" */ '@/modules/page/collection/views/ThePreviewCollection.vue'
      ),
    meta: {
      title: 'navigations.collectionMetaTitle',
      parentRouteName: 'collection',
      logPage: {
        endpoint: collectionEndpoints,
        previewDynamicComponents: collectionPreviewComponents
      }
    }
  },
  {
    path: '/collection/upsert-collection/:id?',
    name: 'upsert-collection',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertCollectionChunk" */ '@/modules/page/collection/views/TheUpsertCollection.vue'
      ),
    meta: {
      title: 'navigations.collectionMetaTitle',
      parentRouteName: 'collection'
    }
  }
]

export const collectionModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-collection',
    requiredParams: ['id'],
    onDelete: q => collectionEndpoints.delete(q.id.toString()),
    dialogTitle: 'collectionTitle'
  }
]
