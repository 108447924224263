import { ModelApi } from '@/services/api/modelApi'

/**
 * Workflow Management endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class WorkflowManagementEndpoints<Type> extends ModelApi<Type> {
  constructor(sectionPath: string, moduleName?: string) {
    super(sectionPath, moduleName)
  }
}
