import { IStaff } from '@/modules/staff/types/staffTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import i18n from '@/plugins/i18n'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param staff is IStaff interface type which
 * @return An Array of IPreviewComponent staff type
 */
export function staffPreviewComponents(
  staff: IStaff
): PreviewComponent<IStaff>[] {
  return [
    PreviewComponent.avatar(
      'profileImage',
      staff.profileImage,
      staff.gender,
      140
    ),
    PreviewComponent.labelValue<IStaff>(
      'firstName',
      'firstName',
      staff.firstName
    ),
    PreviewComponent.labelValue<IStaff>('lastName', 'lastName', staff.lastName),
    PreviewComponent.labelValue<IStaff>('email', 'email', staff.email),
    PreviewComponent.labelValue<IStaff>('phone', 'phone', staff.phone),
    PreviewComponent.labelValue<IStaff>('position', 'position', staff.position),
    PreviewComponent.labelValue<IStaff>('role', 'role', staff.role?.name),
    PreviewComponent.labelValue<IStaff>(
      'countryName',
      'cityId',
      staff.city?.name
    ),
    PreviewComponent.labelValue<IStaff>(
      'gender',
      'gender',
      staff.gender === 'f' ? i18n.t('label.female') : i18n.t('label.male')
    ),

    PreviewComponent.labelValue<IStaff>(
      'status',
      'isActive',
      staff.isActive ? i18n.t('label.active') : i18n.t('label.inActive')
    ).setClass(staff.isActive ? 'success--text' : 'error--text'),
    ...metadataLabelValues(staff),
    PreviewComponent.labelValue<IStaff>('notes', 'notes', staff.notes).setCol(
      'pa-3 col-12'
    )
  ]
}
