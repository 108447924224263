import { ApiIntegrationEndpoint } from '@/modules/preference/api-integration/api/apiIntegrationEndpoint'
import {
  IApiConfig,
  IApiRequest
} from '@/modules/preference/api-integration/types/apiIntegration'

export const apiConfigEndpoint = new ApiIntegrationEndpoint<IApiConfig>(
  'api_config',
  'integration'
)
export const apiRequestEndpoint = new ApiIntegrationEndpoint<IApiRequest>(
  'rest_api',
  'integration'
)

export const logApiConfigEndpoint = new ApiIntegrationEndpoint<IApiConfig>(
  'api_config'
)
export const logApiRequestEndpoint = new ApiIntegrationEndpoint<IApiRequest>(
  'rest_api'
)
export const tagRequestEndpoint = new ApiIntegrationEndpoint<IApiRequest>(
  'request_tag',
  'integration'
)
