import { ModelApi } from '@/services/api/modelApi'
import { ICity } from '@/modules/preference/city/types/cityTypes'
import axios from 'axios'

/**
 * City endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class CityEndpoints extends ModelApi<ICity> {
  constructor(urlPath: string) {
    super(urlPath)
  }
  async setCityOrder(itemsId: Array<number>) {
    try {
      const response = await axios.post(`${this.getUrl()}reorder_city/`, {
        cityIds: itemsId
      })
      this.responseNotify('notifications.updatedSuccessfully')
      return response.data.data
    } catch (error) {
      this.responseNotify(error.response.data, 'error')
      throw error.response.data
    }
  }
}
