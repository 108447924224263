import { RouteConfig } from 'vue-router'

/** Setting module routes */
export const settingRoutes: RouteConfig[] = [
  {
    path: '/setting',
    name: 'setting',
    component: () =>
      import(
        /* webpackChunkName: "TheSettingChunk" */ '@/modules/preference/setting/views/TheSetting.vue'
      ),
    meta: {
      title: 'navigations.settingMetaTitle',
      parentRouteName: null
    }
  }
]
