
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IWeightEstimation } from '@/modules/inventory/weight-estimation/types/weightEstimationTypes'
import { weightEstimationEndpoints } from '@/modules/inventory/weight-estimation'
import { categoryEndpoint } from '@/modules/inventory/category'

@Component({
  name: 'TheUpsertWeightEstimationKeyword',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertWeightEstimationKeyword extends Vue {
  loading = false
  id = parsePathQueryString(location.search).id || ''
  data = {} as IWeightEstimation
  originalData = {} as IWeightEstimation

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.weightEstimation.edit')}`
      : `${this.$t('dialog.weightEstimation.addNew')}`

  /** function will be passed to child components */
  fields(weightEstimation: IWeightEstimation): Field<IWeightEstimation>[] {
    return [
      Field.text<IWeightEstimation>('title', 'title')
        .setCol('col-12')
        .required(),
      Field.autocompleteScroll<IWeightEstimation>(
        'category',
        'categoryId',
        () =>
          categoryEndpoint.list({
            params: {
              limit: 10,
              selectedIds: weightEstimation?.categoryId
            }
          }),
        'path',
        'id',
        {
          searchFetcher: params =>
            categoryEndpoint.list({
              params
            })
        }
      )
        .setCol('12')
        .setHidden(!weightEstimation?.category?.id && !!this.id)
        .required(),
      Field.currency<IWeightEstimation>('minWeightKg', 'minWeight')
        .setRules(`min_value:0.1`)
        .setCol('col-12')
        .required(),
      Field.currency<IWeightEstimation>('maxWeightKg', 'maxWeight')
        .setRules(
          `${
            weightEstimation && weightEstimation.minWeight
              ? `min_value:${weightEstimation.minWeight + 0.1}`
              : ''
          }`
        )
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: IWeightEstimation,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await weightEstimationEndpoints
        .patch(q.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await weightEstimationEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    this.loading = true
    try {
      if (this.id) {
        this.data = await weightEstimationEndpoints.get(this.id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
