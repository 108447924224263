import { ModelApi } from '@/services/api/modelApi'
import {
  ICollection,
  IFilterKwargs,
  StyleType
} from '@/modules/page/collection/types/collectionTypes'
import axios from 'axios'
import AppRouter from '@/AppRouter'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import snakeCaseKeys from 'snakecase-keys'

/**
 * Collection endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class CollectionEndpoints extends ModelApi<ICollection> {
  constructor() {
    super('collection')
  }

  /**
   * get filter kwargs list
   * @param styleType : StyleType
   */
  getItemFilter(styleType?: StyleType): Promise<IFilterKwargs[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`collection/get_item_filters/`, {
          params: {
            styleType
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * add a collection to desired endpoint
   * @param data : ICollection
   */
  async addCollection(data: ICollection) {
    await axios
      .post(`/collection/`, this.prepareData(data))
      .then(() => {
        this.responseNotify('notifications.addedSuccessfully')
        AppRouter.back()
      })
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
      })
  }

  /** update collection from desired endpoint **/
  async updateCollection(id: Id, data: ICollection) {
    await axios
      .patch(`/collection/${id}/`, this.prepareData(data))
      .then(() => {
        this.responseNotify('notifications.updatedSuccessfully')
        AppRouter.back()
      })
      .catch(error => {
        this.responseNotify(
          error.response.data.detail || error.response.data,
          'error'
        )
      })
  }

  prepareData(data: ICollection): FormData {
    const convertedData = Object.fromEntries(Object.entries(data ?? {}))
    const collectionNewData = snakeCaseKeys(convertedData, {
      deep: false
    })
    const formData = new FormData()
    addCollectionToFormData(collectionNewData, formData)
    return formData
  }
}

function addCollectionToFormData<T extends AnyObject>(
  data: T,
  formData: FormData,
  parentKey?: string
) {
  Object.entries(data).forEach(([key, value]) => {
    const parent = parentKey ? parentKey + '.' : ''
    if (Array.isArray(value)) {
      value.forEach(elem => {
        if (elem instanceof File) formData.append(parent + key, elem)
        else if (typeof elem === 'object' && elem) {
          const newElem = snakeCaseKeys(elem ?? {}, {
            deep: true
          })

          if (key === 'filter_kwargs') {
            delete newElem.data
            delete newElem.type
            const filterKwargsElem = JSON.stringify(newElem)
            formData.append(parent + key, filterKwargsElem)
          } else
            addCollectionToFormData(
              newElem as AnyObject,
              formData,
              parent + key
            )
        } else {
          formData.append(parent + key, elem)
        }
      })
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof File) &&
      !(value instanceof Date)
    )
      addCollectionToFormData(value as AnyObject, formData, parent + key)
    else formData.append(parent + key, value as string)
  })
}
