import { AmountType } from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'
import { Id, IMetadata } from '@/shared/types/builtInTypes'
import {
  IProduct,
  IVariantItem,
  WeightEstimationStatus
} from '@/modules/inventory/product/types/productTypes'
import { IBrandList } from '@/modules/preference/brand/type/brandTypes'
import { ICategory } from '@/modules/inventory/category/types/categoryTypes'

export enum TypeAppliesTo {
  basePrice = 'base_price',
  commission = 'commission'
}

export enum TargetType {
  productVariant = 'product_variant',
  category = 'category',
  brand = 'brand'
}

export enum discountStatus {
  started = 'started',
  scheduled = 'scheduled',
  expired = 'expired'
}

export interface ITargetType {
  id: Id
  name: TargetType
}

export interface ITypeAppliesTo {
  id: Id
  name: TypeAppliesTo
}

export const discountStatusColors: Record<discountStatus | string, string> = {
  [discountStatus.started]: 'success',
  [discountStatus.scheduled]: 'warning',
  [discountStatus.expired]: 'onSurfaceOutline'
}

export interface IDiscount extends IMetadata {
  id: Id
  discountName: string
  amountType: AmountType
  amount: number
  startDate: string
  endDate: string
  appliesTo: string
  isActive: boolean
  notes?: string
  target: string
  status: discountStatus
  products: IProduct[]
  productIds: number[]
  brand: IBrandList[]
  brandIds: Id[]
  categories: ICategory[]
  categoryIds: Id[]
  categoryId: Id
  sellerId: Id
  merchantId: Id
  merchant: {
    id: Id
    fullName: string
  }
  storeName: string
  label: string
  sellerSku: string
  systemSku: string
  productName: string
  price: number
  discountPrice: number
  quantity: number
  variantImages: { image: string }[]
  productVariantIds: IProductVariants[]
  productVariants?: {
    id: Id
    sellerSku: string
    productName: string
    systemSku: string
    quantity: number
    price: number
    priceAfterDiscount: number
    barcode: string
    weight: number
    weightEstimationStatus: WeightEstimationStatus
    variantImages: { image: string }[]
    variantOptions: { name: string; value: string }[]
  }[]
  variants: IVariantItem[]
}

export interface IProductVariants {
  id: Id
  sellerSku: string
  productName: string
  systemSku: string
  quantity: number
  price: number
  priceAfterDiscount: number
  barcode: string
  weight: number
  weightEstimationStatus: WeightEstimationStatus
  variantImages: { image: string }[]
  variantOptions: { name: string; value: string }[]
  hasDeleted?: boolean
}
