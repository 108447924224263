import { RouteConfig } from 'vue-router'

export const loginRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "TheLoginChunk" */ '@/modules/login/views/TheLogin.vue'
      ),
    meta: {
      title: 'navigations.loginMetaTitle'
    }
  }
]
