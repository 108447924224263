import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import {
  IProduct,
  productStatusColors
} from '@/modules/inventory/product/types/productTypes'

/**
 * dynamic component generator method
 * @param product is IProduct interface type which
 * @return An Array of IPreviewComponent product type
 */
export function productPreviewComponents(
  product: IProduct
): PreviewComponent<IProduct>[] {
  return [
    PreviewComponent.labelValue<IProduct>('name', 'name', product.name)
      .setCol('col-6')
      .setTitle('information'),
    PreviewComponent.labelValue<IProduct>('productId', 'id', product.id).setCol(
      'col-6'
    ),
    PreviewComponent.labelValue<IProduct>(
      'description',
      'name',
      product.description
    )
      .setCol('12')
      .setBreak(true),
    PreviewComponent.labelValue<IProduct>(
      'seller',
      'merchantId',
      product.merchant?.fullName
    )
      .setTitle('productOrganization')
      .setCol('6'),
    PreviewComponent.labelValue<IProduct>('sellerBranch', 'merchantId', () =>
      product.merchantBranches.length
        ? product.merchantBranches?.map(branch => branch.name).join(', ')
        : ' - '
    ).setCol('6'),
    PreviewComponent.labelValue<IProduct>(
      'storeName',
      'merchant',
      product.merchant?.storeName
    ).setCol('6'),
    PreviewComponent.labelValue<IProduct>(
      'sellerId',
      'merchantId',
      product.merchant?.id
    ).setCol('6'),
    PreviewComponent.labelValue<IProduct>('tags', 'tagIds', () => {
      if (product.tags && product.tags.length > 0)
        return product.tags
          .map(obj => (typeof obj == 'object' ? obj.name : obj))
          .join(', ')
      else return ' - '
    })
      .setCol('6')
      .setClass('warning--text'),
    PreviewComponent.labelValue<IProduct>(
      'brands',
      'brand',
      product.brand && product.brand.name
    )
      .setBreak(true)
      .setClass('warning--text')
      .setCol('6'),
    PreviewComponent.labelValue<IProduct>(
      'productType',
      'productType',
      ContentFormatter.$customizeText(product.productType)
    )
      .setTitle('shipping')
      .setCol('6'),
    PreviewComponent.labelValue<IProduct>(
      'listingStatus',
      'status',
      ContentFormatter.$customizeText(product.status) as string
    )
      .setClass(`${productStatusColors[product.status]}--text`)
      .setTitle('status')
      .setCol('6'),
    PreviewComponent.labelValue<IProduct>('note', 'note', product.note).setCol(
      '12'
    ),
    PreviewComponent.labelValue<IProduct>(
      'productCategory',
      'category',
      product.category?.path
    )
      .setTitle('productCategory')
      .setCol('12'),
    //META DATA
    ...metadataLabelValues(product)
  ]
}
