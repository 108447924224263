import { Module, VuexModule } from 'vuex-module-decorators'
import { IProfileItem } from '@/modules/profile/types/profileTypes'

/**
 * Profile items vuex module.
 */
@Module({ namespaced: true, name: 'profile' })
export default class ProfileStore extends VuexModule {
  profileItem: IProfileItem[] = [
    {
      id: 1,
      title: 'updateProfile',
      component: 'TheUpdateProfileForm'
    },
    {
      id: 2,
      title: 'changePassword',
      component: 'TheChangePasswordForm'
    }
  ]

  /**
   * Get profile item list from vuex store.
   */
  get getProfileList(): IProfileItem[] {
    return this.profileItem
  }
}
