
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { ICountry } from '@/modules/seller-management/country/types/countryTypes'
import { countryEndpoints } from '@/modules/seller-management/country'

@Component({
  name: 'TheUpsertCountry',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertCountry extends Vue {
  loading = false
  data = {} as ICountry
  id = this.$route.params.id || ''
  originalData = {} as ICountry

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.country.edit')}`
      : `${this.$t('dialog.country.addNew')}`

  /**
   * function will be passed to child components
   */
  fields(): Field<ICountry>[] {
    return [
      Field.text<ICountry>('countryNameField', 'name')
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(
    values: ICountry,
    _p: Record<string, number>,
    q: Record<string, number>
  ) {
    if (q.id) {
      await countryEndpoints
        .patch(q.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await countryEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await countryEndpoints.get(id).then(res => res)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
