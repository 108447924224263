/**
 * transform queries into an object
 * @param query - string type
 * @returns an object with params and query seperated used for modal
 */
export function parsePathQueryString(query: string) {
  if (query) {
    return (
      query
        // omit the first question mark character
        .slice(1)
        // split all values
        .split('&')
        // make them all into one object
        .reduce((acc, currentValue) => {
          const [key, value] = currentValue.split('=')
          // eliminate spaces
          acc[key] = decodeURI(value)
          return acc
        }, {} as Record<string, string>)
    )
  }
  return {}
}
