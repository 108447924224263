import { Route, RouteConfig } from 'vue-router'
import { IBreadcrumb } from '@/shared/types/basePage'
import { AnyObject } from '@/shared/types/builtInTypes'
import { generateDynamicTitle } from '@/shared/helpers/generateDynamicTitle'

const pagesDirectory: Record<string, RouteConfig> = {}

/**
 * Get Registered page route from the base router config.
 * @param routes base app router.
 */
export function getBreadcrumbRoutes(routes: Array<RouteConfig>) {
  routes.forEach((curRoute: RouteConfig) => {
    if (curRoute.name) {
      // Create page directory that holds route name and route object.
      pagesDirectory[curRoute.name] = curRoute
    }
  })
}

/**
 * A breadcrumbs' factory that creates breadcrumbs base on provided page,
 * params, and remote data.
 * @param pageRouteConfig
 * @param params route params
 * @param remoteData remote data
 */
export function newBreadcrumbFactory(
  pageRouteConfig: RouteConfig,
  params: Route['params'],
  remoteData: AnyObject
): IBreadcrumb[] {
  if (pageRouteConfig.meta && pageRouteConfig.meta.parentRouteName === null) {
    return [
      {
        text: generateDynamicTitle(
          pageRouteConfig?.name || '',
          {},
          remoteData
        ) as string,
        exact: true,
        to: { name: pageRouteConfig.name, params }
      }
    ]
  }

  const parentPage =
    pageRouteConfig.meta && pagesDirectory[pageRouteConfig.meta.parentRouteName]

  // Check parent page if it is not found try to get parent page info from page
  // info if still not found throw an exception.
  if (!parentPage) {
    if (pageRouteConfig.meta && pageRouteConfig.meta.parentRouteName) {
      return [
        {
          text: pageRouteConfig.meta.parentRouteName as string,
          to: { name: pageRouteConfig.meta.parentRouteName },
          exact: true
        },
        {
          text: generateDynamicTitle(
            pageRouteConfig.name || '',
            params,
            remoteData
          ) as string,
          to: { name: pageRouteConfig.name, params },
          exact: true
        }
      ]
    }

    throw new Error(
      `You provided the path '${pageRouteConfig?.meta?.parentRouteName}' as a parent page path to
      the page with name '${pageRouteConfig?.name}'. However, we could not find a page for
      your provided parent path. In case your parent page is not included in
      CRS, provide 'parentPageTitle' in your page options.`
    )
  }

  // Get parent page breadcrumbs by calling the same function recursively.
  const parentBreadcrumb = newBreadcrumbFactory(parentPage, params, remoteData)

  return [
    ...parentBreadcrumb,
    {
      text: generateDynamicTitle(
        pageRouteConfig.name || '',
        params,
        remoteData
      ) as string,
      to: { name: pageRouteConfig?.name, params },
      exact: true,
      disabled: false
    }
  ]
}
