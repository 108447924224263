import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import TheAddingBatch from '@/modules/arbela-gift-card/batch/views/TheAddingBatch.vue'
import ExportBatch from '@/modules/arbela-gift-card/batch/components/ExportBatch.vue'
import ViewGiftCardNumber from '@/modules/arbela-gift-card/gift-card/components/ViewGiftCardNumber.vue'

/**
 * Batch module routes
 */
export const batchRoutes: RouteConfig[] = [
  {
    path: '/gift-card-batch',
    name: 'batch',
    component: () =>
      import(
        /* webpackChunkName: "TheBatchChunk" */ '@/modules/arbela-gift-card/batch/views/TheBatch.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/gift-card-batch/preview-details/:id',
    name: 'preview-batch',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewBatchChunk" */ '@/modules/arbela-gift-card/batch/views/ThePreviewBatch.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: 'batch'
    }
  },
  {
    path: '/gift-card-batch/set-batch-status/:id',
    name: 'set-batch-status',
    component: () =>
      import(
        /* webpackChunkName: "UpdateBatchStatusChunk" */ '@/modules/arbela-gift-card/batch/components/UpdateBatchStatus.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: 'batch'
    }
  },
  {
    path: '/gift-card-batch/:id/gift-card-list/:batchId/',
    name: 'gift-card-list',
    component: () =>
      import(
        /* webpackChunkName: "GiftCardListChunk" */ '@/modules/arbela-gift-card/batch/components/GiftCardList.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: 'batch'
    }
  },
  {
    path: '/gift-card-batch/:id/gift-card-tracking/:batchId/serial-number/:serialNumber',
    name: 'gift-card-batch-tracking',
    component: () =>
      import(
        /* webpackChunkName: "GiftCardTrackingChunk" */ '@/modules/arbela-gift-card/batch/components/GiftCardTracking.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: 'gift-card-list'
    }
  },
  {
    path: '/gift-card-batch/:id/set-gift-card-status/:batchId/serial-number/:serialNumber',
    name: 'set-gift-card-batch-status',
    component: () =>
      import(
        /* webpackChunkName: "UpdateGiftCardStatusChunk" */ '@/modules/arbela-gift-card/gift-card/components/UpdateGiftCardStatus.vue'
      ),
    meta: {
      title: 'navigations.batchMetaTitle',
      parentRouteName: 'gift-card-list'
    }
  }
]
export const batchModalRoute: PopUpRouteConfig[] = [
  {
    component: TheAddingBatch,
    persistent: true,
    name: 'add-batch',
    requiredParams: []
  },
  {
    component: ExportBatch,
    persistent: true,
    name: 'export-batch',
    requiredParams: ['id']
  },
  {
    component: ViewGiftCardNumber,
    persistent: false,
    name: 'view-gift-card-batch-number',
    requiredParams: ['serialNumber']
  }
]
