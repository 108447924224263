import { RouteConfig } from 'vue-router'

export const forceChangePasswordRoutes: RouteConfig[] = [
  {
    path: '/force-change-password',
    name: 'ForceChangePassword',
    component: () =>
      import(
        /*webpackChunkName: "ForceChangePassword"*/ '@/modules/force-change-password/views/ForceChangePassword.vue'
      ),
    meta: {
      title: 'navigations.forceChangePasswordMetaTitle'
    }
  }
]
