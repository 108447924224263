import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IEscalationProfile } from '@/modules/seller-management/escalation-profile/types/escalationProfileTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

/**
 * dynamic component generator method
 * @param escalationProfile is IEscalationProfile interface type which
 * @return An Array of IPreviewComponent escalation profile type
 */
export function escalationProfilePreviewComponents(
  escalationProfile: IEscalationProfile
): PreviewComponent<IEscalationProfile>[] {
  return [
    PreviewComponent.labelValue<IEscalationProfile>(
      'profileName',
      'profileName',
      escalationProfile.profileName
    ).setTitle('escalationProfileDetail'),
    PreviewComponent.labelValue<IEscalationProfile>(
      'orderProcessingDelayDay',
      'orderProcessingDelay',
      ContentFormatter.$formatToCurrency(
        escalationProfile?.orderProcessingDelay
      )
    ),
    PreviewComponent.labelValue<IEscalationProfile>(
      'orderPackagingDelayDay',
      'orderPackagingDelay',
      ContentFormatter.$formatToCurrency(escalationProfile?.orderPackagingDelay)
    ),
    PreviewComponent.labelValue<IEscalationProfile>(
      'description',
      'description',
      escalationProfile.description
    )
      .setCol('12')
      .setBreak(true),
    ...metadataLabelValues(escalationProfile)
  ]
}
