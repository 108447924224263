import { ICountry } from '@/modules/seller-management/country/types/countryTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param country is ICountry interface type which
 * @return An Array of ICountry type
 */
export function countryPreviewComponents(
  country: ICountry
): PreviewComponent<ICountry>[] {
  return [
    PreviewComponent.labelValue<ICountry>(
      'countryNameField',
      'name',
      country.name
    )
      .setTitle('countryDetail')
      .setBreak(true),
    ...metadataLabelValues(country)
  ]
}
