import { RouteConfig } from 'vue-router'
import { pageEndpoints } from '@/modules/page/page'
import { pagePreviewComponents } from '@/modules/page/page/helpers/previewComponents'

/**
 * Page module routes
 */
export const pageRoutes: RouteConfig[] = [
  {
    path: '/page',
    name: 'page',
    component: () =>
      import(
        /* webpackChunkName: "ThePageChunk" */ '@/modules/page/page/views/ThePage.vue'
      ),
    meta: {
      title: 'navigations.pageMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/page/preview-details/:id',
    name: 'preview-page',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewPageChunk" */ '@/modules/page/page/views/ThePreviewPage.vue'
      ),
    meta: {
      title: 'navigations.pageMetaTitle',
      parentRouteName: 'page',
      logPage: {
        endpoint: pageEndpoints,
        previewDynamicComponents: pagePreviewComponents
      }
    }
  },
  {
    path: '/page/update-page/:id?',
    name: 'update-page',
    component: () =>
      import(
        /* webpackChunkName: "TheUpdatePageChunk" */ '@/modules/page/page/views/TheUpdatePage.vue'
      ),
    meta: {
      title: 'navigations.pageMetaTitle',
      parentRouteName: 'page'
    }
  }
]
