import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import {
  CollectionStrategy,
  ICollection,
  StyleType
} from '@/modules/page/collection/types/collectionTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import i18n from '@/plugins/i18n'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'

/**
 * dynamic component generator method
 * @param collection is ICollection interface type which
 * @return An Array of IPreviewComponent collection type
 */
export function collectionPreviewComponents(
  collection: ICollection
): PreviewComponent<ICollection>[] {
  const isManual = collection.collectionStrategy === CollectionStrategy.manual

  const isAutomatic =
    collection.collectionStrategy === CollectionStrategy.automatic

  return [
    PreviewComponent.photo<ICollection>('image', collection.image).setHidden(
      collection.styleType === StyleType.product ||
        collection.styleType === StyleType.category ||
        collection.styleType === StyleType.slider ||
        collection.styleType === StyleType.brand
    ),
    PreviewComponent.multiPhoto<ICollection>(
      'images',
      collection.images && collection.images.map((img: any) => img.image)
    ).setHidden(collection.styleType !== StyleType.slider),
    PreviewComponent.labelValue<ICollection>(
      'title',
      'title',
      collection.title
    ).setCol('12'),
    PreviewComponent.labelValue<ICollection>(
      'description',
      'description',
      collection.description
    ).setCol('12'),
    PreviewComponent.labelValue<ICollection>(
      'styleType',
      'styleType',
      ContentFormatter.$customizeText(collection.styleType)
    ),
    PreviewComponent.labelValue<ICollection>(
      'collectionStrategy',
      'collectionStrategy',
      ContentFormatter.$customizeText(collection.collectionStrategy)
    ).setHidden(
      collection.styleType === StyleType.navigation ||
        collection.styleType === StyleType.slider ||
        collection.styleType === StyleType.brand
    ),
    PreviewComponent.labelValue<ICollection>(
      'buttonText',
      'buttonText',
      collection.buttonText
    ).setHidden(
      !(
        collection.styleType === StyleType.banner ||
        collection.styleType === StyleType.halfBanner
      )
    ),
    PreviewComponent.labelValue<ICollection>(
      'destination',
      'destination',
      ContentFormatter.$customizeText(collection.destination)
    ).setHidden(!(collection.styleType === StyleType.navigation)),
    PreviewComponent.labelValue<ICollection>(
      'imagePosition',
      'imagePosition',
      ContentFormatter.$customizeText(collection.imagePosition)
    ).setHidden(!(collection.styleType === StyleType.halfBanner)),
    PreviewComponent.table<ICollection>(
      'filterKwargs',
      [
        {
          text: i18n.t('header.fieldName'),
          value: 'fieldName',
          key: 'fieldName',
          formatValue: 'customizeText'
        },
        {
          text: i18n.t('header.lookupExpressions'),
          value: 'lookupExpression',
          key: 'lookupExpression',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.value'),
          value: 'value',
          key: 'value',
          formatValue: 'text'
        }
      ],
      collection.filterKwargs
    )
      .setProp({ class: 'col-12' })
      .setTitle('filteredKwargs')
      .setHidden(!isAutomatic),
    PreviewComponent.table<ICollection>(
      'categories',
      [
        {
          text: 'image',
          value: 'image',
          key: 'image',
          formatValue: 'file',
          io: 'input',
          formatType: 'photo',
          cellProps: item => {
            return {
              src: item.image,
              height: 40,
              width: 40,
              contain: true
            }
          },
          width: '80px'
        },
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.categoryType'),
          value: 'categoryType',
          key: 'categoryType',
          formatValue: 'text'
        }
      ],
      collection.categories
    )
      .setProp({ class: 'col-12' })
      .setTitle('categories')
      .setHidden(!(collection.styleType === StyleType.category && isManual)),
    PreviewComponent.table<ICollection>(
      'products',
      [
        {
          text: 'images',
          value: 'images',
          key: 'images',
          formatValue: 'file',
          io: 'input',
          formatType: 'photo',
          cellProps: item => {
            return {
              src: item.images[0],
              height: 40,
              width: 40,
              contain: true
            }
          },
          width: '80px'
        },
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text'
        },
        {
          text: `${i18n.t('header.salePrice')} (${getCurrencySymbol()})`,
          value: 'salePrice',
          key: 'salePrice',
          formatValue: 'currency'
        }
      ],
      collection.products
    )
      .setProp({ class: 'col-12' })
      .setTitle('products')
      .setHidden(
        !(
          (collection.styleType === StyleType.product ||
            collection.styleType === StyleType.banner ||
            collection.styleType === StyleType.halfBanner) &&
          isManual
        )
      ),
    PreviewComponent.table<ICollection>(
      'brands',
      [
        {
          text: 'image',
          value: 'image',
          key: 'images',
          formatValue: 'file',
          io: 'input',
          formatType: 'photo',
          cellProps: item => {
            return {
              src: item.image,
              height: 40,
              width: 40,
              contain: true
            }
          },
          width: '80px'
        },
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text'
        }
      ],
      collection.brands
    )
      .setProp({ class: 'col-12' })
      .setTitle('brands')
      .setHidden(collection.styleType !== StyleType.brand),
    ...metadataLabelValues(collection)
  ]
}
