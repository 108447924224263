import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IPageModule } from '@/modules/page/page/types/pageTypes'

/**
 * dynamic component generator method
 * @param page is IPageModule interface type which
 * @return An Array of IPreviewComponent page type
 */
export function pagePreviewComponents(
  page: IPageModule
): PreviewComponent<IPageModule>[] {
  return [
    PreviewComponent.labelValue<IPageModule>('pageTitle', 'name', page.name),
    ...metadataLabelValues(page)
  ]
}
