import { ModelApi } from '@/services/api/modelApi'
import {
  ISellerRequest,
  requestStatusPayload
} from '@/modules/request/seller-request/types/sellerRequestTypes'
import { Id } from '@/shared/types/builtInTypes'
import axios from 'axios'

/**
 * Seller Request endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class SellerRequestEndpoints extends ModelApi<ISellerRequest> {
  constructor() {
    super('maker_checker/request')
  }

  updateStatus(id: Id, payload: requestStatusPayload, elementId: Id) {
    return new Promise((resolve, reject) => {
      //For now for seller request, we only have `approve` endpoint
      const statusType = 'approve'

      axios
        .post(`/maker_checker/request/${id}/${statusType}_request/`, payload, {
          params: {
            elementId
          }
        })
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  getRequestTracking(trackingId: Id): Promise<ISellerRequest> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/maker_checker/request/${trackingId}/get_request_tracking/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
