
import { Component, Vue } from 'vue-property-decorator'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { giftCardEndpoints } from '@/modules/arbela-gift-card/gift-card'
import { IGiftCardNumber } from '@/modules/arbela-gift-card/gift-card/types/giftCardTypes'
import CustomButtons from '@/stories/custom-buttons/CustomButtons.vue'
import { IButtons } from '@/shared/types/baseButton'
import PreviewLabelValue from '@/stories/preview-label-value/PreviewLabelValue.vue'

@Component({
  name: 'ViewGiftCardNumber',
  components: {
    PreviewLabelValue,
    CustomButtons,
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ViewGiftCardNumber extends Vue {
  serialNumber = this.$route.params.serialNumber || ''
  data = {} as IGiftCardNumber
  loading = false

  get buttons(): IButtons[] {
    return [
      {
        text: 'cancel',
        icon: '',
        props: { color: 'primary', text: true },
        onClick: () => this.closePopUp(),
        permit: true
      }
    ]
  }

  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['serialNumber'])
  }

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const serialNumber = parsePathQueryString(location.search).serialNumber
    this.loading = true
    try {
      if (serialNumber) {
        this.serialNumber = serialNumber
        this.data = await giftCardEndpoints.giftCardNumber(serialNumber)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
