import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import {
  apiConfigEndpoint,
  apiRequestEndpoint,
  logApiConfigEndpoint,
  logApiRequestEndpoint
} from '@/modules/preference/api-integration'
import { apiConfigPreviewComponents } from '@/modules/preference/api-integration/helpers/apiConfigPreviewComponents'
import { apiRequestPreviewComponents } from '@/modules/preference/api-integration/helpers/apiRequestPreviewComponents'
import ImportApiRequest from '@/modules/preference/api-integration/components/ImportApiRequest.vue'

/** Api Configuration and Api Request module routes */
export const apiIntegrationRoutes: RouteConfig[] = [
  {
    path: '/api-config',
    name: 'api-config',
    component: () =>
      import(
        /* webpackChunkName: "TheApiConfigChunk" */ '@/modules/preference/api-integration/views/TheApiConfig.vue'
      ),
    meta: {
      title: 'navigations.apiConfigMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/api-config/preview-api-config/:id',
    name: 'preview-apiConfig',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewApiConfigChunk" */ '@/modules/preference/api-integration/views/ThePreviewApiConfig.vue'
      ),
    meta: {
      title: 'navigations.apiConfigMetaTitle',
      parentRouteName: 'api-config',
      logPage: {
        endpoint: logApiConfigEndpoint,
        previewDynamicComponents: apiConfigPreviewComponents
      }
    }
  },
  {
    path: '/api-config/upsert-api-config/:id?',
    name: 'upsert-api-config',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertApiConfigChunk" */ '@/modules/preference/api-integration/views/TheUpsertApiConfig.vue'
      ),
    meta: {
      title: 'navigations.apiConfigMetaTitle',
      parentRouteName: 'api-config'
    }
  },
  {
    path: '/api-config/celery-api-config/:celeryApiType',
    name: 'celery-api-config',
    component: () =>
      import(
        /*TheCeleryTaskChunk*/ '@/modules/celery-task/views/TheCeleryTask.vue'
      ),
    meta: {
      parentRouteName: 'api-config',
      title: 'navigations.apiConfigMetaTitle'
    }
  },
  /*Routes for API Request*/
  {
    path: '/api-config/:configId/api-request',
    name: 'api-request',
    component: () =>
      import(
        /* webpackChunkName: "TheApiRequestChunk" */ '@/modules/preference/api-integration/views/TheApiRequest.vue'
      ),
    meta: {
      title: 'navigations.apiRequestMetaTitle',
      parentRouteName: 'api-config'
    }
  },
  {
    path: '/api-config/:configId/preview-api-request/:id',
    name: 'preview-apiRequest',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewApiRequestChunk" */ '@/modules/preference/api-integration/views/ThePreviewApiRequest.vue'
      ),
    meta: {
      title: 'navigations.apiRequestMetaTitle',
      parentRouteName: 'api-request',
      logPage: {
        endpoint: logApiRequestEndpoint,
        previewDynamicComponents: apiRequestPreviewComponents
      }
    }
  },
  {
    path: '/api-config/:configId/upsert-api-request/:id?',
    name: 'upsert-api-request',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertApiRequestChunk" */ '@/modules/preference/api-integration/views/TheUpsertApiRequest.vue'
      ),
    meta: {
      title: 'navigations.apiRequestMetaTitle',
      parentRouteName: 'api-request'
    }
  }
]

export const apiIntegrationModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-api-config',
    requiredParams: ['id'],
    onDelete: q => apiConfigEndpoint.delete(q.id.toString()),
    dialogTitle: 'apiConfig'
  },
  /*API Request Module Route*/
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-api-request',
    requiredParams: ['id'],
    onDelete: q => apiRequestEndpoint.delete(q.id.toString()),
    dialogTitle: 'apiRequest'
  },
  {
    component: ImportApiRequest,
    persistent: true,
    name: 'import-rest_api',
    size: 600,
    requiredParams: []
  }
]
