
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import { orderItemEndpoints } from '@/modules/order-management/order-items'
import {
  IOrderItems,
  ReturnReasonTypeChoices
} from '@/modules/order-management/order-items/types/orderTypes'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { AnyObject } from '@/shared/types/builtInTypes'

@Component({
  name: 'SetReturnItem',
  components: {
    PreviewAvatar,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class SetReturnItem extends Vue {
  loading = false
  data = {}
  originalData = {}

  ReturnReasonTypeList: AnyObject[] = [
    {
      id: 1,
      name: ReturnReasonTypeChoices.iDoNotLikeTheModel
    },
    {
      id: 2,
      name: ReturnReasonTypeChoices.missingPiecesAccessoriesInTheProduct
    },
    {
      id: 3,
      name: ReturnReasonTypeChoices.cargoDeliveryDelay
    },
    {
      id: 4,
      name: ReturnReasonTypeChoices.defectiveProduct
    },
    {
      id: 5,
      name: ReturnReasonTypeChoices.wrongProduct
    },
    {
      id: 6,
      name: ReturnReasonTypeChoices.productHasWrongCharacteristics
    },
    {
      id: 6,
      name: ReturnReasonTypeChoices.iDoNotLikeTheQuality
    }
  ]

  async created() {
    await this.fetchRemoteData()
  }
  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    if (id)
      try {
        this.data = await orderItemEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      } catch (error) {
        throw new Error(`Failed to fetch form data. Due to ${error}`)
      } finally {
        this.loading = false
      }
    else throw new Error('Please, Provide User ID ')
  }

  fields(order: IOrderItems): Field<IOrderItems>[] {
    const numberOfQuantity = order.quantity
    return [
      Field.dropdown<IOrderItems>(
        'returnReason',
        'returnReason',
        () => Promise.resolve(this.ReturnReasonTypeList),
        (item: AnyObject) => `${this.$t('label.' + item.name)}`,
        'name'
      )
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
        .setCol('col-12'),
      Field.text<IOrderItems>('quantity', 'quantity')
        .setProps({
          type: 'number'
        })
        .setRules(`between:${0},${numberOfQuantity}`)
        .required()
        .setCol('col-12')
    ]
  }
  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(values: Pick<IOrderItems, 'quantity' | 'returnReason'>) {
    const { id } = parsePathQueryString(location.search)
    const data = {
      quantity: values.quantity,
      returnReason: values.returnReason
    }
    if (id)
      await orderItemEndpoints
        .setReturnedOrder(id.toString(), data, this.originalData)
        .then(() => this.closePopUp())
        .finally(() => eventBus.refreshData())
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
