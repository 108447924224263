
import { Component, Vue } from 'vue-property-decorator'
import { popUpRoute } from '@/services/utils/popupRoute'
import { currencyRateEndpoints } from '@/modules/accounting/currency-rate'

import BaseForm from '@/stories/base-form/BaseForm.vue'
import eventBus from '@/eventBus'
import { changeObjectsValToArray } from '@/shared/helpers/changeObjectValToArray'
import {
  CurrencyNameEnum,
  ILastCurrencyRate
} from '@/modules/accounting/currency-rate/types/currencyRateTypes'
import Buttons from '@/stories/custom-buttons/CustomButtons.vue'
import { IButtons } from '@/shared/types/baseButton'

@Component({
  name: 'TheAddingCurrencyRate',
  components: {
    Buttons,
    BaseForm,
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class TheAddingCurrencyRate extends Vue {
  loading = false
  lastCurrencyRate: ILastCurrencyRate[] = []
  submitBtnLoading = false

  get cancelButtons(): IButtons {
    return {
      text: 'cancel',
      icon: '',
      props: {
        disabled: this.submitBtnLoading,
        class: 'mx-3',
        text: true,
        tile: true
      },
      onClick: () => this.closePopUp(),
      permit: true
    }
  }

  /**
   * return the whole object of CurrencyNameEnum
   */
  get currencyName(): Record<string, CurrencyNameEnum> {
    return CurrencyNameEnum
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath()
  }

  mounted() {
    this.fetchRemoteData()
  }

  /**
   * submit the data to the desired endpoint.
   */
  controlSubmit() {
    this.submitBtnLoading = true
    let payload = {
      currencyIds: changeObjectsValToArray<ILastCurrencyRate>(
        this.lastCurrencyRate,
        'currencyId'
      ),
      rates: changeObjectsValToArray<ILastCurrencyRate>(
        this.lastCurrencyRate,
        'rate'
      )
    }

    currencyRateEndpoints
      .post(payload)
      .then(() => eventBus.refreshData())
      .finally(() => {
        this.submitBtnLoading = false
        this.closePopUp()
      })
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    try {
      this.loading = true
      this.lastCurrencyRate = await currencyRateEndpoints.getLastCurrencyRate()
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
