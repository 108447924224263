import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IWeightEstimation } from '@/modules/inventory/weight-estimation/types/weightEstimationTypes'

/**
 * dynamic component generator method
 * @param weightEstimation is IWeightEstimation interface type which
 * @return An Array of IPreviewComponent Weight Estimation type
 */
export function weightEstimationPreviewComponents(
  weightEstimation: IWeightEstimation
): PreviewComponent<IWeightEstimation>[] {
  return [
    PreviewComponent.labelValue<IWeightEstimation>(
      'title',
      'title',
      weightEstimation.title
    ).setTitle('weightEstimationDetails'),
    PreviewComponent.labelValue<IWeightEstimation>(
      'category',
      'categoryId',
      weightEstimation.category?.name
    ),
    PreviewComponent.labelValue<IWeightEstimation>(
      'minWeightKg',
      'minWeight',
      weightEstimation.minWeight
    ),
    PreviewComponent.labelValue<IWeightEstimation>(
      'maxWeightKg',
      'maxWeight',
      weightEstimation.maxWeight
    ),
    ...metadataLabelValues(weightEstimation)
  ]
}
