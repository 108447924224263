import { RouteConfig } from 'vue-router'

/**
 * Profile module routes
 */
export const profileRoutes: RouteConfig[] = [
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () =>
      import(
        /*webpackChunkName: "TheProfilePageComponent"*/ '@/modules/profile/views/TheProfilePage.vue'
      ),
    meta: {
      title: 'navigations.profileMetaTitle'
    }
  }
]
