import { TransactionFeeEndpoints } from '@/modules/accounting/transaction-fee/api/transactionFeeEndpoints'
import {
  ICommissionFeeList,
  ITransactionFee,
  ITransferFeeList
} from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'

/**
 * Transaction Fee endpoint instance
 * instantiate Transaction Fee api class with a different api path
 */
export const transactionFeeEndpoints =
  new TransactionFeeEndpoints<ITransactionFee>('transaction_fee')

export const transactionFeeListEndpoints =
  new TransactionFeeEndpoints<ITransferFeeList>('fee_list', 'transaction_fee')

export const transactionCommissionListEndpoints =
  new TransactionFeeEndpoints<ICommissionFeeList>(
    'commission_list',
    'transaction_fee'
  )
