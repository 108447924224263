
import { Component, Vue } from 'vue-property-decorator'
import { popUpRoute } from '@/services/utils/popupRoute'
import { batchEndpoints } from '@/modules/arbela-gift-card/batch'
import CustomButtons from '@/stories/custom-buttons/CustomButtons.vue'
import { IButtons } from '@/shared/types/baseButton'
import { hasPermission } from '@/shared/helpers/hasPermission'
import batchPermissions from '@/modules/arbela-gift-card/batch/helpers/batchPermissions.json'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'

@Component({
  name: 'ExportBatch',
  components: {
    CustomButtons,
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ExportBatch extends Vue {
  isExportBtnLoading = false
  get buttons(): IButtons[] {
    return [
      {
        text: 'cancel',
        icon: '',
        props: { color: 'primary', text: true },
        onClick: () => this.closePopUp(),
        permit: true
      },
      {
        text: 'export',
        icon: '',
        props: {
          color: 'primary',
          loading: this.isExportBtnLoading,
          disabled: this.isExportBtnLoading
        },
        onClick: () => this.handleSubmit(),
        permit: hasPermission([batchPermissions.EXPORT_BATCH])
      }
    ]
  }

  /** submit the data to the desired endpoint.*/
  async handleSubmit() {
    const id = parsePathQueryString(location.search).id
    this.isExportBtnLoading = true
    try {
      if (id) await batchEndpoints.exportBatch(id).then(() => this.closePopUp())
    } finally {
      this.isExportBtnLoading = false
    }
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
