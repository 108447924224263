import { ModelApi } from '@/services/api/modelApi'
import axios from 'axios'
import {
  CurrencyNameEnum,
  ICurrencyRate,
  ILastCurrencyRate
} from '@/modules/accounting/currency-rate/types/currencyRateTypes'

/**
 * Currency rate endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class CurrencyRateEndpoints extends ModelApi<ICurrencyRate> {
  constructor() {
    super('currency_rate')
  }

  //Get Last currency rate
  getLastCurrencyRate(): Promise<ILastCurrencyRate[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`currency_rate/last_rate/`)
        .then(response => {
          // filter the response from IQD object, cuz backend don't accept any update on it
          resolve(
            response?.data?.currencyRateDetails.filter(
              (currency: ILastCurrencyRate) =>
                currency.currencyName !== CurrencyNameEnum.IQD
            )
          )
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
