
import { Component, Vue } from 'vue-property-decorator'
import DialogBaseSkeleton from '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
import { authEndpoints } from '@/modules/login'
import appStore from '@/AppStore'
import Buttons from '@/stories/custom-buttons/CustomButtons.vue'
import { IButtons } from '@/shared/types/baseButton'

@Component({
  name: 'TheLogoutConfirm',
  components: { Buttons, DialogBaseSkeleton }
})
export default class TheLogoutConfirm extends Vue {
  loading = false

  get logoutButtons(): IButtons[] {
    return [
      {
        text: 'cancel',
        props: {
          text: true,
          class: 'mx-3'
        },
        permit: true,
        onClick: () => this.handleCloseDialog()
      },
      {
        text: 'logout',
        props: {
          tile: true,
          class: 'white--text me-3',
          color: 'error',
          loading: this.loading
        },
        icon: 'fal fa-right-from-bracket',
        permit: true,
        onClick: () => this.logout()
      }
    ]
  }

  logout() {
    this.loading = true
    authEndpoints
      .logout()
      .then(() => {
        this.handleCloseDialog()
        this.$router.push('/login')
      })
      .catch(() => {
        this.handleCloseDialog()
        appStore.dispatch('notification/pushNotification', {
          type: 'error',
          messages: 'Logout Failed'
        })
      })
      .finally(() => (this.loading = false))
  }

  handleCloseDialog() {
    this.$emit('close')
  }
}
