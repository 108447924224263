
import { Component, Vue } from 'vue-property-decorator'
import { ILocale } from '@/core/types/coreTypes'
import { namespace } from 'vuex-class'

import ForceChangePassword from '@/modules/force-change-password/views/ForceChangePassword.vue'
import TheLogin from '@/modules/login/views/TheLogin.vue'
import TheLayout from '@/core/components/TheLayout.vue'

const localeStore = namespace('locale')
const authStore = namespace('auth')
@Component({
  components: {
    TheLogin,
    ForceChangePassword,
    TheLayout,
    Notification: () =>
      import(
        /* webpackChunkName: "TheNotification" */ '@/core/components/TheNotification.vue'
      )
  }
})
export default class App extends Vue {
  @localeStore.Getter('getLocaleList')
  readonly languageOptions!: ILocale[]

  @authStore.Getter('getIsAuthenticated')
  readonly isAuth!: boolean

  created() {
    // handle rtl in both cases, authenticated user and unauthenticated user
    this.$vuetify.rtl = this.$store.getters['auth/isRTL']
  }
}
