import { RouteConfig } from 'vue-router'

export const homeRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "HomeChunk" */ '@/modules/home/views/Home.vue'
      )
  }
]
