import { PreviewComponent } from '@/services/utils/basePreview'
import i18n from '@/plugins/i18n'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import {
  discountStatusColors,
  IDiscount
} from '@/modules/inventory/discount/types/discountTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { mergeAmountWithType } from '@/shared/helpers/amountWithAmountType'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'

/**
 * dynamic component generator method
 * @param discount is IDiscount interface type which
 * @return An Array of IPreviewComponent discount type
 */
export function discountPreviewComponents(
  discount: IDiscount
): PreviewComponent<IDiscount>[] {
  return [
    PreviewComponent.labelValue<IDiscount>(
      'discountName',
      'discountName',
      discount.discountName
    ).setTitle('discountDetails'),
    PreviewComponent.labelValue<IDiscount>(
      'appliesTo',
      'appliesTo',
      ContentFormatter.$customizeText(discount.appliesTo)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'amountType',
      'amountType',
      ContentFormatter.$customizeText(discount.amountType)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'amount',
      'amount',
      mergeAmountWithType(discount)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'seller',
      'merchant',
      discount.merchant?.fullName
    ),
    PreviewComponent.labelValue<IDiscount>(
      'startDate',
      'startDate',
      ContentFormatter.$formatToDate(discount.startDate)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'endDate',
      'endDate',
      ContentFormatter.$formatToDate(discount.endDate)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'target',
      'target',
      ContentFormatter.$customizeText(discount.target)
    ),
    PreviewComponent.labelValue<IDiscount>(
      'status',
      'status',
      discount.status
    ).setClass(
      `${discountStatusColors[discount.status]}--text text-capitalize`
    ),
    PreviewComponent.labelValue<IDiscount>(
      'activityStatus',
      'isActive',
      discount.isActive ? i18n.t('label.active') : i18n.t('label.inActive')
    ).setClass(discount.isActive ? 'success--text' : 'error--text'),
    PreviewComponent.labelValue<IDiscount>('notes', 'notes', discount.notes)
      .setCol('pa-3 col-12')
      .setBreak(),
    PreviewComponent.table<IDiscount>(
      'productVariants',
      [
        {
          text: '',
          value: 'variantImage',
          key: 'variantImages',
          formatValue: 'file',
          io: 'input',
          formatType: 'photo',
          cellProps: item => {
            return {
              src: item?.variantImages[0]?.image,
              height: 40,
              width: 40,
              contain: true
            }
          },
          width: '80px'
        },
        {
          text: i18n.t('header.product'),
          value: 'productName',
          key: 'productName',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.sellerSku'),
          value: 'sellerSku',
          key: 'sellerSku',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.systemSku'),
          value: 'id',
          key: 'id',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.quantity'),
          value: 'quantity',
          key: 'quantity',
          formatValue: 'text'
        },
        {
          text: ` ${i18n.t('header.price')} (${getCurrencySymbol()})`,
          value: 'price',
          key: 'price',
          formatValue: 'currency'
        },
        {
          text: ` ${i18n.t('header.discountedPrice')} (${getCurrencySymbol()})`,
          value: 'priceAfterDiscount',
          key: 'priceAfterDiscount',
          formatValue: 'text'
        }
      ],
      discount?.productVariants ?? []
    )
      .setProp({ class: 'col-12' })
      .setTitle('products')
      .setBreak(),
    ...metadataLabelValues(discount)
  ]
}
