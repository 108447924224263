import Vue from 'vue'
import Vuetify, {
  VDatePicker,
  VRow,
  VTextField,
  VTimePicker
} from 'vuetify/lib'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import only light icons */
import { fal } from '@fortawesome/pro-light-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import font awesome icon brands */
import {
  faAmazon,
  faFacebook,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import i18n from '@/plugins/i18n'

/* add light icons to the library */
library.add(fal, faLinkedin, faFacebook, faInstagram, faAmazon)

Vue.use(Vuetify, {
  components: {
    VTextField,
    VRow,
    VDatePicker,
    VTimePicker
  }
})
/**
 * configure vuetify themes, configs
 */
const vuetify = new Vuetify({
  // config font awesome pro icon
  icons: {
    component: FontAwesomeIcon,
    values: {
      dropdown: 'fal fa-chevron-down',
      prev: 'fal fa-chevron-left',
      next: 'fal fa-chevron-right',
      expand: 'fal fa-chevron-down',
      sort: 'fal fa-arrow-down-long',
      clear: 'fal fa-xmark',
      delete: 'fal fa-circle-xmark',
      checkboxOn: 'fal fa-square-check',
      checkboxOff: 'fal fa-square',
      checkboxIndeterminate: 'fal fa-square-minus',
      subgroup: 'fal fa-caret-down',
      radioOn: 'fal fa-circle-dot',
      radioOff: 'fal fa-circle'
    }
  },
  theme: {
    dark: localStorage.getItem('dark') === 'true',
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        //primary
        primary: '#F5AD1B',
        onPrimary: '#333333',
        primaryContainer: '#51504C',
        onPrimaryContainer: '#E39600',
        //surface
        surface: '#1E1E1E',
        onSurface: '#FAFAFA',
        onSurfaceHigh: '#FAFAFA',
        onSurfaceMedium: '#DADADA',
        onSurfaceDisabled: '#8B8B8B',
        onSurfaceOutline: '#5C5C5C',
        onSurfaceVariant: '#2A2A2A',
        //background
        background: '#222222',
        onBackground: '#FFFFFF',
        //Semantic Color
        //error
        error: '#C33028',
        onError: '#FFFFFF',
        errorContainer: '#FFC5B0',
        onErrorContainer: '#B4261B',
        //Info
        info: '#2094F3',
        onInfo: '#FFFFFF',
        infoContainer: '#BDDFFE',
        onInfoContainer: '#1975D2',
        //success
        success: '#4CAF50',
        onSuccess: '#FFFFFF',
        successContainer: '#E2FBBD',
        onSuccessContainer: '#2F7D31',
        //warning
        warning: '#E8BA04',
        onWarning: '#222222',
        warningContainer: '#FFF9CC',
        onWarningContainer: '#DBAA00',

        //old - Note: will be removed once they don't have usage
        secondary: '#0D99DA',
        service: '#121212',
        link: '#457b9d',
        section: '#f5f5f5'
      },
      light: {
        //primary
        primary: '#E5940D',
        onPrimary: '#333333',
        primaryContainer: '#FFFBEB',
        onPrimaryContainer: '#D67609',
        //surface
        surface: '#FFFFFF',
        onSurface: '#121212',
        onSurfaceHigh: '#222222',
        onSurfaceMedium: '#666666',
        onSurfaceDisabled: '#9E9E9E',
        onSurfaceOutline: '#CACACA',
        onSurfaceVariant: '#F7FAFC',
        //background
        background: '#F6F6F6',
        onBackground: '#121212',
        //Semantic Color
        //error
        error: '#C33028',
        onError: '#FFFFFF',
        errorContainer: '#FFEBEE',
        onErrorContainer: '#B4261B',
        //info
        info: '#2094F3',
        onInfo: '#FFFFFF',
        infoContainer: '#EAF4FC',
        onInfoContainer: '#1975D2',
        //success
        success: '#4CAF50',
        onSuccess: '#FFFFFF',
        successContainer: '#E7F5E9',
        onSuccessContainer: '#2F7D31',
        //warning
        warning: '#DBAA00',
        onWarning: '#222222',
        warningContainer: '#FFF9CC',
        onWarningContainer: '#DBAA00',
        //old - Note: will be removed once they don't have usage
        secondary: '#0D99DA',
        service: '#F3F3F3',
        section: '#202223'
      }
    }
  },
  lang: {
    t: (key, ...params) =>
      (i18n.t(key, params) ? i18n.t(key, params) : key).toString()
  }
})

/* in production mode, dismiss vuetify warns and logs */
Vuetify.config.silent = process.env.NODE_ENV === 'production'
export default vuetify
