
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'

import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'

import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { localizationEndpoints } from '@/modules/preference/localization'
import { ILocalization } from '@/modules/preference/localization/types/localizationTypes'
import { keyTranslationsTransformer } from '@/modules/preference/localization/helpers/keyTranslationsTransformer'
import { AnyObject } from '@/shared/types/builtInTypes'
import appStore from '@/AppStore'

@Component({
  name: 'TheUpsertLocalization',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertLocalization extends Vue {
  loading = false
  data = {} as ILocalization
  id = this.$route.params.id || ''
  originalData = {}

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.localization.edit')}`
      : `${this.$t('dialog.localization.addNew')}`

  /**
   * function will be passed to child components
   * @params Localization which modules type the fetched Localization will be passed to it in the child component. (base form)
   */
  fields(): Field<ILocalization>[] {
    return [
      Field.dropdown<ILocalization>(
        'platform',
        'platformId',
        () => Promise.resolve(appStore.getters['platform/getPlatformList']),
        (item: AnyObject) => item.name,
        'id'
      )
        .required()
        .setCol('col-12'),
      Field.text<ILocalization>('key', 'key').setCol('col-12').required(),
      Field.text<ILocalization>('english', 'en').setCol('col-12').required(),
      Field.text<ILocalization>('kurdish', 'ckb')
        .setProps({ dir: 'rtl', reverse: true })
        .setCol('col-12')
        .required(),
      Field.text<ILocalization>('arabic', 'ar')
        .setProps({ dir: 'rtl', reverse: true })
        .setCol('col-12')
        .required()
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(
    values: ILocalization,
    p: Record<string, number>,
    q: Record<string, number>
  ) {
    const languageIds = [1, 2, 3]
    const translations = [this.data.en, this.data.ckb, this.data.ar]

    const newData = {
      ...this.data,
      languageIds,
      translations
    }

    if (q.id) {
      await localizationEndpoints.patch(q.id, this.originalData, newData)
    } else await localizationEndpoints.post(newData)

    popUpRoute.removeModalPath(['id'])
    eventBus.refreshData()
  }

  bindSubmitTitle(params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await localizationEndpoints.get(id).then(res => {
          const translations = keyTranslationsTransformer(res.keyTranslations)
          return { ...res, ...translations }
        })
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
