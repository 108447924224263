import { Module, VuexModule } from 'vuex-module-decorators'
import { Id } from '@/shared/types/builtInTypes'

/**
 * Store platform vuex store module.
 */
@Module({ namespaced: true, name: 'storeType' })
export default class platform extends VuexModule {
  platformStore: {
    id: Id
    name: string
  }[] = [
    { id: 1, name: 'Customer' },
    { id: 2, name: 'Seller' },
    { id: 3, name: 'Admin' },
    { id: 4, name: 'Agent' },
    { id: 5, name: 'Delivery Man' },
    { id: 6, name: 'Influencer' }
  ]

  /**
   * Get  storeType list from vuex store.
   */
  get getPlatformList() {
    return this.platformStore
  }
}
