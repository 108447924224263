
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { IDiscount } from '@/modules/inventory/discount/types/discountTypes'
import { popUpRoute } from '@/services/utils/popupRoute'
import { discountEndpoints } from '@/modules/inventory/discount'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import { freezeObject } from '@/shared/helpers/freezeObject'

@Component({
  name: 'Activation',
  components: {
    PreviewAvatar,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class Activation extends Vue {
  loading = false
  data = { isActive: true } as IDiscount
  originalData = {} as IDiscount

  async created() {
    await this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    try {
      if (id) this.data = await discountEndpoints.get(id)
      this.originalData = freezeObject(this.data)
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  fields(): Field<IDiscount>[] {
    return [
      Field.switch<IDiscount>(
        this.data?.isActive ? 'active' : 'inActive',
        'isActive'
      )
        .required()
        .setCol('col-12 d-flex justify-end ')
        .setProps({ color: this.data?.isActive ? 'success' : '' }),
      Field.textarea<IDiscount>('notes', 'notes').setProps({
        autoGrow: true,
        rows: 3
      })
    ]
  }

  /** submit the data to the desired endpoint.*/
  async handleSubmit(values: Pick<IDiscount, 'isActive' | 'notes'>) {
    const { id } = parsePathQueryString(location.search)

    if (id)
      await discountEndpoints
        .setStatus(id, values, this.originalData)
        .then(() => this.closePopUp())
    eventBus.refreshData()
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
