import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import appRouter from '@/AppRouter'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import { stringifyQueryObject } from '@/shared/helpers/stringifyQueryObject'
import { Dictionary } from 'vue-router/types/router'

class PopUpRoute {
  private modals: Dictionary<PopUpRouteConfig> = {}

  /**
   * register route in object also checks if the route does exist or nah.
   * @param modal - is an array of PopUpRouteConfig
   */
  register(...modal: PopUpRouteConfig[]): void {
    modal.forEach((val: PopUpRouteConfig) => {
      if (this.modals[val.name])
        throw new Error(
          `modal with this name ('${val.name}') is already registered!`
        )
      this.modals[val.name] = val
    })
  }

  getPopup(name: string) {
    const popup = this.modals[name]
    if (!popup) {
      throw new Error(`could not find popup for provided name '${name}'`)
    }
    return popup
  }

  /**
   * remove route params
   * please, delete the params after providing them!
   * @params params to delete
   */
  removeModalPath(params?: string[]): void {
    const currentQueries = parsePathQueryString(location.search)
    const nextQueries = { ...currentQueries }
    params && params.forEach(key => delete nextQueries[key])
    nextQueries.dialog && delete nextQueries.dialog
    history.replaceState(
      {},
      '',
      appRouter.currentRoute.path + stringifyQueryObject(nextQueries)
    )
    const event = new CustomEvent('app-dialog', {
      detail: { open: false },
      bubbles: true
    })
    document.dispatchEvent(event)
  }

  /**
   * adds route name and params as vue router parameter.
   * @param name route name
   * @param params route parameter
   */
  addModalPath(
    name: string,
    params: Record<string, string | number> = {}
  ): void {
    const currentQueries = parsePathQueryString(location.search)
    const nextQueries = { ...currentQueries, ...params }

    const modal = this.getPopup(name)

    // if the persistent is true , we will add a dialog flag to persist it after refresh
    if (modal.persistent) {
      nextQueries.dialog = name
    }

    this.validateParams(modal.requiredParams, params, this.modals)

    history.replaceState(
      {},
      '',
      appRouter.currentRoute.path + stringifyQueryObject(nextQueries)
    )
    const event = new CustomEvent('app-dialog', {
      detail: { open: true, name },
      bubbles: true
    })
    document.dispatchEvent(event)
  }

  /**
   * check the route path if the (dialog) does exist return true else false
   * @return - boolean
   */
  checkModalPath(): boolean {
    const { dialog } = parsePathQueryString(location.search)
    if (dialog && !Array.isArray(dialog)) {
      const modal = this.modals[dialog]
      !modal &&
        console.warn(`could not find modal, Please register the modal first!`)
      return !!modal
    }
    return false
  }

  private validateParams(
    requiredParams: string[],
    params: Record<string, string | number>,
    popup: Record<string, PopUpRouteConfig>
  ) {
    requiredParams.forEach(paramKey => {
      if (params[paramKey] === undefined) {
        throw new Error(
          `required param '${paramKey}' is not passed for popup with name '${popup.name}'`
        )
      }
    })
  }
}

export const popUpRoute = new PopUpRoute()
