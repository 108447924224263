import VueRouter, { RouteConfig, RouteRecordPublic } from 'vue-router'

class LogRoute {
  allRoutes: RouteRecordPublic[] = []

  /**
   * to check if duplicated routes exits returns true
   * @return boolean - based on route registering for the log section
   */
  private static hasNecessaryLogRoute(routes: Array<RouteConfig>): boolean {
    return routes.some((r: RouteConfig) => r.name === `logs-${r.name}`)
  }

  registerLogRoute(router: VueRouter, routes: Array<RouteConfig>) {
    routes.forEach((curRoute: RouteConfig) => {
      if (curRoute.meta && curRoute.meta.logPage) {
        if (LogRoute.hasNecessaryLogRoute(routes))
          throw new Error(
            `Log route for ${curRoute.name} has already been registered, Please avoid duplicated routes!`
          )
        this.generateRoutes(curRoute).forEach(rout => router.addRoute(rout))
        this.allRoutes = router.getRoutes()
      }
    })
  }

  /**
   * @return  all existing routes  also include logs routes
   */
  getAllRoutes(): Array<RouteConfig> {
    return this.allRoutes as Array<RouteConfig>
  }

  /**
   * A route factory method that creates RouteConfig array base on provided meta properties of any route
   * @param to RouteRecordPublic instance.
   */
  private generateRoutes(to: RouteConfig): RouteConfig[] {
    return [
      {
        path: `${to.path}/logs`,
        name: `logs-${to.name}`,
        component: () => import('@/stories/log-data-table/LogDataTable.vue'),
        props({ params }) {
          return {
            ...params,
            parent: to.name,
            previewDynamicComponents:
              to.meta && to.meta.logPage.previewDynamicComponents,
            endpoint: to.meta && to.meta.logPage.endpoint,
            title: to.meta && to.meta.logPage.title
          }
        },

        meta: to.meta && {
          ...to.meta,
          parentRouteName: to.name
        }
      },
      {
        path: `${to.path}/logs/:logId`,
        name: `log-details-${to.name}`,
        component: () => import('@/stories/log-preview/LogPreview.vue'),
        props({ params }) {
          return {
            ...params,
            previewDynamicComponents:
              to.meta && to.meta.logPage.previewDynamicComponents,
            endpoint: to.meta && to.meta.logPage.endpoint,
            title: to.meta && to.meta.logPage.title
          }
        },
        meta: to.meta && {
          ...to.meta,
          parentRouteName: `logs-${to.name}`
        }
      }
    ]
  }
}

const logRoute = new LogRoute()
export default logRoute
