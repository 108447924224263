import { RouteConfig } from 'vue-router'
import { customerAddressEndpoints, customerEndpoints } from '@/modules/customer'
import { customerPreviewComponents } from '@/modules/customer/helpers/previewComponents'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import ResetPassword from '@/modules/customer/components/ResetPassword.vue'
import { customerAddressPreviewLogComponents } from '@/modules/customer/helpers/customerAddressPreviewLogComponents'
import TheUpsertCustomerAddress from '@/modules/customer/components/TheUpsertCustomerAddress.vue'

/**
 * Customer module routes
 * */
export const customerRoutes: RouteConfig[] = [
  {
    path: '/customer',
    name: 'customer',
    component: () =>
      import(
        /* webpackChunkName: "TheCustomerChunk" */ '@/modules/customer/views/TheCustomer.vue'
      ),
    meta: {
      title: 'navigations.customerMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/customer/preview-details/:id',
    name: 'preview-customer',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCustomerChunk" */ '@/modules/customer/views/ThePreviewCustomer.vue'
      ),
    meta: {
      title: 'navigations.customerMetaTitle',
      parentRouteName: 'customer',
      logPage: {
        endpoint: customerEndpoints,
        previewDynamicComponents: customerPreviewComponents
      }
    }
  },
  {
    path: '/customer/upsert-customer/:id?',
    name: 'upsert-customer',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertCustomerChunk" */ '@/modules/customer/views/TheUpsertCustomer.vue'
      ),
    meta: {
      title: 'navigations.customerMetaTitle',
      parentRouteName: 'customer'
    }
  },
  {
    path: '/customer/customer-address/preview-details/:customerId/:id',
    name: 'preview-customerAddress',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCustomerChunk" */ '@/modules/customer/components/TheCustomerAddressOption.vue'
      ),
    meta: {
      title: 'navigations.customerAddressMetaTitle',
      parentRouteName: 'customer',
      logPage: {
        endpoint: customerAddressEndpoints,
        previewDynamicComponents: customerAddressPreviewLogComponents
      }
    }
  },
  {
    path: '/customer/customer-transaction/:trnId/:id/:instanceType',
    name: 'customer-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionChunk" */ '@/modules/transaction/views/Transaction.vue'
      ),
    meta: {
      title: 'navigations.customerTransactionMetaTitle',
      parentRouteName: 'customer'
    }
  },
  {
    path: '/customer/customer-transaction/preview-details/:trnId/:id/:instanceType',
    name: 'preview-customer-transaction',
    component: () =>
      import(
        /* webpackChunkName: "PreviewTransactionChunk" */ '@/modules/transaction/views/PreviewTransaction.vue'
      ),
    meta: {
      title: 'navigations.customerTransactionMetaTitle',
      parentRouteName: 'customer-transaction'
    }
  },
  {
    path: '/customer/customer-transaction/:actionType/:trnId/:id/:instanceType',
    name: 'reload-customer-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionActionChunk" */ '@/modules/transaction/views/TransactionAction.vue'
      ),
    meta: {
      title: 'navigations.customerTransactionMetaTitle',
      parentRouteName: 'customer-transaction'
    }
  }
]
export const customerModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-customer',
    requiredParams: ['id'],
    onDelete: q => customerEndpoints.delete(q.id.toString()),
    dialogTitle: 'customerTitle'
  },
  {
    component: ResetPassword,
    persistent: true,
    name: 'reset-customer-password',
    requiredParams: ['id']
  },
  {
    component: TheUpsertCustomerAddress,
    persistent: true,
    name: 'edit-customer-address',
    requiredParams: ['id']
  },
  {
    component: TheUpsertCustomerAddress,
    persistent: true,
    name: 'add-customer-address',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-customer-address',
    requiredParams: ['id'],
    onDelete: q => customerAddressEndpoints.delete(q.id.toString()),
    dialogTitle: 'customerAddressTitle'
  }
]
