import { ModelApi } from '@/services/api/modelApi'
import { ICommission } from '@/modules/accounting/commission/types/commissionTypes'

/**
 * Commission endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class CommissionEndpoints extends ModelApi<ICommission> {
  constructor() {
    super('merchant_commission')
  }
}
