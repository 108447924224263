import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { paymentOptionEndpoints } from '@/modules/accounting/payment-option'
import { paymentOptionPreviewComponents } from '@/modules/accounting/payment-option/helpers/paymentOptionPreviewComponents'

/**
 * Payment Option module routes
 */
export const paymentOptionRoutes: RouteConfig[] = [
  {
    path: '/payment-option',
    name: 'paymentOption',
    component: () =>
      import(
        /* webpackChunkName: "ThePaymentOptionChunk" */ '@/modules/accounting/payment-option/views/ThePaymentOption.vue'
      ),
    meta: {
      title: 'navigations.paymentOptionMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/payment-option/preview-details/:id',
    name: 'preview-paymentOption',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewPaymentOptionChunk" */ '@/modules/accounting/payment-option/views/ThePreviewPaymentOption.vue'
      ),
    meta: {
      title: 'navigations.paymentOptionMetaTitle',
      parentRouteName: 'paymentOption',
      logPage: {
        endpoint: paymentOptionEndpoints,
        previewDynamicComponents: paymentOptionPreviewComponents
      }
    }
  },
  {
    path: '/payment-option/upsert-payment-option/:id?',
    name: 'upsert-paymentOption',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertPaymentOptionChunk" */ '@/modules/accounting/payment-option/views/TheUpsertPaymentOption.vue'
      ),
    meta: {
      title: 'navigations.paymentOptionMetaTitle',
      parentRouteName: 'paymentOption'
    }
  },
  {
    path: '/payment-option/payment-option-transaction/:trnId/:id/:instanceType',
    name: 'paymentOption-transaction',
    component: () =>
      import(
        /* webpackChunkName: "TransactionChunk" */ '@/modules/transaction/views/Transaction.vue'
      ),
    meta: {
      title: 'navigations.paymentOptionTransactionMetaTitle',
      parentRouteName: 'paymentOption'
    }
  },
  {
    path: '/payment-option/payment-option-transaction/preview-details/:trnId/:id/:instanceType',
    name: 'preview-payment_option-transaction',
    component: () =>
      import(
        /* webpackChunkName: "PreviewTransactionChunk" */ '@/modules/transaction/views/PreviewTransaction.vue'
      ),
    meta: {
      title: 'navigations.paymentOptionTransactionMetaTitle',
      parentRouteName: 'paymentOption-transaction'
    }
  }
]
export const paymentOptionModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-payment-option',
    requiredParams: ['id'],
    onDelete: q => paymentOptionEndpoints.delete(q.id.toString()),
    dialogTitle: 'paymentOptionTitle'
  }
]
