import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertCountry from '@/modules/seller-management/country/views/TheUpsertCountry.vue'
import { countryEndpoints } from '@/modules/seller-management/country'
import { countryPreviewComponents } from '@/modules/seller-management/country/helpers/previewComponents'

export const countryRoutes: RouteConfig[] = [
  {
    path: '/countries',
    name: 'countries',
    component: () =>
      import(
        /* webpackChunkName: "TheCountryChunk" */ '@/modules/seller-management/country/views/TheCountry.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigation.countryMetaTitle'
    }
  },
  {
    path: '/countries/preview-details/:id',
    name: 'preview-country',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCountryChunk" */ '@/modules/seller-management/country/views/ThePreviewCountry.vue'
      ),
    meta: {
      title: 'navigation.countryMetaTitle',
      parentRouteName: 'countries',
      logPage: {
        endpoint: countryEndpoints,
        previewDynamicComponents: countryPreviewComponents
      }
    }
  }
]
export const countryModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertCountry,
    persistent: true,
    name: 'upsert-country',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-country',
    requiredParams: ['id'],
    onDelete: q => countryEndpoints.delete(q.id.toString()),
    dialogTitle: 'countryTitle'
  }
]
