import { ModelApi } from '@/services/api/modelApi'
import axios from 'axios'
import IUpsertProductData, {
  IProduct,
  IVariantItem
} from '@/modules/inventory/product/types/productTypes'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import snakeCaseKeys from 'snakecase-keys'
import AppRouter from '@/AppRouter'
import escapeHTMLElement from '@/shared/helpers/htmlEscaper'
import { getDiff } from '@/shared/helpers/getDifference'

/** Product endpoint extends model api to inherit its methods and custom methods up to its needs */
export class ProductEndpoints extends ModelApi<IProduct> {
  constructor(urlPath: string) {
    super(urlPath)
  }

  /**
   * Update status of each product
   * @param id
   * @param data
   * @param originalData
   **/
  setProductStatus(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .post(`product/${id}/set_status/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * product delete bulkAction
   * @param data
   **/
  productDeleteBulkAction(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/product/product_bulk_delete/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          if (error.code === 'ERR_BAD_RESPONSE') {
            this.responseNotify(error.message, 'error')
            reject(error.message)
          } else {
            this.responseNotify(error.response.data, 'error')
            reject(error.response.data)
          }
        })
    })
  }

  /**
   * product status bulkAction
   * @param data
   **/
  productStatusBulkAction(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/product/product_bulk_set_status/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Update Branch
   * @param id
   * @param data
   * @param originalData
   **/
  setUpdateBranch(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (+id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }
      axios
        .post(`product/${id}/change_product_branch/`, data)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  async addProduct(data: IUpsertProductData, isEmptyVariant: boolean) {
    await axios
      .post(`/product/`, this.prepareData(data, false, isEmptyVariant))
      .then(() => {
        this.responseNotify('notifications.addedSuccessfully')
        AppRouter.back()
      })
      .catch(error => {
        this.responseNotify(
          this.extractErrorMessages(error.response.data),
          'error'
        )
      })
  }

  /** make the error more readable */
  extractErrorMessages(errorData: AnyObject, parentKey = '') {
    const messages: unknown[] = []

    function traverse(data: AnyObject, keyPath: string) {
      if (typeof data === 'string') {
        messages.push(`${keyPath}: ${data}`)
      } else if (Array.isArray(data)) {
        data.forEach(item => traverse(item, keyPath))
      } else if (typeof data === 'object' && data !== null) {
        Object.entries(data).forEach(([key, value]) => {
          traverse(value as AnyObject, key)
        })
      }
    }

    traverse(errorData, parentKey)
    return messages.join(' ')
  }

  prepareData(
    data: IUpsertProductData,
    isUpdate = false,
    isEmptyVariant = false
  ): FormData {
    const convertedData = Object.fromEntries(Object.entries(data.product ?? {}))
    const productData: any = snakeCaseKeys(convertedData, {
      deep: false
    })

    if (productData.description) {
      productData.description = escapeHTMLElement(productData.description)
    }

    const formData = new FormData()
    addProductToFormData(productData, formData)

    /** No need for variant on update */
    if (!isUpdate) {
      const variantData = data.variants

      variantData.forEach(variant => {
        const unNecessaryVariantKeys = ['id']
        unNecessaryVariantKeys.forEach(itemKey => {
          delete variant[itemKey]
        })

        if (variant?.variantOptions)
          variant.variantOptions = Object.keys(variant.variantOptions).map(
            key => ({
              name: key,
              value: variant.variantOptions[key as any]
            })
          ) as any
        else if (isEmptyVariant) {
          variant.variantOptions = []
        }
      })

      const variantDataSnake = snakeCaseKeys(data.variants)
      // Remove unnecessary keys of variant

      if (variantDataSnake && variantDataSnake.length > 0) {
        addVariantsImages(data.variants, formData)
        variantDataSnake.forEach((variant, index: number) => {
          addToFormData(variant, formData, 'variants', index)
        })
      }
    }

    return formData
  }

  async updateProduct(id: Id, data: IUpsertProductData) {
    await axios
      .patch(`/product/${id}/`, this.prepareData(data, true))
      .then(() => {
        this.responseNotify('notifications.updatedSuccessfully')
        AppRouter.back()
      })
      .catch(error => {
        this.responseNotify(error.response.data.detail, 'error')
      })
  }
}

export function addToFormData<T>(
  data: T,
  formData: FormData,
  parentKey: string,
  parentIndex: number
) {
  Object.entries(data).forEach(([key, value]) => {
    const parent = parentKey ? parentKey + '[' + parentIndex + ']' : ''
    if (Array.isArray(value)) {
      // Send empty variant_option case

      if (value.length === 0) {
        formData.append(parent + key + `[0]`, `[${value}]`)
      } else
        value.forEach((elem, subIndex) => {
          if (typeof elem === 'object' && elem) {
            addToFormData(elem, formData, parent + key, subIndex)
          }
        })
    } else {
      if ((value || value === 0) && key !== '0')
        formData.append(parent + key, value as string)
      else formData.append(parent, value as string)
    }
  })
}

/**
 * Add variant images Data to formData with indexed keys
 * @param data Array of variant items
 * @param formData FormData instance
 */
function addVariantsImages(data: IVariantItem[], formData: FormData) {
  Object.entries(data).forEach(([key, value], variantIndex) => {
    if (value.images && value.images.length > 0) {
      value.images.forEach((elem, imageIndex) => {
        if ((elem instanceof File || typeof elem === 'string') && elem) {
          const imageKey = `variants[${variantIndex}]images[${imageIndex}]`
          formData.append(imageKey, elem)
        }
      })
    }
  })
}

/**
 * Add product data to formData with proper key formatting
 * @param data Product data object
 * @param formData FormData instance
 * @param parentKey Optional parent key for nested objects
 */
export function addProductToFormData<T extends AnyObject>(
  data: T,
  formData: FormData,
  parentKey?: string
) {
  Object.entries(data).forEach(([key, value]) => {
    const currentKey = parentKey ? `${parentKey}[${key}]` : key

    if (Array.isArray(value)) {
      if (value.length <= 0) {
        formData.append(currentKey, '[]')
      } else {
        value.forEach((elem, index) => {
          const arrayKey = `${currentKey}[${index}]`

          if (elem instanceof File) {
            const checkElem = elem === null ? '' : elem
            formData.append(arrayKey, checkElem)
          } else if (typeof elem === 'object' && elem) {
            addProductToFormData(elem as AnyObject, formData, arrayKey)
          } else {
            const checkElem = !elem ? '' : elem
            formData.append(arrayKey, checkElem)
          }
        })
      }
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof File) &&
      !(value instanceof Date)
    ) {
      addProductToFormData(value as AnyObject, formData, currentKey)
    } else {
      let checkElem = !value ? '' : value
      if (
        (key === 'quantity' ||
          key === 'track_quantity' ||
          key === 'weight' ||
          key === 'sale_price' ||
          key === 'cost') &&
        !value
      ) {
        checkElem = 0
      }
      formData.append(currentKey, checkElem as string)
    }
  })
}
