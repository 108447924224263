import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertProductVariant from '@/modules/inventory/product-variant/views/TheUpsertProductVariant.vue'
import { productVariantPreviewComponent } from '@/modules/inventory/product-variant/helpers/productVariantPreviewComponent'
import { productVariantEndpoints } from '@/modules/inventory/product-variant'

/** Product Variant module routes */
export const productVariantRoutes: RouteConfig[] = [
  {
    path: '/inventory/product/:productId/:productCategoryId/:productType/product-variants/',
    name: 'product-variant',
    component: () =>
      import(
        /* webpackChunkName: "TheProductVariantChunk" */ '@/modules/inventory/product-variant/views/TheProductVariant.vue'
      ),
    meta: {
      title: 'navigations.productVariantMetaTitle',
      parentRouteName: 'product'
    }
  },
  {
    path: '/inventory/product/:productId/:productCategoryId/:productType/product-variants/preview-details/:productVariantId',
    name: 'preview-product-variant',
    component: () =>
      import(
        /*ThePreviewProductVariantChunk*/ '@/modules/inventory/product-variant/views/ThePreviewProductVariant.vue'
      ),
    meta: {
      title: 'navigations.productVariantMetaTitle',
      parentRouteName: 'product-variant',
      logPage: {
        endpoint: productVariantEndpoints,
        previewDynamicComponents: productVariantPreviewComponent
      }
    }
  }
]

export const productVariantModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-product-variant',
    requiredParams: ['id', 'productId'],
    onDelete: q =>
      productVariantEndpoints.delete(
        q.id.toString(),
        q.productId.toString(),
        `product_variant/${q.id.toString()}/?product_id=${q.productId.toString()}`
      ),
    dialogTitle: 'productTitle'
  },
  {
    component: TheUpsertProductVariant,
    persistent: true,
    name: 'upsert-product-variant',
    requiredParams: [],
    size: 700
  }
]
