import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { transactionFeeEndpoints } from '@/modules/accounting/transaction-fee'
import { transactionFeePreviewComponents } from '@/modules/accounting/transaction-fee/helpers/previewComponents'
import TheUpsertTransactionFee from '@/modules/accounting/transaction-fee/views/TheUpsertTransactionFee.vue'

/**
 * Transaction Fees module routes
 */
export const transactionFeeRoutes: RouteConfig[] = [
  {
    path: '/transaction-fee',
    name: 'transactionFee',
    component: () =>
      import(
        /* webpackChunkName: "TheTransactionFeeChunk" */ '@/modules/accounting/transaction-fee/views/TheTransactionFee.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.transactionFeeMetaTitle'
    }
  },
  {
    path: '/transaction-fee/preview-details/:id',
    name: 'preview-transactionFee',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewTransactionFeeChunk" */ '@/modules/accounting/transaction-fee/views/ThePreviewTransactionFee.vue'
      ),
    meta: {
      title: 'navigations.transactionFeeMetaTitle',
      parentRouteName: 'transactionFee',
      logPage: {
        endpoint: transactionFeeEndpoints,
        previewDynamicComponents: transactionFeePreviewComponents
      }
    }
  }
]
export const transactionFeeModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertTransactionFee,
    persistent: true,
    name: 'edit-transactionFee',
    requiredParams: ['id']
  },
  {
    component: TheUpsertTransactionFee,
    persistent: true,
    name: 'add-transactionFee',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-transactionFee',
    requiredParams: ['id'],
    onDelete: q => transactionFeeEndpoints.delete(q.id.toString()),
    dialogTitle: 'transactionFeeTitle'
  }
]
