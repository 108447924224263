import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertPricingLimitProfile from '@/modules/seller-management/pricing-limit-profile/views/TheUpsertPricingLimitProfile.vue'
import { pricingLimitProfileEndpoints } from '@/modules/seller-management/pricing-limit-profile'
import { pricingLimitProfilePreviewComponents } from '@/modules/seller-management/pricing-limit-profile/helpers/previewComponents'

export const pricingLimitProfileRoutes: RouteConfig[] = [
  {
    path: '/pricing-limit-profile',
    name: 'pricingLimitProfile',
    component: () =>
      import(
        /* webpackChunkName: "ThePricingLimitProfileChunk" */ '@/modules/seller-management/pricing-limit-profile/views/ThePricingLimitProfile.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigation.pricingLimitProfileMetaTitle'
    }
  },
  {
    path: '/pricing-limit-profile/preview-details/:id',
    name: 'preview-pricingLimitProfile',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewPricingLimitProfileChunk" */ '@/modules/seller-management/pricing-limit-profile/views/ThePreviewPricingLimitProfile.vue'
      ),
    meta: {
      title: 'navigation.pricingLimitProfileMetaTitle',
      parentRouteName: 'pricingLimitProfile',
      logPage: {
        endpoint: pricingLimitProfileEndpoints,
        previewDynamicComponents: pricingLimitProfilePreviewComponents
      }
    }
  }
]
export const pricingLimitProfileModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertPricingLimitProfile,
    persistent: true,
    name: 'upsert-pricingLimitProfile',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-pricingLimitProfile',
    requiredParams: ['id'],
    onDelete: q => pricingLimitProfileEndpoints.delete(q.id.toString()),
    dialogTitle: 'pricingLimitProfileTitle'
  }
]
