import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import {
  variantEndpoints,
  variantOptionsEndpoints
} from '@/modules/inventory/variant'

import TheUpsertVariant from '@/modules/inventory/variant/views/TheUpsertVariant.vue'
import { variantOptionPreviewComponents } from '@/modules/inventory/variant/helpers/previewVariantOptionComponents'
import TheUpsertVariantOptions from '@/modules/inventory/variant/views/TheUpsertVariantOptions.vue'
import { variantPreviewComponents } from '@/modules/inventory/variant/helpers/previewVariantComponents'

/**
 * Variant module routes
 */
export const variantRoutes: RouteConfig[] = [
  {
    path: '/inventory/variant',
    name: 'variant',
    component: () =>
      import(
        /* webpackChunkName: "TheVariantChunk" */ '@/modules/inventory/variant/views/TheVariant.vue'
      ),
    meta: {
      title: 'navigations.variantMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/inventory/variant/preview-details/:id',
    name: 'preview-variant',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewVariantChunk" */ '@/modules/inventory/variant/views/ThePreviewVariant.vue'
      ),
    meta: {
      title: 'navigations.variantMetaTitle',
      parentRouteName: 'variant',
      logPage: {
        endpoint: variantEndpoints,
        previewDynamicComponents: variantPreviewComponents
      }
    }
  },
  {
    path: '/inventory/variant/variant-option/preview-details/:variantId/:id',
    name: 'preview-variant-options',
    component: () =>
      import(
        /* webpackChunkName: "TheVariantOptionsChunk" */ '@/modules/inventory/variant/components/TheVariantOptions.vue'
      ),
    meta: {
      title: 'navigations.variantMetaTitle',
      parentRouteName: 'variant',
      logPage: {
        endpoint: variantOptionsEndpoints,
        previewDynamicComponents: variantOptionPreviewComponents
      }
    }
  }
]
export const variantModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertVariant,
    persistent: true,
    name: 'edit-variant',
    requiredParams: ['id']
  },
  {
    component: TheUpsertVariant,
    persistent: true,
    name: 'add-variant',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-variant',
    requiredParams: ['id'],
    onDelete: q => variantEndpoints.delete(q.id.toString()),
    dialogTitle: 'variantTitle'
  },
  {
    component: TheUpsertVariantOptions,
    persistent: true,
    name: 'edit-variant-options',
    requiredParams: ['id']
  },
  {
    component: TheUpsertVariantOptions,
    persistent: true,
    name: 'add-variant-options',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-variant-options',
    requiredParams: ['id'],
    onDelete: q => variantOptionsEndpoints.delete(q.id.toString()),
    dialogTitle: 'variantOptionsTitle'
  }
]
