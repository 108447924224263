import { ModelApi } from '@/services/api/modelApi'
import { ICategory } from '@/modules/inventory/category/types/categoryTypes'
import axios from 'axios'

export class CategoryEndpoints extends ModelApi<ICategory> {
  constructor(urlPath: string) {
    super(urlPath)
  }

  /**
   * order categories by sending a list of category ids
   * @param categoryIds
   */
  async setCategoryOrder(categoryIds: Array<number>) {
    try {
      const response = await axios.post(`${this.getUrl()}reorder_category/`, {
        categoryIds
      })
      this.responseNotify('notifications.updatedSuccessfully')
      return response.data.data
    } catch (error) {
      this.responseNotify(error.response.data, 'error')
      throw error.response.data
    }
  }
}
