import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { ICommission } from '@/modules/accounting/commission/types/commissionTypes'

/**
 * dynamic component generator method
 * @param commission is ICommission interface type which
 * @return An Array of IPreviewComponent commission type
 */
export function commissionPreviewComponents(
  commission: ICommission
): PreviewComponent<ICommission>[] {
  return [
    PreviewComponent.labelValue<ICommission>(
      'name',
      'name',
      commission.name
    ).setTitle('commissionDetail'),
    PreviewComponent.labelValue<ICommission>(
      'amount',
      'amount',
      ContentFormatter.$formatToPercentage(commission.amount, true)
    ).setBreak(true),
    ...metadataLabelValues(commission)
  ]
}
