import { IInternationalLogistic } from '@/modules/seller-management/international-logistic/types/internationalLogisticTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param internationalLogistic is IInternationalLogistic interface type which
 * @return An Array of IPreviewComponent international logistic type
 */
export function internationalLogisticPreviewComponents(
  internationalLogistic: IInternationalLogistic
): PreviewComponent<IInternationalLogistic>[] {
  return [
    PreviewComponent.labelValue<IInternationalLogistic>(
      'countryName',
      'country',
      internationalLogistic && internationalLogistic?.country?.name
    )
      .setTitle('internationalLogisticDetails')
      .setCol('6'),
    PreviewComponent.labelValue<IInternationalLogistic>(
      'cost',
      'cost',
      ContentFormatter.$formatToCurrency(internationalLogistic.cost, true)
    ).setCol('6'),
    ...metadataLabelValues(internationalLogistic)
  ]
}
