
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { INavigationItem } from '@/core/types/coreTypes'

const navigationStore = namespace('navigation')

@Component({
  name: 'TheNavigationDrawer'
})
export default class TheNavigationDrawer extends Vue {
  @Prop(Boolean) readonly miniMenu!: boolean
  drawer = true
  model = 1
  @navigationStore.Getter('getNavigationList')
  navigationItem!: INavigationItem[]
}
