/**
 * Staff interface type based on backend api response
 */
import { Id } from '@/shared/types/builtInTypes'

export enum CurrencyNameEnum {
  IQD = 'IQD',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  TRY = 'TRY'
}

export interface ICurrencyRate {
  id: Id
  createdAt: string
  createdBy: string
  currencyRateDetails: Array<{
    id: Id
    rate: number
    currencyId: Id
    currencyRateId: Id
    currencyName: string
  }>
}

export interface ILastCurrencyRate {
  id: Id
  rate: number
  currencyId: Id
  currencyRateId: Id
  currencyName: CurrencyNameEnum
}
