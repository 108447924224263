/**
 * Check the image Path if it does not contain any url of domain it will add app api base url
 * Also, Check if a type of image is a file.
 * @param image
 * */
export function createImagePath(image: string): string {
  // Remove leading backslash if it exists
  const customImage = image.startsWith('/') ? image.slice(1) : image

  return /^((blob:https?|http|https):\/\/.([-a-zA-Z0-9@:%._+~#=]\.)?[-a-zA-Z0-9@:%._+~#=]{2,256})/g.test(
    customImage
  )
    ? customImage
    : process.env.VUE_APP_API_BASE_URL + customImage
}
