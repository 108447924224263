import { PreviewComponent } from '@/services/utils/basePreview'
import i18n from '@/plugins/i18n'
import { IDType, ISeller } from '@/modules/seller/types/sellerTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import AppRouter from '@/AppRouter'

/**
 * dynamic component generator method
 * @param seller is ISeller interface type which
 * @param emit
 * @param trackingId
 * @return An Array of IPreviewComponent seller type
 */
export function sellerPreviewComponents(
  seller: ISeller
): PreviewComponent<ISeller>[] {
  const isIraq = seller.country?.name?.toLowerCase() === 'iraq'
  const isRequest = AppRouter.currentRoute.name === 'review-sellerRequest'
  return [
    // PERSONAL INFO
    PreviewComponent.labelValue<ISeller>(
      'firstName',
      'firstName',
      seller.firstName
    ).setTitle('personalInfo'),
    PreviewComponent.labelValue<ISeller>(
      'lastName',
      'lastName',
      seller.lastName
    ),
    PreviewComponent.labelValue<ISeller>('sellerId', 'id', seller.id),
    PreviewComponent.labelValue<ISeller>(
      'gender',
      'gender',
      seller.gender === 'f' ? i18n.t('label.female') : i18n.t('label.male')
    ),
    PreviewComponent.labelValue<ISeller>(
      'status',
      'isActive',
      seller.isActive ? i18n.t('label.active') : i18n.t('label.inActive')
    )
      .setClass(seller.isActive ? 'success--text' : 'error--text')
      .setBreak(true),
    // ID DETAILS
    PreviewComponent.labelValue<ISeller>(
      'idType',
      'idType',
      ContentFormatter.$customizeText(seller.idType)
    ).setTitle('idDetails'),
    PreviewComponent.labelValue<ISeller>(
      'idNumber',
      'idNumber',
      seller.idNumber
    ).setBreak(true),
    PreviewComponent.fileLink<ISeller>('front', 'idFront', seller.idFront)
      .setClass('col-12 col-md-6')
      .setProp({
        isImageType: true,
        customFileName: i18n.t('label.front')
      }),
    PreviewComponent.fileLink<ISeller>('back', 'idBack', seller.idBack)
      .setClass('col-12 col-md-6')
      .setHidden(seller.idType === IDType.PASSPORT)
      .setProp({
        isImageType: true,
        customFileName: i18n.t('label.back')
      }),
    // CONTACT DETAILS
    PreviewComponent.labelValue<ISeller>(
      'primaryPhoneNumber',
      'phone',
      seller.phone
    ).setTitle('contactDetails'),
    PreviewComponent.labelValue<ISeller>(
      'secondaryPhoneNumber',
      'secondaryPhone',
      seller.secondaryPhone
    ),
    PreviewComponent.labelValue<ISeller>('emailAddress', 'email', seller.email),
    PreviewComponent.labelValue<ISeller>(
      'country',
      'countryId',
      seller.country?.name
    ),
    PreviewComponent.labelValue<ISeller>('address', 'address', seller.address),
    PreviewComponent.labelValue<ISeller>(
      'returnAddress',
      'returnAddress',
      seller.returnAddress
    ),
    PreviewComponent.labelValue<ISeller>(
      'description',
      'description',
      seller.description
    ).setCol('12'),
    // ESTIMATION ARRIVAL
    PreviewComponent.labelValue<ISeller>(
      'minEstimationDays',
      'estimationArrival',
      seller.estimationArrival?.minEstimation
    )
      .setTitle('estimationArrival')
      .setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'maxEstimationDays',
      'estimationArrival',
      seller.estimationArrival?.maxEstimation
    ).setHidden(isRequest),
    // ESTIMATION ARRIVAL
    PreviewComponent.labelValue<ISeller>(
      'internationalLogisticCost',
      'internationalLogistic',
      seller.internationalLogistic?.cost
    )
      .setTitle('internationalLogistic')
      .setHidden(isIraq || isRequest),
    // SELLER MANAGEMENT
    PreviewComponent.labelValue<ISeller>(
      'cashOutCommissionFee',
      'cashOutCommissionId',
      seller.cashOutCommission?.label
    )
      .setTitle('sellerManagement')
      .setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'arbelaTransferFee',
      'transferFeeId',
      seller.transferFee?.label
    ).setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'basePriceCommission',
      'merchantCommissionId',
      seller.merchantCommission?.name
    ).setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'discountProfile',
      'discountProfileId',
      seller.discountProfile?.profileName
    ).setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'pricingProfile',
      'pricingProfileId',
      seller.pricingProfile?.profileName
    ).setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'stockProfile',
      'stockProfileId',
      seller.stockProfile?.profileName
    ).setHidden(isRequest),
    PreviewComponent.labelValue<ISeller>(
      'escalationProfile',
      'escalationProfileId',
      seller.escalationProfile?.profileName
    )
      .setBreak(true)
      .setHidden(isRequest),
    // COMPANY DETAILS
    PreviewComponent.labelValue<ISeller>(
      'companyName',
      'companyName',
      seller.companyName
    ).setTitle('companyDetails'),
    PreviewComponent.labelValue<ISeller>(
      'storeName',
      'storeName',
      seller.storeName
    ),
    PreviewComponent.labelValue<ISeller>(
      'companyAddress',
      'companyAddress',
      seller.companyAddress
    ),
    PreviewComponent.labelValue<ISeller>(
      'companyEmailAddress',
      'companyEmail',
      seller.companyEmail
    ),
    PreviewComponent.fileLink<ISeller>(
      'companyLogo',
      'companyLogo',
      seller.companyLogo
    )
      .setClass('col-12 col-md-6')
      .setProp({
        isImageType: true,
        customFileName: i18n.t('label.companyLogo')
      }),
    PreviewComponent.fileLink<ISeller>(
      'businessLicense',
      'businessLicense',
      seller.businessLicense
    )
      .setClass('col-12 col-md-6')
      .setProp({
        customFileName: i18n.t('label.businessLicense')
      }),
    PreviewComponent.fileLink<ISeller>(
      'articlesOfAssociation',
      'articlesOfAssociation',
      seller.articlesOfAssociation
    )
      .setClass('col-12 col-md-6')
      .setProp({
        customFileName: i18n.t('label.articlesOfAssociation')
      })
      .setBreak(true),
    // BANK DETAILS
    PreviewComponent.labelValue<ISeller>(
      'bankAccountOwner',
      'bankAccountOwner',
      seller.bankAccountOwner
    )
      .setTitle('bankDetails')
      .setHidden(isIraq),
    PreviewComponent.labelValue<ISeller>(
      'bankName',
      'bankName',
      seller.bankName
    ).setHidden(isIraq),
    PreviewComponent.labelValue<ISeller>('iban', 'iban', seller.iban).setHidden(
      isIraq
    ),
    PreviewComponent.labelValue<ISeller>('swift', 'swift', seller.swift)
      .setBreak(true)
      .setHidden(isIraq),
    // META DATA
    ...metadataLabelValues(seller)
  ]
}
