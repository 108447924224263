/**
 * Transaction Fee interface type based on backend api response
 */
import { Id, IMetadata } from '@/shared/types/builtInTypes'

export enum TypeTransaction {
  fee = 'fee',
  commission = 'commission'
}

export enum AmountType {
  fixed = 'fixed',
  percentage = 'percentage'
}

export interface ITypeTransaction {
  id: Id
  name: TypeTransaction
}

export interface IAmountType {
  id: Id
  name: AmountType
}

/**
 * interface for endpoint `transaction_fee`
 */
export interface ITransactionFee extends IMetadata {
  id: Id
  label: string
  type: TypeTransaction
  amountType: AmountType
  amount: number
  code: string
}

/**
 * interface for endpoint `commission_list`
 */
export interface ICommissionFeeList {
  id: Id
  label: string
}

/**
 * interface for endpoint `fee_list`
 */
export interface ITransferFeeList {
  id: Id
  label: string
}
