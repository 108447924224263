import { Route } from 'vue-router'
import { AnyObject } from '@/shared/types/builtInTypes'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import VueI18n from 'vue-i18n'
import TranslateResult = VueI18n.TranslateResult

/**
 * Get page title of the provide page, params, and remote data.
 * @param title page title
 * @param params route params
 * @param remoteData remote data
 */
export function generateDynamicTitle(
  title:
    | string
    | TranslateResult
    | ((
        params: Route['params'],
        queries: Route['query'],
        remoteDataFetchers: Partial<AnyObject>
      ) => string | TranslateResult),
  params: Route['params'],
  remoteData: AnyObject = {}
): string {
  if (typeof title === 'function') {
    return `${title(params, parsePathQueryString(location.search), remoteData)}`
  }
  return `${title}`
}
