
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import {
  BulkActionList,
  IProduct,
  ProductStatus
} from '@/modules/inventory/product/types/productTypes'
import { popUpRoute } from '@/services/utils/popupRoute'
import { productEndpoints } from '@/modules/inventory/product'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import { AnyObject } from '@/shared/types/builtInTypes'
import AppStore from '@/AppStore'

@Component({
  name: 'BulkAction',
  components: {
    PreviewAvatar,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class BulkAction extends Vue {
  loading = false
  data = {} as IProduct

  listActions: AnyObject[] = [
    {
      id: BulkActionList.productBulkDelete,
      name: BulkActionList.productBulkDelete
    },
    {
      id: BulkActionList.productBulkUpdate,
      name: BulkActionList.productBulkUpdate
    }
  ]
  ProductStatusList: AnyObject[] = [
    {
      id: ProductStatus.active,
      name: ProductStatus.active
    },
    {
      id: ProductStatus.inactive,
      name: ProductStatus.inactive
    },
    {
      id: ProductStatus.freeze,
      name: ProductStatus.freeze
    }
  ]

  fields(product: IProduct): Field<IProduct>[] {
    return [
      Field.dropdown<IProduct>(
        'action',
        'bulkActionType',
        () => Promise.resolve(this.listActions),
        (item: AnyObject) => `${this.$t('label.' + item.name)}`,
        'id',
        ['status', 'note']
      )
        .required()
        .setCol('col-12'),
      Field.dropdown<IProduct>(
        'listingStatus',
        'status',
        () => Promise.resolve(this.ProductStatusList),
        (item: AnyObject) => `${this.$t('label.' + item.name)}`
      )
        .required()
        .setCol('col-12')
        .setHidden(
          product?.bulkActionType !== BulkActionList.productBulkUpdate
        ),
      Field.textarea<IProduct>('note', 'note')
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
        .setHidden(product?.bulkActionType !== BulkActionList.productBulkUpdate)
    ]
  }
  responseNotify(message: string, type = 'error') {
    AppStore.dispatch('notification/pushNotification', {
      type: type,
      messages: message
    }).finally()
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: IProduct) {
    const { ids } = parsePathQueryString(location.search)

    // Split the `ids` string into an array
    const productIdsArray = ids?.split?.(',').map(id => parseInt(id.trim(), 10))
    const deleteData = {
      product_ids: productIdsArray
    }
    const statusData = {
      ...deleteData,
      status: values.status,
      note: values.note
    }

    if (!ids) {
      return this.responseNotify('productIds: This field is required.')
    }

    if (values.bulkActionType === BulkActionList.productBulkDelete) {
      await productEndpoints.productDeleteBulkAction(deleteData)
    } else if (values.bulkActionType === BulkActionList.productBulkUpdate) {
      await productEndpoints.productStatusBulkAction(statusData)
    }
    this.closePopUp()
    eventBus.refreshData()
  }

  closePopUp() {
    popUpRoute.removeModalPath(['ids'])
    eventBus.refreshData()
  }
}
