import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import SetReturnStatusItem from '@/modules/order-management/returned-order/components/SetReturnStatus.vue'

/**
 * returnedOrder module routes
 */
export const returnedOrderRoutes: RouteConfig[] = [
  {
    path: '/returnedOrder',
    name: 'returnedOrder',
    component: () =>
      import(
        /* webpackChunkName: "TheReturnedOrderChunk" */ '@/modules/order-management/returned-order/views/TheReturnedOrder.vue'
      ),
    meta: {
      title: 'navigations.returnedOrderMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/returnedOrder/preview-details/:id',
    name: 'preview-returnedOrder',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewReturnedOrderChunk" */ '@/modules/order-management/returned-order/views/ThePreviewReturnedOrder.vue'
      ),
    meta: {
      title: 'navigations.returnedOrderMetaTitle',
      parentRouteName: 'returnedOrder'
    }
  },
  {
    path: '/returnedOrder/inspection-returnedOrder/:id',
    name: 'inspection-returned-order',
    component: () =>
      import(
        /* webpackChunkName: "TheInspectionReturnedOrderChunk" */ '@/modules/order-management/returned-order/views/TheInspectionReturnedOrder.vue'
      ),
    meta: {
      title: 'navigations.returnedOrderMetaTitle',
      parentRouteName: 'returnedOrder'
    }
  },
  {
    path: '/returnedOrder/refund-order/:id',
    name: 'refund-order',
    component: () =>
      import(
        /* webpackChunkName: "TheRefundOrderChunk" */ '@/modules/order-management/returned-order/views/TheRefundOrder.vue'
      ),
    meta: {
      title: 'navigations.returnedOrderMetaTitle',
      parentRouteName: 'returnedOrder'
    }
  }
]

export const returnOrderModalRoute: PopUpRouteConfig[] = [
  {
    component: SetReturnStatusItem,
    persistent: true,
    name: 'set-return-status-item',
    requiredParams: ['id'],
    size: 600
  }
]
