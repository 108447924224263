import { IEstimationArrival } from '@/modules/seller-management/estimation-arrival/types/estimationArrivalTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param estimationArrival is IEstimationArrival interface type which
 * @return An Array of IPreviewComponent estimation arrival type
 */
export function estimationArrivalPreviewComponents(
  estimationArrival: IEstimationArrival
): PreviewComponent<IEstimationArrival>[] {
  return [
    PreviewComponent.labelValue<IEstimationArrival>(
      'countryNameField',
      'country',
      estimationArrival?.country?.name
    )
      .setTitle('estimationArrivalDetails')
      .setCol('6'),
    PreviewComponent.labelValue<IEstimationArrival>(
      'minEstimationDays',
      'minEstimation',
      estimationArrival.minEstimation
    ).setCol('6'),
    PreviewComponent.labelValue<IEstimationArrival>(
      'maxEstimationDays',
      'maxEstimation',
      estimationArrival.maxEstimation
    )
      .setCol('6')
      .setBreak(true),
    ...metadataLabelValues(estimationArrival)
  ]
}
