import { PreviewComponent } from '@/services/utils/basePreview'
import { IVariantOptions } from '@/modules/inventory/variant/types/variantTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param variantOption is IVariantOptions interface type which
 * @return An Array of IPreviewComponent variant type
 */
export function variantOptionPreviewComponents(
  variantOption: IVariantOptions
): PreviewComponent<IVariantOptions>[] {
  return [
    PreviewComponent.labelValue<IVariantOptions>(
      'name',
      'name',
      variantOption.name
    ),
    PreviewComponent.labelValue<IVariantOptions>(
      'code',
      'code',
      variantOption.code
    ),
    ...metadataLabelValues(variantOption)
  ]
}
