import { Module, VuexModule } from 'vuex-module-decorators'
import { INavigationItem } from '@/core/types/coreTypes'
import { hasPermission } from '@/shared/helpers/hasPermission'
import staffPermissions from '@/modules/staff/helpers/staffPermissions.json'
import cityPermissions from '@/modules/preference/city/helpers/cityPermissions.json'
import customerPermissions from '@/modules/customer/helpers/customerPermissions.json'
import variantPermissions from '@/modules/inventory/variant/helpers/variantPermissions.json'
import discountPermissions from '@/modules/inventory/discount/helpers/discountPermissions.json'
import categoryPermissions from '@/modules/inventory/category/herlpers/categoryPermissions.json'
import apiPermission from '@/modules/preference/api-integration/helpers/ApiPermissions.json'
import localizationPermissions from '@/modules/preference/localization/helpers/localizationPermissions.json'
import rolePermissions from '@/modules/preference/role/helpers/rolePermissions.json'
import taxFeePermissions from '@/modules/accounting/tax/helpers/taxPermissions.json'
import currencyRatePermissions from '@/modules/accounting/currency-rate/helpers/currencyRatePermissions.json'
import paymentOptionPermissions from '@/modules/accounting/payment-option/helpers/paymentOptionPermissions.json'
import sellerPermissions from '@/modules/seller/helpers/sellerPermissions.json'
import productPermissions from '@/modules/inventory/product/helpers/productPermissions.json'
import stockPermissions from '@/modules/inventory/stock/helpers/stockPermissions.json'
import transactionFeePermissions from '@/modules/accounting/transaction-fee/helpers/transactionFeePermissions.json'
import commissionPermissions from '@/modules/accounting/commission/helpers/commissionPermissions.json'
import collectionPermissions from '@/modules/page/collection/helpers/collectionPermissions.json'
import pagePermissions from '@/modules/page/page/helpers/pagePermissions.json'
import denominationPermissions from '@/modules/arbela-gift-card/denomination/helpers/denominationPermissions.json'
import batchPermissions from '@/modules/arbela-gift-card/batch/helpers/batchPermissions.json'
import giftCardPermissions from '@/modules/arbela-gift-card/gift-card/helpers/giftCardPermissions.json'
import weightEstimationPermissions from '@/modules/inventory/weight-estimation/helpers/weightEstimationPermissions.json'
import settingPermissions from '@/modules/preference/setting/helpers/settingPermissions.json'
import brandPermissions from '@/modules/preference/brand/helpers/brandPermissions.json'
import pricingLimitProfilePermissions from '@/modules/seller-management/pricing-limit-profile/helpers/pricingLimitProfilePermissions.json'
import stockProfilePermissions from '@/modules/seller-management/stock-profile/helpers/stockProfilePermissions.json'
import escalationProfilePermissions from '@/modules/seller-management/escalation-profile/helpers/escalationProfilePermissions.json'
import discountProfilePermissions from '@/modules/seller-management/discount-profile/helpers/discountProfilePermissions.json'
import countryPermissions from '@/modules/seller-management/country/helpers/countryPermissions.json'
import workflowActionPermissions from '@/modules/seller-management/workflow-action/helpers/workflowActionPermissions.json'
import workflowManagementPermissions from '@/modules/seller-management/workflow-management/helpers/workflowManagementPermissions.json'
import sellerRequestPermissions from '@/modules/request/seller-request/helpers/sellerRequestPermissions.json'
import estimationArrivalPermission from '@/modules/seller-management/estimation-arrival/helpers/estimationArrivalPermission.json'
import internationalLogisticPermission from '@/modules/seller-management/international-logistic/helpers/internationalLogisticPermission.json'
import orderPermissions from '@/modules/order-management/local-orders/helpers/orderPermissions.json'
import returnedOrderPermissions from '@/modules/order-management/returned-order/helpers/returnedOrderPermissions.json'

/** Navigation items vuex module. */
@Module({ namespaced: true, name: 'navigation' })
export default class NavigationStore extends VuexModule {
  navigationItems: INavigationItem[] = [
    {
      id: 1,
      title: 'home',
      icon: 'fal fa-home',
      permissionCode: '',
      to: '/'
    },
    {
      id: 2,
      title: 'request',
      icon: 'fal fa-clipboard-list-check',
      sub: [
        {
          id: 1,
          title: 'sellerRequest',
          icon: 'fal fa-clipboard-list-check',
          permissionCode: sellerRequestPermissions.VIEW,
          to: '/seller-request'
        }
      ]
    },
    {
      id: 3,
      title: 'sellers',
      icon: 'fal fa-user-tie',
      permissionCode: sellerPermissions.VIEW,
      to: '/seller'
    },
    {
      id: 4,
      title: 'customer',
      icon: 'fal fa-user-vneck',
      permissionCode: customerPermissions.VIEW,
      to: '/customer'
    },
    {
      id: 5,
      title: 'inventory',
      icon: 'fal fa-warehouse',
      sub: [
        {
          id: 1,
          title: 'stockAndPrice',
          icon: 'fal fa-stock',
          permissionCode: stockPermissions.VIEW,
          to: '/inventory/stock-and-price'
        },
        {
          id: 2,
          title: 'product',
          icon: 'fal fa-box-open',
          permissionCode: productPermissions.VIEW,
          to: '/inventory/product'
        },
        {
          id: 3,
          title: 'variants',
          icon: 'fal fa-ballot',
          permissionCode: variantPermissions.VIEW,
          to: '/inventory/variant'
        },
        {
          id: 4,
          title: 'discounts',
          icon: 'fal fa-ballot',
          permissionCode: discountPermissions.VIEW,
          to: '/inventory/discount'
        },
        {
          id: 5,
          title: 'category',
          icon: 'fal fa-shapes',
          permissionCode: categoryPermissions.VIEW,
          to: '/inventory/categories'
        },
        {
          id: 6,
          title: 'weightEstimation',
          icon: 'fal fa-weight-scale',
          permissionCode: weightEstimationPermissions.VIEW,
          to: '/inventory/weight-estimation'
        }
      ]
    },
    {
      id: 6,
      title: 'orderManagement',
      icon: 'fal fa-truck',
      sub: [
        {
          id: 1,
          title: 'localOrder',
          icon: 'fal fa-cart-shopping',
          permissionCode: orderPermissions.VIEW,
          to: '/order'
        },
        {
          id: 2,
          title: 'itemOrders',
          icon: 'fal fa-bag-shopping',
          permissionCode: orderPermissions.VIEW,
          to: '/orderItems'
        },
        {
          id: 3,
          title: 'returnedOrder',
          icon: 'fal fa-cart-shopping',
          permissionCode: returnedOrderPermissions.VIEW,
          to: '/returnedOrder'
        }
      ]
    },
    {
      id: 7,
      title: 'accounting',
      icon: 'fal fa-circle-dollar',
      sub: [
        {
          id: 1,
          title: 'currencyRate',
          icon: 'fal fa-money-bill-1-wave',
          permissionCode: currencyRatePermissions.VIEW,
          to: '/currency-rate'
        },
        {
          id: 2,
          title: 'paymentOption',
          icon: 'fal fa-file-invoice-dollar',
          permissionCode: paymentOptionPermissions.VIEW,
          to: '/payment-option'
        },
        {
          id: 3,
          title: 'tax',
          icon: 'fal fa-envelope-open-dollar',
          permissionCode: taxFeePermissions.VIEW,
          to: '/tax'
        },
        {
          id: 4,
          title: 'transactionFee',
          icon: 'fal fa-money-bill-transfer',
          permissionCode: transactionFeePermissions.VIEW,
          to: '/transaction-fee'
        },
        {
          id: 5,
          title: 'commission',
          icon: 'fal fa-percent',
          permissionCode: commissionPermissions.VIEW,
          to: '/commission'
        }
      ]
    },
    {
      id: 8,
      title: 'arbelaGiftCard',
      icon: 'fal fa-gift-card',
      sub: [
        {
          id: 1,
          title: 'denomination',
          icon: 'fal fa-credit-card-front',
          permissionCode: denominationPermissions.VIEW,
          to: '/gift-card-denomination'
        },
        {
          id: 2,
          title: 'batch',
          icon: 'fal fa-credit-card',
          permissionCode: batchPermissions.VIEW,
          to: '/gift-card-batch'
        },
        {
          id: 3,
          title: 'trackingCard',
          icon: 'fal fa-input-numeric',
          permissionCode: giftCardPermissions.VIEW,
          to: '/gift-card/serial-number'
        }
      ]
    },
    {
      id: 9,
      title: 'pageManagement',
      icon: 'fal fa-chart-tree-map',
      sub: [
        {
          id: 1,
          title: 'page',
          icon: 'fal fa-pager',
          permissionCode: pagePermissions.VIEW,
          to: '/page'
        },
        {
          id: 2,
          title: 'collection',
          icon: 'fal fa-chart-tree-map',
          permissionCode: collectionPermissions.VIEW,
          to: '/collection'
        }
      ]
    },
    {
      id: 10,
      title: 'staffManagement',
      icon: 'fal fa-users',
      permissionCode: staffPermissions.VIEW,
      to: '/staff'
    },
    {
      id: 11,
      title: 'sellerManagement',
      icon: 'fal fa-ballot',
      sub: [
        {
          id: 1,
          title: 'discountProfile',
          icon: 'fal fa-escalator',
          permissionCode: discountProfilePermissions.VIEW,
          to: '/discount-profile'
        },
        {
          id: 2,
          title: 'country',
          icon: 'fal fa-country',
          permissionCode: countryPermissions.VIEW,
          to: '/countries'
        },
        {
          id: 3,
          title: 'pricingLimitProfile',
          icon: 'fal fa-rectangle-history',
          permissionCode: pricingLimitProfilePermissions.VIEW,
          to: '/pricing-limit-profile'
        },
        {
          id: 4,
          title: 'stockProfile',
          icon: 'fal fa-rectangle-history',
          permissionCode: stockProfilePermissions.VIEW,
          to: '/stock-profile'
        },
        {
          id: 5,
          title: 'escalationProfile',
          icon: 'fal fa-escalator',
          permissionCode: escalationProfilePermissions.VIEW,
          to: '/escalation-profile'
        },
        {
          id: 6,
          title: 'workflowActions',
          icon: 'fal fa-arrow-progress',
          permissionCode: workflowActionPermissions.VIEW,
          to: '/workflow-action'
        },
        {
          id: 7,
          title: 'workflowManagement',
          icon: 'fal fa-arrow-progress',
          permissionCode: workflowManagementPermissions.VIEW,
          to: '/workflow-management'
        },
        {
          id: 8,
          title: 'estimationArrival',
          icon: 'fal fa-arrow-progress',
          permissionCode: estimationArrivalPermission.VIEW,
          to: '/estimation-arrival'
        },
        {
          id: 9,
          title: 'internationalLogistic',
          icon: 'fal fa-arrow-progress',
          permissionCode: internationalLogisticPermission.VIEW,
          to: '/international-logistic'
        }
      ]
    },
    {
      id: 12,
      title: 'preferences',
      icon: 'fal fa-gear',
      sub: [
        {
          id: 1,
          title: 'city',
          icon: 'fal fa-city',
          permissionCode: cityPermissions.VIEW,
          to: '/city'
        },
        {
          id: 2,
          title: 'brand',
          icon: 'fal fa-shop',
          permissionCode: brandPermissions.VIEW,
          to: '/brand'
        },
        {
          id: 3,
          title: 'roleManagement',
          icon: 'fal fa-list-check',
          to: '/roles',
          permissionCode: rolePermissions.VIEW
        },
        {
          id: 4,
          title: 'localizations',
          icon: 'fal fa-earth-americas',
          to: '/localization',
          permissionCode: localizationPermissions.VIEW
        },
        {
          id: 5,
          title: 'apiConfig',
          icon: 'fal fa-database',
          permissionCode: apiPermission.VIEW_CONFIG,
          to: '/api-config'
        },
        {
          id: 6,
          title: 'setting',
          icon: 'fal fa-wrench-simple',
          permissionCode: settingPermissions.VIEW,
          to: '/setting'
        }
      ]
    }
  ]

  /** Get navigation item list from vuex store. */
  get getNavigationList() {
    const permittedMenu: INavigationItem[] = []
    filterMenu(this.navigationItems, permittedMenu)
    return permittedMenu
  }
}

/**
 * Recursively checks the menu items for permitted submenu items
 * @param menuItems
 * @param filteredMenu
 *  */
function filterMenu(
  menuItems: INavigationItem[],
  filteredMenu: INavigationItem[]
) {
  menuItems.forEach(menuItem => {
    if (menuItem.sub) {
      filteredMenu.push({ ...menuItem })

      const lastMenuItem = filteredMenu[filteredMenu.length - 1]

      lastMenuItem.sub = []
      filterMenu(
        menuItem.sub as INavigationItem[],
        lastMenuItem.sub as INavigationItem[]
      )

      // if none of the child items had permission, remove the parent
      if (!lastMenuItem.sub?.length) {
        filteredMenu.pop()
      }

      // if only one child has permission, add its route and name to the parent
      else if (lastMenuItem.sub?.length === 1) {
        lastMenuItem.to = lastMenuItem.sub[0].to
        lastMenuItem.permissionCode = lastMenuItem.sub[0].permissionCode
        lastMenuItem.title = lastMenuItem.sub[0].title
        delete lastMenuItem.sub
      }
    } else {
      // the menu item has no children, so check its permission
      if (checkPermission(menuItem)) {
        filteredMenu.push(menuItem)
      }
    }
  })
}

function checkPermission(menuItem: INavigationItem) {
  return (
    !menuItem.permissionCode ||
    hasPermission(
      Array.isArray(menuItem.permissionCode)
        ? menuItem.permissionCode
        : [menuItem.permissionCode]
    )
  )
}
