import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertBrand from '@/modules/preference/brand/views/TheUpsertBrand.vue'
import { brandEndpoints } from '@/modules/preference/brand'
import { brandPreviewComponents } from '@/modules/preference/brand/helpers/previewComponents'

/** Brand module routes */
export const brandRoutes: RouteConfig[] = [
  {
    path: '/brand',
    name: 'brand',
    component: () =>
      import(
        /* webpackChunkName: "TheBrandChunk" */ '@/modules/preference/brand/views/TheBrand.vue'
      ),
    meta: {
      title: 'navigations.brandMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/brand/preview-details/:id',
    name: 'preview-brand',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewBrandChunk" */ '@/modules/preference/brand/views/ThePreviewBrand.vue'
      ),
    meta: {
      title: 'navigations.brandMetaTitle',
      parentRouteName: 'brand',
      logPage: {
        endpoint: brandEndpoints,
        previewDynamicComponents: brandPreviewComponents
      }
    }
  }
]
export const brandModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertBrand,
    persistent: true,
    name: 'edit-brand',
    requiredParams: ['id']
  },
  {
    component: TheUpsertBrand,
    persistent: true,
    name: 'add-brand',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-brand',
    requiredParams: ['id'],
    onDelete: q => brandEndpoints.delete(q.id.toString()),
    dialogTitle: 'brandTitle'
  }
]
