export enum ImgType {
  file = 'blob',
  imageUrl = 'image_url',
  both = 'both'
}
export enum ImgExtensionTypes {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  WEBP = 'image/webp'
}

export interface IImagesUploaded {
  imageFileOrString: File | string
  imageBlobOrString: Blob | string
}
