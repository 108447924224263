import { PreviewComponent } from '@/services/utils/basePreview'
import { ILocalization } from '@/modules/preference/localization/types/localizationTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { keyTranslationsTransformer } from '@/modules/preference/localization/helpers/keyTranslationsTransformer'

/**
 * dynamic component generator method
 * @param localization is ILocalization interface type which
 * @return An Array of IPreviewComponent localization type
 */
export function localizationPreviewComponents(
  localization: ILocalization
): PreviewComponent<ILocalization>[] {
  // transform keyTranslation array into a translation object based on their languageId
  localization = {
    ...localization,
    ...(localization.keyTranslations &&
      keyTranslationsTransformer(localization.keyTranslations))
  }

  return [
    PreviewComponent.labelValue<ILocalization>(
      'platform',
      'platform',
      localization.platform?.name
    ),
    PreviewComponent.labelValue<ILocalization>('key', 'key', localization.key),
    PreviewComponent.labelValue<ILocalization>(
      'english',
      'en',
      localization.en
    ),
    PreviewComponent.labelValue<ILocalization>(
      'kurdish',
      'ckb',
      localization.ckb
    ),
    PreviewComponent.labelValue<ILocalization>('arabic', 'ar', localization.ar),
    ...metadataLabelValues(localization)
  ]
}
