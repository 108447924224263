import { PreviewComponent } from '@/services/utils/basePreview'
import { IVariant } from '@/modules/inventory/variant/types/variantTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param variant is IVariant interface type which
 * @return An Array of IPreviewComponent variant type
 */
export function variantPreviewComponents(
  variant: IVariant
): PreviewComponent<IVariant>[] {
  return [
    PreviewComponent.labelValue<IVariant>('name', 'name', variant.name),
    ...metadataLabelValues(variant)
  ]
}
