/**
 * transform object into queries path
 * */
export const stringifyQueryObject = (
  queries: Record<string, string | number>
) => {
  return (
    (Object.keys(queries).length ? '?' : '') +
    Object.keys(queries)
      .map(key => `${key}=${queries[key]}`)
      .join('&')
  )
}
