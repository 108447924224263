import { ModelApi } from '@/services/api/modelApi'
import { IStockProfile } from '@/modules/seller-management/stock-profile/types/stockProfileTypes'

/**
 * Stock Profile endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class StockProfileEndpoints extends ModelApi<IStockProfile> {
  constructor() {
    super('stock_profile')
  }
}
