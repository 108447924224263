
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import { orderItemEndpoints } from '@/modules/order-management/order-items'
import {
  IOrderItems,
  ItemStatus
} from '@/modules/order-management/order-items/types/orderTypes'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { AnyObject } from '@/shared/types/builtInTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

@Component({
  name: 'SetStatus',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class SetStatus extends Vue {
  loading = false
  data = {}
  originalData = {}

  StatusTypeList: AnyObject[] = [
    {
      id: ItemStatus.PENDING,
      name: ContentFormatter.$customizeText(ItemStatus.PENDING)
    },
    {
      id: ItemStatus.PACKED,
      name: ContentFormatter.$customizeText(ItemStatus.PACKED)
    },
    {
      id: ItemStatus.PROCESSING,
      name: ContentFormatter.$customizeText(ItemStatus.PROCESSING)
    },
    {
      id: ItemStatus.BOOKED,
      name: ContentFormatter.$customizeText(ItemStatus.BOOKED)
    },
    {
      id: ItemStatus.DELIVERED,
      name: ContentFormatter.$customizeText(ItemStatus.DELIVERED)
    },
    {
      id: ItemStatus.CANCELLED,
      name: ContentFormatter.$customizeText(ItemStatus.CANCELLED)
    }
  ]

  async created() {
    await this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    try {
      if (id) this.data = await orderItemEndpoints.get(id)
      this.originalData = freezeObject(this.data)
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  fields(): Field<IOrderItems>[] {
    return [
      Field.dropdown<IOrderItems>('Status', 'itemStatus', () =>
        Promise.resolve(this.StatusTypeList)
      )
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
        .setCol('col-12'),
      Field.textarea<IOrderItems>('note', 'note')
        .setProps({
          autoGrow: true,
          rows: 3
        })
        .required()
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit() {
    const { id } = parsePathQueryString(location.search)
    if (id)
      await orderItemEndpoints
        .setStatusActions(id.toString(), this.data, this.originalData)
        .then(() => this.closePopUp())
        .finally(() => eventBus.refreshData())
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
