import Vue from 'vue'
import App from '@/App.vue'
import appRouter from '@/AppRouter'
import AppStore from '@/AppStore'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

// import format utility builtin functions to be able to use them anywhere in the app
import '@/shared/helpers/contentFormatter.ts'

// import axios configuration
import '@/plugins/axios'

// import vee validate
import '@/plugins/veeValidate'

// import v-currency-field
import '@/plugins/vCurrencyField'

// import v-phone-input
import '@/plugins/vPhoneInput'

import InlineSvg from 'vue-inline-svg'
import { trans } from '@/services/utils/translations'
import { authEndpoints } from '@/modules/login'

/* add inline component globally */
Vue.component('inline-svg', InlineSvg)
Vue.config.productionTip = false

/** fetch lang, refresh endpoints before any initial load */
;(async () => {
  const locale = localStorage.getItem('language') || 'en'
  // catch the error so that the compiler gets out of the await function
  await trans.changeLocale(locale).catch(error => error)
  await authEndpoints.refresh().catch(error => error)

  new Vue({
    router: appRouter,
    store: AppStore,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})()
