import { Module, VuexModule } from 'vuex-module-decorators'
import VueI18n from 'vue-i18n'
import { WeightUnitType } from '@/modules/inventory/product/types/productTypes'
import TranslateResult = VueI18n.TranslateResult

/**
 * Weight Unit vuex store module.
 */
@Module({ namespaced: true, name: 'weight' })
export default class WeightUnitStore extends VuexModule {
  weightUnitList: {
    text: string | TranslateResult
    value: string
  }[] = [
    {
      text: 'kilogram(kg)',
      value: WeightUnitType.kilogram
    },
    {
      text: 'gram(g)',
      value: WeightUnitType.gram
    },
    {
      text: 'pound(lb)',
      value: WeightUnitType.pound
    },
    {
      text: 'once(oz)',
      value: WeightUnitType.once
    }
  ]

  /**
   * Get weight unit list from vuex store.
   */
  get getWeightUnitList() {
    return this.weightUnitList
  }
}
