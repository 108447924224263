
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'PreviewLabelValue'
})
export default class PreviewLabelValue extends Vue {
  /**
   * Provide label to describe the value.
   */
  @Prop({ type: String, required: true })
  readonly label!: string
  /**
   * Provide value to the label.
   */
  @Prop({ required: true }) readonly value!: string | (() => string)
  /**
   * Adds a loading placeholder or skeleton while loading to handle the value.
   */
  @Prop({ type: Boolean, required: true, default: false })
  readonly loading!: boolean
  /**
   * Sets the default number of columns the component extends. Available options are 1 -> 12 and auto.
   */
  @Prop({ type: String, required: false }) readonly cols?: string
  /**
   * Sets the class to the value
   */
  @Prop({ type: String, required: false }) readonly valueClass?: string
  /**
   * Sets the class to the label
   */
  @Prop({ type: String, required: false }) readonly labelClass?: string

  /**
   * Sets the Link to the label
   */
  @Prop({ type: String, required: false }) readonly link?: string

  /**
   *  Translate label before given label in any new modules.
   */
  get translateLabel() {
    return this.label && typeof this.label === 'string'
      ? this.$t(`label.${this.label}`)
      : '-'
  }

  /**
   *  check if value is a function else return value.
   */
  get bindValue() {
    const value = this.value
    if (typeof value === 'function') {
      return value()
    } else if (!value) return '-'
    else {
      return value
    }
  }
}
