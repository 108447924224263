import { AnyObject, Id, IMetadata } from '@/shared/types/builtInTypes'
import { ISeller } from '@/modules/seller/types/sellerTypes'
import { IWorkflowManagementList } from '@/modules/seller-management/workflow-management/types/workflowManagementTypes'
import { IProduct } from '@/modules/inventory/product/types/productTypes'

export enum RequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REVISED = 'revised',
  REJECTED = 'rejected'
}

export const requestStatusColors: Record<RequestStatus | string, string> = {
  [RequestStatus.PENDING]: 'primary',
  [RequestStatus.APPROVED]: 'success',
  [RequestStatus.REVISED]: 'info',
  [RequestStatus.REJECTED]: 'error'
}

export const requestStatusText: Record<RequestStatus | string, string> = {
  [RequestStatus.PENDING]: 'Pending',
  [RequestStatus.APPROVED]: 'Approve',
  [RequestStatus.REVISED]: 'Revise',
  [RequestStatus.REJECTED]: 'Reject'
}

export enum ActionTypes {
  BecomeSeller = 'Become Seller',
  AddDiscount = 'Add Discount',
  ImportProduct = 'Import Product',
  ImportStockAndPrice = 'Import Stock And Price',
  AddProduct = 'Add Product',
  EditProduct = 'Edit Product',
  DeleteProduct = 'Delete Product',
  EditStockAndPrice = 'Edit Stock And Price'
}

export interface ISellerRequest extends IMetadata {
  id: Id
  name: string
  type: ActionTypes
  code: string
  status: RequestStatus
  workflowId: Id
  workflow: IWorkflowManagementList
  nextElementIds: string[]
  requestedBy: string
  requestedDate: string | Date
  payload: ISeller | AnyObject
  note: string
  statusTracking: {
    id: Id
    status: RequestStatus
    note: string
    date: string | Date
  }[]
  newResponse: ISeller | IProduct | AnyObject
  oldResponse: ISeller | IProduct | AnyObject
}

export interface requestStatusPayload {
  status: RequestStatus
  note: string
}
