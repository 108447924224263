import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import { discountEndpoints } from '@/modules/inventory/discount'
import Activation from '@/modules/inventory/discount/components/Activation.vue'
import { discountPreviewComponents } from '@/modules/inventory/discount/helpers/previewComponents'

/**
 * Discount module routes
 */
export const discountRoutes: RouteConfig[] = [
  {
    path: '/inventory/discount',
    name: 'discount',
    component: () =>
      import(
        /* webpackChunkName: "TheStaffChunk" */ '@/modules/inventory/discount/views/TheDiscount.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.discountMetaTitle'
    }
  },
  {
    path: '/inventory/discount/preview-details/:id',
    name: 'preview-discount',
    component: () =>
      import(
        /*ThePreviewDiscountChunk*/ '@/modules/inventory/discount/views/ThePreviewDiscount.vue'
      ),
    meta: {
      title: 'navigations.discountMetaTitle',
      parentRouteName: 'discount',
      logPage: {
        endpoint: discountEndpoints,
        previewDynamicComponents: discountPreviewComponents
      }
    }
  },
  {
    path: '/inventory/discount/upsert-discount',
    name: 'upsert-discount',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertDiscount" */ '@/modules/inventory/discount/views/TheUpsertDiscount.vue'
      ),
    meta: {
      title: 'navigations.discountMetaTitle',
      parentRouteName: 'discount'
    }
  }
]

export const discountModalRoute: PopUpRouteConfig[] = [
  {
    component: Activation,
    persistent: true,
    name: 'activation',
    requiredParams: ['id']
  }
]
