import { WorkflowManagementEndpoints } from '@/modules/seller-management/workflow-management/api/workflowManagementEndpoints'
import { IWorkflowManagement } from '@/modules/seller-management/workflow-management/types/workflowManagementTypes'

/**
 * Workflow Management endpoint instance
 * instantiate Workflow Management api class with a different api path
 */
export const workflowManagementEndpoints =
  new WorkflowManagementEndpoints<IWorkflowManagement>(
    'workflow',
    'maker_checker'
  )

export const workflowManagementListEndpoints =
  new WorkflowManagementEndpoints<IWorkflowManagement>(
    'workflow/workflow_list',
    'maker_checker'
  )
