import formatDate from 'intl-dateformat'
import { getCurrencySymbol } from '@/shared/helpers/getCurrencySymbol'

// interface IContentFormatter {
//   // used to handle typescript compiler for builtin methods for string
//   $_formatToTime(value?: Date | string): string
//
//   $_formatToDate(value?: Date | string): string
//
//   $_formatToDateTime(value?: Date | string): string
//   // used to handle typescript compiler for builtin methods for numbers
//   $_formatToCurrency(value?: number | string, minFraction?: number): string
//
//   // used to handle typescript compiler for builtin methods for numbers
//   $_toBoolean(value?: string): boolean
// }

/*
 * A class to format string tests into Date or currency or boolean
 * */
export class ContentFormatter {
  // The currency prototype function number type.
  // Return a string of comma separated by given fraction or default fraction (4).
  // E.g., 4123.456789 to 4,123.4568
  static $formatToCurrency(
    value?: number | string | undefined,
    isSymbol = false,
    minFraction = 4
  ): string | undefined {
    if (value == undefined || value == null) return value

    const num = Number(value)
    if (isNaN(num)) {
      throw Error('This variable is not a number. Please, check your value.')
    } else {
      const currencySymbol = getCurrencySymbol()
      const oldValue = num.toFixed(minFraction)

      const formattedValue = oldValue
        .replace(/(\d)(?=(\d{3})+(?!\d)[.])/g, '$1,')
        .replace(/\.?0+$/, '')

      return isSymbol
        ? `${currencySymbol} ${formattedValue}`
        : `${formattedValue}`
    }
  }

  // The date Prototype string type must in the format of ISO8601
  // e.g., 14:48:00.000+09:00
  static $formatToTime(value?: Date | string) {
    if (value)
      if (value instanceof Date) {
        return formatDate(value, 'hh:mm A')
      } else {
        return formatDate(new Date(Date.parse(value.toString())), 'hh:mm A')
      }
    return value
  }

  // The date Prototype string type must in the format of ISO8601
  // e.g., 2011-10-10T
  static $formatToDate(value?: Date | string, format = 'DD/MM/YYYY') {
    if (value)
      if (value instanceof Date) {
        return formatDate(value, format)
      } else {
        return formatDate(new Date(Date.parse(value)), format)
      }
    return value
  }

  // The date Prototype string type must in the format of ISO8601
  // e.g., 2011-10-10T14:48:00.000+09:00
  static $formatToDateTime(
    value?: string | Date,
    format = 'DD/MM/YYYY hh:mm A'
  ) {
    if (value)
      if (value instanceof Date) {
        return formatDate(value, format)
      } else {
        return formatDate(new Date(Date.parse(value)), format)
      }
    return value
  }

  static $toBoolean(value?: string) {
    if (value)
      switch (value) {
        case 'true':
        case '1':
        case 'on':
        case 'yes':
          return true
        default:
          return false
      }
    return value
  }

  //Replace any underscore with space and capitalize all words of a string
  static $customizeText(value?: string): string {
    if (typeof value !== 'string' || !value) return ''
    if (value)
      return value.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase()
      })
    return ''
  }

  //Format value to percentage by multiplied value with 100
  static $formatToPercentage(
    value?: number | string,
    isSymbol = false
  ): string | undefined {
    if (value == undefined || value == null) return value

    const percentageSymbol = ' %'
    const num = Number(value)

    if (isNaN(num)) {
      throw Error('This variable is not a number. Please, check your value.')
    } else {
      const formattedValue = parseFloat((num * 100).toFixed(4)).toString()
      return isSymbol
        ? `${formattedValue} ${percentageSymbol}`
        : `${formattedValue}`
    }
  }
}
