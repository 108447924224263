
import { Component, Vue } from 'vue-property-decorator'
import { popUpRoute } from '@/services/utils/popupRoute'
import {
  IOrderItemDetails,
  IOrderItems
} from '@/modules/order-management/order-items/types/orderTypes'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString' //@ts-ignore
import VueBarcode from 'vue-barcode'
import CustomButtons from '@/stories/custom-buttons/CustomButtons.vue'
import { IButtons } from '@/shared/types/baseButton'
import Print from '@/services/utils/print'
import { orderItemEndpoints } from '@/modules/order-management/order-items'
import PreviewLabelValue from '@/stories/preview-label-value/PreviewLabelValue.vue'

@Component({
  name: 'TheBarCodeOrder',
  components: {
    PreviewLabelValue,
    CustomButtons,
    barcode: VueBarcode,
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class TheBarCodeOrder extends Vue {
  id = parsePathQueryString(location.search).id || ''

  orderItemDetails = [] as IOrderItemDetails[]
  data = {} as IOrderItems
  loading = true
  print = new Print()

  get printButton(): IButtons {
    return {
      text: 'print',
      props: {
        color: 'primary'
      },
      permit: true,
      onClick: () => this.print.$_print()
    }
  }

  get expandedBarcodeId(): string {
    return this.generateExpandedId(this.id)
  }

  generateExpandedId(id: string): string {
    // This is a simple example. You might want to use a more sophisticated algorithm.
    const prefix = '9876210'
    const suffix = '0124589'
    const paddedId = id.padStart(6, '0')
    return `${prefix}${paddedId}${suffix}`
  }

  async created() {
    await this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    this.loading = true
    try {
      if (this.id) {
        this.data = await orderItemEndpoints.get(this.id)
        this.orderItemDetails = [
          {
            text: this.$t('label.customerName'),
            value: this.data?.customerFullName
          },
          {
            text: this.$t('label.customerID'),
            value: this.data?.customerId
          },
          {
            text: this.$t('label.customerPhoneNumber'),
            value: this.data?.customerPhone
          },
          {
            text: this.$t('label.paymentStatus'),
            value: this.data?.paymentOptionName
          },
          {
            text: this.$t('label.storeName'),
            value: this.data?.storeName
          }
        ]
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
