import {
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators'
import {
  IUser,
  IUserPermissions
} from '@/modules/force-change-password/types/ForceChangePasswordType'
import { trans } from '@/services/utils/translations'

/**
 * Auth vuex module.
 */
@Module({ namespaced: true, name: 'auth' })
export default class LoginStore extends VuexModule {
  isAuthenticated = false
  expiryToken: number | null = null
  forceChangePassword = false
  meInfo = {} as IUser
  userPermissions: IUserPermissions[] = []

  /**
   * @returns a string - currencySymbol from API or default is IQD
   */
  get getCurrencySymbol(): string {
    // fetch user symbol from api (meInfo)
    return 'IQD'
  }

  /**
   * Get login me from vuex store.
   */
  get getIsAuthenticated(): boolean {
    return this.isAuthenticated
  }

  /**
   * Get login me from vuex store.
   */
  get getForceChangePassword(): boolean {
    return this.forceChangePassword
  }

  /**
   * Get expireToken from vuex store.
   */
  get getExpiryToken() {
    return this.expiryToken
  }

  /**
   * Get me Info from vuex store
   * */
  get getMeInfo() {
    return this.meInfo
  }

  /**
   * Get me Info from vuex store
   * */
  get isRTL() {
    if (this.meInfo.languageId && this.meInfo.languageId !== 1) return true
    else {
      const local = localStorage.getItem('language') ?? 'en'
      return local !== 'en'
    }
  }

  /**
   * Get permissions from vuex store
   * */

  get getUserPermissions() {
    return this.userPermissions
  }

  @Mutation
  setAuthentication(setAuth: boolean) {
    this.isAuthenticated = setAuth
  }

  @Mutation
  setForceChangePassword(isChangePassword: boolean) {
    this.forceChangePassword = isChangePassword
  }

  @Mutation
  setExpiryToken(expiryToken: number) {
    this.expiryToken = expiryToken
  }

  @Mutation
  setMeInfo(meInfo: IUser) {
    // change language depends on the user selections
    // LanguageId starts from 1 not 0 as we have in supportedLocales ,so we do a minus one
    if (
      trans.supportedLocales[(meInfo.languageId as number) - 1] !==
      trans.currentLocale
    ) {
      const locale =
        trans.supportedLocales &&
        trans.supportedLocales[(meInfo.languageId as number) - 1]
      if (locale) trans.changeLocale(locale || '0').finally()
    }
    this.meInfo = meInfo
  }

  // @Mutation
  // setUserPermissions(user: IUserPermissions[]) {
  //   this.userPermissions = user
  //   }
  @MutationAction({ mutate: ['userPermissions'] })
  async setUserPermissions(userPermissions: IUserPermissions[]) {
    return { userPermissions }
  }
}
