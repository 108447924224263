import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { roleEndpoints } from '@/modules/preference/role'
import TheUpsertRoleManagement from '@/modules/preference/role/views/TheUpsertRoleManagement.vue'
import { rolesPreviewComponents } from '@/modules/preference/role/helpers/previewComponents'

/**
 * Roles module routes
 */
export const roleRoutes: RouteConfig[] = [
  {
    path: '/roles',
    name: 'roles',
    component: () =>
      import(
        /* webpackChunkName: "TheRoleManagementChunk" */ '@/modules/preference/role/views/TheRoleManagement.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.roleManagementMetaTitle'
    }
  },
  {
    path: '/roles/preview-details/:id',
    name: 'preview-roles',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewRoleManagementChunk" */ '@/modules/preference/role/views/ThePreviewRoleManagement.vue'
      ),
    meta: {
      title: 'navigations.roleManagementMetaTitle',
      parentRouteName: 'roles',
      logPage: {
        endpoint: roleEndpoints,
        previewDynamicComponents: rolesPreviewComponents
      }
    }
  }
]
export const roleModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertRoleManagement,
    size: 600,
    persistent: true,
    name: 'edit-role',
    requiredParams: ['id']
  },
  {
    component: TheUpsertRoleManagement,
    size: 600,
    persistent: true,
    name: 'add-role',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-role',
    requiredParams: ['id'],
    onDelete: q => roleEndpoints.delete(q.id.toString()),
    dialogTitle: 'roleTitle'
  }
]
