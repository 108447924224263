
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { IProduct } from '@/modules/inventory/product/types/productTypes'
import { popUpRoute } from '@/services/utils/popupRoute'
import { productEndpoints } from '@/modules/inventory/product'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import eventBus from '@/eventBus'
import PreviewAvatar from '@/stories/preview-avatar/PreviewAvatar.vue'
import { sellerBranchEndpoints } from '@/modules/seller'
import { freezeObject } from '@/shared/helpers/freezeObject'

@Component({
  name: 'SetBranch',
  components: {
    PreviewAvatar,
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class SetBranch extends Vue {
  loading = false
  data = {} as IProduct
  originalData = {} as IProduct

  async created() {
    await this.fetchRemoteData()
  }

  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)
    this.loading = true
    if (id)
      try {
        this.data = await productEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      } catch (error) {
        throw new Error(`Failed to fetch form data. Due to ${error}`)
      } finally {
        this.loading = false
      }
    else throw new Error('Please, Provide User ID ')
  }

  fields(productModule: IProduct): Field<IProduct>[] {
    return [
      Field.dropdown<IProduct>('branches', 'merchantBranchIds', () =>
        sellerBranchEndpoints.list({
          params: {
            merchantId: productModule?.merchantId
          }
        })
      )
        .setProps({
          clearable: true,
          multiple: true,
          closableChips: true,
          chips: true
        })
        .setHidden(!productModule.merchantId)
        .required()
        .setCol('col-12')
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: Pick<IProduct, 'merchantBranchIds'>) {
    const { id } = parsePathQueryString(location.search)

    try {
      if (id)
        await productEndpoints
          .setUpdateBranch(id.toString(), values, this.originalData)
          .then(() => this.closePopUp())
    } catch {
      throw new Error('Please, Provide id')
    } finally {
      eventBus.refreshData()
    }
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
