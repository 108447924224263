import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IPaymentOption } from '@/modules/accounting/payment-option/types/paymentOptionTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { mergeAmountWithType } from '@/shared/helpers/amountWithAmountType'

/**
 * dynamic component generator method
 * @param paymentOption is IPaymentOption interface type which
 * @return An Array of IPreviewComponent payment Option type
 */
export function paymentOptionPreviewComponents(
  paymentOption: IPaymentOption
): PreviewComponent<IPaymentOption>[] {
  return [
    PreviewComponent.photo<IPaymentOption>('logo', paymentOption.logo),
    PreviewComponent.labelValue<IPaymentOption>(
      'paymentName',
      'name',
      paymentOption.name
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'paymentType',
      'type',
      ContentFormatter.$customizeText(paymentOption.type)
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'allowedPaymentTypes',
      'allowedPaymentTypes',
      formatAllowedTypes(paymentOption.allowedPaymentTypes)
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'apiConfigId',
      'apiConfigId',
      paymentOption.apiConfig?.name
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'paymentOptionFee',
      'paymentOptionFee',
      paymentOption.paymentOptionFee?.label
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'paymentOptionFeeAmount',
      'paymentOptionFee',
      mergeAmountWithType(paymentOption.paymentOptionFee)
    ),
    PreviewComponent.labelValue<IPaymentOption>(
      'currency',
      'currencyId',
      paymentOption.currency?.name
    ),
    ...metadataLabelValues(paymentOption)
  ]
}

/**
 * @param item - an array of string to be joined
 * @return string - join allowed payments types with "," separator
 */
function formatAllowedTypes(item: string[]): string {
  const joinItems = item ? item.join(', ') : ''
  return ContentFormatter.$customizeText(joinItems)
}
