import { ModelApi } from '@/services/api/modelApi'
import {
  IDiscount,
  IProductVariants
} from '@/modules/inventory/discount/types/discountTypes'
import { Id } from '@/shared/types/builtInTypes'
import axios from 'axios'
import QueryString from 'qs'
import { getDiff } from '@/shared/helpers/getDifference'
import { AmountType } from '@/modules/accounting/transaction-fee/types/transactionFeeTypes'

/**
 * Discount endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class DiscountEndpoints extends ModelApi<IDiscount> {
  constructor() {
    super('discount')
  }

  setStatus(id: Id, data = {}, originalData = {}) {
    return new Promise((resolve, reject) => {
      const differences = getDiff(originalData, data)

      // if there was no change, just return a success promise
      if (!differences) {
        this.responseNotify('notifications.nothingChanged', 'error')
        return reject('No changed value')
      }

      let newData = {}
      if (differences) {
        newData = ModelApi.rearrangeData(differences, false, false)
      }

      if (id <= 0 || newData === undefined) {
        // this.responseNotify('notifications.id0', 'error')
        return reject('Id must be greater than 0')
      }

      axios
        .post(`discount/${id}/set_discount_activation/`, newData)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  importProductVariants(
    params: Pick<IDiscount, 'amountType' | 'amount' | 'merchantId'>,
    file: File
  ) {
    const formData = new FormData()
    formData.append('merchant_id', params.merchantId as string)
    formData.append('file', file)

    return new Promise((resolve, reject) => {
      axios
        .post(
          `discount/import_discount/?discount_rate=${params.amount}&amount_type=${params.amountType}`,
          formData
        )
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Get all products after depends on params will filter the data
   * Display the data inside simple-table component
   * @param params
   */
  async getDiscountProductVariants(params: {
    discountRate?: number
    amountType?: string
    brandIds?: Id[]
    categoryIds?: Id[]
    productIds?: Id[]
  }): Promise<IProductVariants[]> {
    // Parse the query string
    const dat = {
      ...params,
      discountRate:
        params?.discountRate && params.amountType === AmountType.percentage
          ? params?.discountRate / 100
          : params?.discountRate
    }

    const decodedParams = QueryString.parse(
      QueryString.stringify(dat, {
        arrayFormat: 'comma'
      })
    )

    return new Promise((resolve, reject) => {
      axios
        .get('discount/product_variants/', {
          params: decodedParams
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** get product variant */
  async getProductVariant(params = {}): Promise<IProductVariants[]> {
    // Parse the query string
    const decodedParams = QueryString.parse(
      QueryString.stringify(params, {
        arrayFormat: 'comma'
      })
    )
    return axios
      .get(`${this.getUrl()}product_variants/`, {
        params: decodedParams
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
        throw error.response.data
      })
  }
}
