import { Id } from '@/shared/types/builtInTypes'

export enum ExportDialogName {
  PRODUCT = 'export-product',
  STOCK = 'export-stock_and_price'
}

export enum CeleryApiType {
  PRODUCT = 'product',
  STOCK = 'stock_and_price',
  REST_API = 'rest_api'
}

export const celeryApiTypeWord: Record<CeleryStatus | string, string> = {
  [CeleryApiType.PRODUCT]: 'Product',
  [CeleryApiType.STOCK]: 'Stock and Price',
  [CeleryApiType.REST_API]: 'API-Configuration'
}

export enum CeleryStatus {
  FAILURE = 'FAILURE',
  RECEIVED = 'RECEIVED',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  REVOKED = 'REVOKED',
  RETRY = 'RETRY'
}

export const celeryStatusColors: Record<CeleryStatus | string, string> = {
  [CeleryStatus.FAILURE]: 'error',
  [CeleryStatus.RECEIVED]: 'info',
  [CeleryStatus.SUCCESS]: 'success',
  [CeleryStatus.PENDING]: 'primary',
  [CeleryStatus.STARTED]: 'secondary',
  [CeleryStatus.REVOKED]: '#fc5370',
  [CeleryStatus.RETRY]: 'section'
}

export interface ICeleryTask {
  id: Id
  taskId: string
  taskName: string
  status: string
  result: {
    excType: string
    excMessage: [
      {
        detail: string
        row: number
      }
    ]
    excModule: string
  }
  dateCreated: string | Date
  dateDone: string | Date
  createdById: Id
  createdBy: string
}
