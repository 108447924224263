import Vue from 'vue'
// v-phone-input:  https://github.com/paul-thebaud/v-phone-input/tree/2.x.x
import { createVPhoneInput } from 'v-phone-input'

import { VAutocomplete } from 'vuetify/lib'
import i18n from '@/plugins/i18n'
// Globally import VAutocomplete, IMPORTANT: required when tree shaking Vuetify components.
Vue.component('VAutocomplete', VAutocomplete)

const vPhoneInput = createVPhoneInput({
  countryLabel: `${i18n.t('label.code')}`
})

// Configure VPhoneInput library.
Vue.use(vPhoneInput)
