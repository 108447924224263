import { PreviewComponent } from '@/services/utils/basePreview'
import { ICustomerAddress } from '@/modules/customer/types/customerTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import i18n from '@/plugins/i18n'

/**
 * dynamic component generator method
 * @param  customerAddress is ICustomerAddress interface type which
 * @return An Array of IPreviewComponent  customerAddress type
 */
export function customerAddressPreviewLogComponents(
  customerAddress: ICustomerAddress
): PreviewComponent<ICustomerAddress>[] {
  return [
    PreviewComponent.labelValue<ICustomerAddress>(
      'addressTitle',
      'addressTitle',
      customerAddress.addressTitle
    ).setTitle('customerAddress'),
    PreviewComponent.labelValue<ICustomerAddress>(
      'city',
      'cityId',
      customerAddress.city?.name
    ),
    PreviewComponent.labelValue<ICustomerAddress>(
      'location',
      'location',
      customerAddress.location
    ),
    PreviewComponent.labelValue<ICustomerAddress>(
      'phone',
      'phone',
      customerAddress.phone
    ),
    PreviewComponent.labelValue<ICustomerAddress>(
      'preferredAddress',
      'preferredAddress',
      customerAddress.preferredAddress ? i18n.t('label.preferred') : ''
    ).setClass(customerAddress.preferredAddress ? 'success--text' : ''),
    PreviewComponent.labelValue<ICustomerAddress>(
      'availability',
      'isDeleted',
      customerAddress.isDeleted
        ? i18n.t('label.deleted')
        : i18n.t('label.available')
    ).setClass(customerAddress.isDeleted ? 'error--text' : ''),
    ...metadataLabelValues(customerAddress)
  ]
}
