/**
 * Payment Option endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
import { ModelApi } from '@/services/api/modelApi'
import { IPaymentOption } from '@/modules/accounting/payment-option/types/paymentOptionTypes'
import axios from 'axios'

export class PaymentOptionEndpoints extends ModelApi<IPaymentOption> {
  constructor() {
    super('payment_option')
  }

  setPaymentOptionOrder(itemsId: Array<number>) {
    return axios
      .post(`${this.getUrl()}reorder_payment_option/`, {
        paymentOptionIds: itemsId
      })
      .then(response => {
        this.responseNotify('notifications.updatedSuccessfully')
        return response.data.data
      })
      .catch(error => {
        this.responseNotify(error.response.data, 'error')
        throw error.response.data
      })
  }
}
