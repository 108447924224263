import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import {
  staffModalRoute,
  staffRoutes
} from '@/modules/staff/routes/staffRoutes'
import {
  cityModalRoute,
  cityRoutes
} from '@/modules/preference/city/routes/cityRoutes'
import { profileRoutes } from '@/modules/profile/routes/profileRoutes'
import VueRouterPrefetch from 'vue-router-prefetch'
import { popUpRoute } from '@/services/utils/popupRoute'
import logRoute from '@/services/utils/logRoute'
import { loginRoutes } from '@/modules/login/routes/loginRoutes'
import { forceChangePasswordRoutes } from '@/modules/force-change-password/routes/forceChangePwRoutes'
import i18n from '@/plugins/i18n'
import {
  customerModalRoute,
  customerRoutes
} from '@/modules/customer/routes/customerRoutes'
import {
  apiIntegrationModalRoute,
  apiIntegrationRoutes
} from '@/modules/preference/api-integration/routes/apiIntegrationRoutes'
import {
  variantModalRoute,
  variantRoutes
} from '@/modules/inventory/variant/routes/variantRoutes'
import {
  discountModalRoute,
  discountRoutes
} from '@/modules/inventory/discount/routes/discountRoutes'
import {
  categoryModalRoute,
  categoryRoutes
} from '@/modules/inventory/category/routes/categoryRoutes'
import {
  roleModalRoute,
  roleRoutes
} from '@/modules/preference/role/routes/roleRoutes'
import {
  localizationModalRoute,
  localizationRoutes
} from '@/modules/preference/localization/routes/localizationRoutes'
import { taxRoutes } from '@/modules/accounting/tax/routes/taxRoutes'
import appStore from '@/AppStore'
import { getBreadcrumbRoutes } from '@/services/utils/serviceRoutes'
import {
  currencyRateModalRoute,
  currencyRateRoutes
} from '@/modules/accounting/currency-rate/routes/currencyRateRoutes'
import {
  orderItemModalRoute,
  orderItemRoutes
} from '@/modules/order-management/order-items/routes/orderItemRoutes'
import {
  paymentOptionModalRoute,
  paymentOptionRoutes
} from '@/modules/accounting/payment-option/routes/paymentOptionRoutes'
import {
  sellerModalRoute,
  sellerRoutes
} from '@/modules/seller/routes/sellerRoutes'
import {
  productModalRoute,
  productRoutes
} from '@/modules/inventory/product/routes/productRoutes'
import {
  stockModalRoute,
  stockRoutes
} from '@/modules/inventory/stock/routes/stockRoutes'
import {
  transactionFeeModalRoute,
  transactionFeeRoutes
} from '@/modules/accounting/transaction-fee/routes/transactionFeeRoutes'
import {
  collectionModalRoute,
  collectionRoutes
} from '@/modules/page/collection/routes/collectionRoutes'
import { pageRoutes } from '@/modules/page/page/routes/pageRoutes'
import {
  denominationModalRoute,
  denominationRoutes
} from '@/modules/arbela-gift-card/denomination/routes/denominationRoutes'
import {
  batchModalRoute,
  batchRoutes
} from '@/modules/arbela-gift-card/batch/routes/batchRoutes'
import {
  giftCardModalRoute,
  giftCardRoutes
} from '@/modules/arbela-gift-card/gift-card/routes/giftCardRoutes'
import { settingRoutes } from '@/modules/preference/setting/routes/settingRoutes'
import {
  weightEstimationModalRoute,
  weightEstimationRoutes
} from '@/modules/inventory/weight-estimation/routes/weightEstimationRoutes'
import {
  brandModalRoute,
  brandRoutes
} from '@/modules/preference/brand/routes/brandRoutes'
import {
  stockProfileModalRoute,
  stockProfileRoutes
} from '@/modules/seller-management/stock-profile/routes/stockProfileRoutes'
import {
  escalationProfileModalRoute,
  escalationProfileRoutes
} from '@/modules/seller-management/escalation-profile/routes/escalationProfileRoutes'

import {
  pricingLimitProfileModalRoute,
  pricingLimitProfileRoutes
} from '@/modules/seller-management/pricing-limit-profile/routes/pricingLimitProfileRoutes'
import {
  discountProfileModalRoute,
  discountProfileRoutes
} from '@/modules/seller-management/discount-profile/routes/discountProfileRoutes'
import {
  countryModalRoute,
  countryRoutes
} from '@/modules/seller-management/country/routes/countryRoutes'
import {
  workflowManagementModalRoute,
  workflowManagementRoutes
} from '@/modules/seller-management/workflow-management/routes/workflowManagementRoutes'
import {
  workflowActionModalRoute,
  workflowActionRoutes
} from '@/modules/seller-management/workflow-action/routes/workflowActionRoutes'
import {
  sellerRequestModalRoute,
  sellerRequestRoutes
} from '@/modules/request/seller-request/routes/sellerRequestRoutes'
import {
  estimationArrivalModalRoute,
  estimationArrivalRoutes
} from '@/modules/seller-management/estimation-arrival/routes/estimationArrivalRoutes'
import {
  internationalLogisticModalRoute,
  internationalLogisticRoutes
} from '@/modules/seller-management/international-logistic/routes/internationalLogisticRoutes'
import {
  returnedOrderRoutes,
  returnOrderModalRoute
} from '@/modules/order-management/returned-order/routes/returnedOrderRoutes'
import { localOrderRoutes } from '@/modules/order-management/local-orders/routes/localOrderRoutes'
import { homeRoutes } from '@/modules/home/routes/homeRoutes'
import {
  productVariantModalRoute,
  productVariantRoutes
} from '@/modules/inventory/product-variant/routes/productVariantRoutes'
import {
  commissionModalRoute,
  commissionRoutes
} from '@/modules/accounting/commission/routes/commissionRoutes'

Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)

const routes: Array<RouteConfig> = [
  ...loginRoutes,
  ...forceChangePasswordRoutes,
  ...homeRoutes,
  ...staffRoutes,
  ...apiIntegrationRoutes,
  ...cityRoutes,
  ...profileRoutes,
  ...customerRoutes,
  ...roleRoutes,
  ...variantRoutes,
  ...discountRoutes,
  ...localizationRoutes,
  ...categoryRoutes,
  ...taxRoutes,
  ...currencyRateRoutes,
  ...orderItemRoutes,
  ...paymentOptionRoutes,
  ...sellerRoutes,
  ...productRoutes,
  ...productVariantRoutes,
  ...stockRoutes,
  ...transactionFeeRoutes,
  ...collectionRoutes,
  ...pageRoutes,
  ...denominationRoutes,
  ...batchRoutes,
  ...giftCardRoutes,
  ...weightEstimationRoutes,
  ...settingRoutes,
  ...brandRoutes,
  ...pricingLimitProfileRoutes,
  ...stockProfileRoutes,
  ...escalationProfileRoutes,
  ...discountProfileRoutes,
  ...countryRoutes,
  ...workflowActionRoutes,
  ...workflowManagementRoutes,
  ...sellerRequestRoutes,
  ...estimationArrivalRoutes,
  ...internationalLogisticRoutes,
  ...localOrderRoutes,
  ...returnedOrderRoutes,
  ...commissionRoutes,
  {
    path: '*',
    name: 'notFound',
    component: () =>
      import(
        /*webpackChunkName: NotFound*/ '@/core/components/NotFoundPage.vue'
      ),
    meta: {
      title: 'navigations.notFound'
    }
  }
]

// register or add modal route
popUpRoute.register(
  ...orderItemModalRoute,
  ...staffModalRoute,
  ...cityModalRoute,
  ...apiIntegrationModalRoute,
  ...customerModalRoute,
  ...variantModalRoute,
  ...discountModalRoute,
  ...roleModalRoute,
  ...localizationModalRoute,
  ...categoryModalRoute,
  ...currencyRateModalRoute,
  ...paymentOptionModalRoute,
  ...sellerModalRoute,
  ...productModalRoute,
  ...productVariantModalRoute,
  ...transactionFeeModalRoute,
  ...stockModalRoute,
  ...collectionModalRoute,
  ...denominationModalRoute,
  ...batchModalRoute,
  ...giftCardModalRoute,
  ...weightEstimationModalRoute,
  ...brandModalRoute,
  ...pricingLimitProfileModalRoute,
  ...stockProfileModalRoute,
  ...escalationProfileModalRoute,
  ...discountProfileModalRoute,
  ...countryModalRoute,
  ...workflowActionModalRoute,
  ...workflowManagementModalRoute,
  ...sellerRequestModalRoute,
  ...estimationArrivalModalRoute,
  ...internationalLogisticModalRoute,
  ...commissionModalRoute,
  ...returnOrderModalRoute
)

const appRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// register log routes
logRoute.registerLogRoute(appRouter, routes)

//Register all routes include log route too for breadcrumbs for dynamical purpose
getBreadcrumbRoutes(logRoute.getAllRoutes())

appRouter.beforeEach((to, _from, next) => {
  if (to.meta && to.meta.title) {
    document.title = i18n.t(to.meta.title).toString()
  }
  // if no title in to.meta.title found, just display Arbela
  else document.title = i18n.t('footer.company').toString()

  // Remove trailing slash from url
  if (to.path !== '/' && to.path[to.path.length - 1] === '/') {
    const newPath = to.path.slice(0, -1)
    return next(newPath)
  } else next()

  if (
    to.path === '/force-change-password' &&
    !appStore.getters['auth/getForceChangePassword']
  ) {
    next('/')
  }
})

export default appRouter
