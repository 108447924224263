import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import { staffEndpoints } from '@/modules/staff'
import { staffPreviewComponents } from '@/modules/staff/helpers/previewComponents'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import AssignRole from '@/modules/staff/components/AssignRole.vue'
import ResetPassword from '@/modules/staff/components/ResetPassword.vue'

/**
 * Staff module routes
 */
export const staffRoutes: RouteConfig[] = [
  {
    path: '/staff',
    name: 'staff',
    component: () =>
      import(
        /* webpackChunkName: "TheStaffChunk" */ '@/modules/staff/views/TheStaff.vue'
      ),
    meta: {
      title: 'navigations.staffMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/staff/preview-details/:id',
    name: 'preview-staff',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewStaffChunk" */ '@/modules/staff/views/ThePreviewStaff.vue'
      ),
    meta: {
      title: 'navigations.staffMetaTitle',
      parentRouteName: 'staff',
      logPage: {
        endpoint: staffEndpoints,
        previewDynamicComponents: staffPreviewComponents
      }
    }
  },
  {
    path: '/staff/upsert-staff/:id?',
    name: 'upsert-staff',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertStaff" */ '@/modules/staff/views/TheUpsertStaff.vue'
      ),
    meta: {
      title: 'navigations.staffMetaTitle',
      parentRouteName: 'staff'
    }
  }
]

export const staffModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-staff',
    requiredParams: ['id'],
    onDelete: q => staffEndpoints.delete(q.id.toString()),
    dialogTitle: 'staffTitle'
  },
  {
    component: ResetPassword,
    persistent: true,
    name: 'reset-password',
    requiredParams: ['id']
  },
  {
    component: AssignRole,
    persistent: true,
    name: 'assign-role',
    requiredParams: ['id']
  }
]
