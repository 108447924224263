/**
 * Escape with predefined object
 * @param element
 */
export default function escapeHTMLElement(element: string): string {
  const escaped: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
    '"': '&quot;'
  }
  return element.replace(/[&<>'"]/g, function (m) {
    return escaped[m]
  })
}

/**
 * Escape with predefined object
 * with predefined object specific
 * // for HTML entities only
 * @param text
 */

export function unescapeHTMLElement(text: string): string {
  const re = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g
  const unescaped: Record<string, string> = {
    '&amp;': '&',
    '&#38;': '&',
    '&lt;': '<',
    '&#60;': '<',
    '&gt;': '>',
    '&#62;': '>',
    '&apos;': "'",
    '&#39;': "'",
    '&quot;': '"',
    '&#34;': '"'
  }
  return text.replace(re, function (m) {
    return unescaped[m]
  })
}
