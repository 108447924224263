import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { IBrand } from '@/modules/preference/brand/type/brandTypes'

/**
 * dynamic component generator method
 * @param brand is IBrand interface type which
 * @return An Array of IPreviewComponent brand type
 */
export function brandPreviewComponents(
  brand: IBrand
): PreviewComponent<IBrand>[] {
  return [
    PreviewComponent.photo<IBrand>('image', brand.image),
    PreviewComponent.labelValue<IBrand>('brandName', 'name', brand.name),
    ...metadataLabelValues(brand)
  ]
}
