import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { productEndpoints } from '@/modules/inventory/product'
import { productPreviewComponents } from '@/modules/inventory/product/helpers/previewComponents'
import SetBranch from '@/modules/inventory/product/components/SetBranch.vue'
import BulkAction from '@/modules/inventory/product/components/BulkAction.vue'
import ExportFile from '@/modules/celery-task/components/ExportFile.vue'
import ImportFile from '@/modules/celery-task/components/ImportFile.vue'

/** Product module routes */
export const productRoutes: RouteConfig[] = [
  {
    path: '/inventory/product',
    name: 'product',
    component: () =>
      import(
        /*TheProductChunk*/ '@/modules/inventory/product/views/TheProduct.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.productMetaTitle'
    }
  },
  {
    path: '/inventory/product/celery-product/:celeryApiType',
    name: 'celery-product',
    component: () =>
      import(
        /*TheCeleryTaskChunk*/ '@/modules/celery-task/views/TheCeleryTask.vue'
      ),
    meta: {
      parentRouteName: 'product',
      title: 'navigations.productMetaTitle'
    }
  },
  {
    path: '/inventory/product/preview-details/:id',
    name: 'preview-product',
    component: () =>
      import(
        /*ThePreviewProductChunk*/ '@/modules/inventory/product/views/ThePreviewProduct.vue'
      ),
    meta: {
      title: 'navigations.productMetaTitle',
      parentRouteName: 'product',
      logPage: {
        endpoint: productEndpoints,
        previewDynamicComponents: productPreviewComponents
      }
    }
  },
  {
    path: '/inventory/product/upsert-product/:id?',
    name: 'upsert-product',
    component: () =>
      import(
        /*TheUpsertProductChunk*/ '@/modules/inventory/product/views/TheUpsertProduct.vue'
      ),
    meta: {
      title: 'navigations.productMetaTitle',
      parentRouteName: 'product'
    }
  },
  {
    path: '/inventory/product/update-product-status/:id?',
    name: 'update-product-status',
    component: () =>
      import(
        /*TheUpdateProductStatusChunk*/ '@/modules/inventory/product/views/TheUpdateProductStatus.vue'
      ),
    meta: {
      title: 'navigations.productMetaTitle',
      parentRouteName: 'product'
    }
  }
]

export const productModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-product',
    requiredParams: ['id'],
    onDelete: q => productEndpoints.delete(q.id.toString()),
    dialogTitle: 'productTitle'
  },
  {
    component: SetBranch,
    persistent: true,
    name: 'branch',
    requiredParams: ['id']
  },
  {
    component: ImportFile,
    size: 600,
    persistent: true,
    name: 'import-product',
    requiredParams: []
  },
  {
    component: ExportFile,
    size: 600,
    persistent: true,
    name: 'export-product',
    requiredParams: []
  },
  {
    component: BulkAction,
    persistent: true,
    name: 'bulk-action',
    size: 600,
    requiredParams: []
  }
]
