import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import { workflowManagementEndpoints } from '@/modules/seller-management/workflow-management'
import { workflowManagementPreviewComponents } from '@/modules/seller-management/workflow-management/helpers/previewComponents'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'

/** Workflow management module routes */
export const workflowManagementRoutes: RouteConfig[] = [
  {
    path: '/workflow-management',
    name: 'workflowManagement',
    component: () =>
      import(
        /* webpackChunkName: "TheWorkflowManagementChunk" */ '@/modules/seller-management/workflow-management/views/TheWorkflowManagement.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.workflowManagementMetaTitle'
    }
  },
  {
    path: '/workflow-management/preview-details/:id',
    name: 'preview-workflowManagement',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewWorkflowManagementChunk" */ '@/modules/seller-management/workflow-management/views/ThePreviewWorkflowManagement.vue'
      ),
    meta: {
      title: 'navigations.workflowManagementMetaTitle',
      parentRouteName: 'workflowManagement',
      logPage: {
        endpoint: workflowManagementEndpoints,
        previewDynamicComponents: workflowManagementPreviewComponents
      }
    }
  },
  {
    path: '/workflow-management/upsert-workflow-management/:id?',
    name: 'upsert-workflowManagement',
    component: () =>
      import(
        /* webpackChunkName: "TheUpsertWorkflowManagementChunk" */ '@/modules/seller-management/workflow-management/views/TheUpsertWorkflowManagement.vue'
      ),
    meta: {
      title: 'navigations.workflowManagementMetaTitle',
      parentRouteName: 'workflowManagement'
    }
  }
]
export const workflowManagementModalRoute: PopUpRouteConfig[] = [
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-workflowManagement',
    requiredParams: ['id'],
    onDelete: q => workflowManagementEndpoints.delete(q.id.toString()),
    dialogTitle: 'workflowManagementProfileTitle'
  }
]
