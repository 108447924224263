
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import { Route } from 'vue-router'
import eventBus from '@/eventBus'
import { IVariant } from '@/modules/inventory/variant/types/variantTypes'
import { variantEndpoints } from '@/modules/inventory/variant'
import { freezeObject } from '@/shared/helpers/freezeObject'

@Component({
  name: 'TheUpsertVariant',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: DialogBaseSkeleton */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class TheUpsertVariant extends Vue {
  loading = false
  data = {} as IVariant
  id = this.$route.params.id || ''
  originalData = {} as IVariant

  title = (_params: Route['params'], query: Route['query']) =>
    query.id
      ? `${this.$t('dialog.variant.edit')}`
      : `${this.$t('dialog.variant.addNew')}`

  /**
   * function will be passed to child components
   * @params variant which modules type the fetched Cities will be passed to it in the child component. (base form)
   */
  fields(): Field<IVariant>[] {
    return [
      Field.text<IVariant>('variantName', 'name').setCol('col-12').required()
    ]
  }

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(
    values: Pick<IVariant, 'id'>,
    _params: Record<string, number>,
    queries: Record<string, number>
  ) {
    if (queries.id) {
      await variantEndpoints
        .patch(queries.id, this.originalData, values)
        .then(() => popUpRoute.removeModalPath(['id']))
    } else
      await variantEndpoints.post(values).then(() => {
        popUpRoute.removeModalPath(['id'])
      })
    eventBus.refreshData()
  }

  bindSubmitTitle(_params: Route['params'], query: Route['query']): string {
    return query.id ? 'update' : 'add'
  }

  /**
   * closePopUp function pass to the baseModal component
   */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }

  created() {
    this.fetchRemoteData()
  }

  /**
   * call given fetch data function
   */
  async fetchRemoteData() {
    const id = parsePathQueryString(location.search).id
    this.loading = true
    try {
      if (id) {
        this.data = await variantEndpoints.get(id)
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }
}
