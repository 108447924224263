import { AnyObject } from '@/shared/types/builtInTypes'

/**
 * To get rid of access modifiers in fields class.
 * we created & used this interface
 * in base form properties
 */
export interface IField<T> {
  type: FieldType
  rules?: string[]
  label?: string
  key?: keyof T
  value: unknown
  items?: () => Promise<unknown[]>
  searchFetcher?: (params: Record<string, string>) => Promise<unknown[]>
  props?: AnyObject
  listeners?: AnyObject
  hidden?: boolean
  cols?: string
  break?: boolean
  loading: boolean
  sectionTitle?: string
  divider?: boolean
  dependantKey?: string[]
  isResettableDependantKey?: boolean
  slots: string[]
}

/** type(component) of fields.*/
export enum FieldType {
  flaggedCountries = 'flaggedCountries',
  currency = 'currency',
  internationalPhone = 'internationalPhone',
  text = 'text',
  textarea = 'textarea',
  dropdown = 'dropdown',
  relation = 'relation',
  file = 'file',
  dateTime = 'dateTime',
  preview = 'preview',
  radio = 'radio',
  switch = 'switch',
  checkbox = 'checkbox',
  photo = 'photo',
  multiPhoto = 'multiPhoto',
  avatar = 'avatar',
  table = 'table',
  banner = 'banner',
  colorPicker = 'colorPicker',
  combobox = 'combobox',
  mapping = 'mapping',
  nestedDropdown = 'nestedDropdown',
  textEditor = 'textEditor',
  treeView = 'treeView',
  variantOption = 'variantOption',
  autoCompleteFetch = 'autoCompleteFetch'
}
