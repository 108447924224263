import { Id, IMetadata } from '@/shared/types/builtInTypes'
import { CategoriesType } from '@/modules/inventory/category/types/categoryTypes'

export enum StyleType {
  product = 'product',
  banner = 'banner',
  halfBanner = 'half_banner',
  category = 'category',
  navigation = 'navigation',
  slider = 'slider',
  brand = 'brand'
}

export const styleTypeColors: Record<StyleType | string, string> = {
  [StyleType.product]: 'info',
  [StyleType.banner]: 'success',
  [StyleType.halfBanner]: 'error',
  [StyleType.category]: 'primary',
  [StyleType.navigation]: 'secondary',
  [StyleType.slider]: 'blue-grey',
  [StyleType.brand]: 'purple'
}

export interface styleTypeItems {
  id: StyleType
  name: StyleType | string
}

export enum CollectionStrategy {
  manual = 'manual',
  automatic = 'automatic'
}

export interface collectionStrategyItems {
  id: CollectionStrategy
  name: CollectionStrategy | string
}

export const collectionStrategyColors: Record<
  CollectionStrategy | string,
  string
> = {
  [CollectionStrategy.manual]: 'primary',
  [CollectionStrategy.automatic]: 'success'
}

export enum Destination {
  arbelaExpress = 'arbela_express',
  personalShopper = 'personal_shopper'
}

export interface destinationItems {
  id: Destination
  name: Destination | string
}

export enum ImagePosition {
  left = 'left',
  right = 'right'
}

export interface imagePositionItems {
  id: ImagePosition
  name: ImagePosition | string
}

export type ListItems =
  | styleTypeItems
  | imagePositionItems
  | destinationItems
  | collectionStrategyItems

export interface IProductList {
  id: Id
  name: string
}

export type Images = { id: Id; image: string }

export interface ICollection extends IMetadata {
  id: Id
  title: string
  description: string
  styleType: StyleType
  collectionStrategy: CollectionStrategy
  productIds: Id[]
  products: {
    id: Id
    name: string
    description: string
    salePrice: number
    image: string
  }[]
  categoryIds: Id[]
  categories: {
    id: Id
    name: string
    image: string
    categoryType: CategoriesType
  }[]
  brandIds: Id[]
  brands: {
    id: Id
    name: string
    image: string
  }[]
  filterKwargs: {
    data: IFilterKwargsData | null
    fieldName: string
    type: FilterKwargsType
    lookupExpression: string
    value: string | number
  }[]
  image: string
  images: (string | File)[] | Images[]
  imagePosition: ImagePosition
  buttonText: string
  destination: Destination
}

export type FilterKwargsType = 'string' | 'number' | 'enum' | 'primary_key'
export type FilterKwargsDataType = 'array' | 'endpoint'
export type DataValue = { id: string; name: string }[] | string | null

export interface ILookupExpressions {
  id: Id
  name: string
}

export interface IFilterKwargsData {
  type: FilterKwargsDataType
  value: DataValue
}

export interface IFilterKwargs {
  fieldName: {
    id: Id
    name: string
  }
  type: FilterKwargsType
  data: IFilterKwargsData | null
  lookupExpressions: ILookupExpressions[]
}

export interface IResponseFilterKwargs {
  data: IFilterKwargsData | null
  fieldName: string
  type: FilterKwargsType
  lookupExpression: string
  value: string | number
}
