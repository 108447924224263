import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertEscalationProfile from '@/modules/seller-management/escalation-profile/views/TheUpsertEscalationProfile.vue'
import { escalationProfileEndpoints } from '@/modules/seller-management/escalation-profile'
import { escalationProfilePreviewComponents } from '@/modules/seller-management/escalation-profile/helpers/previewComponents'

/** Escalation Profile module routes*/
export const escalationProfileRoutes: RouteConfig[] = [
  {
    path: '/escalation-profile',
    name: 'escalationProfile',
    component: () =>
      import(
        /* webpackChunkName: "TheEscalationProfileChunk" */ '@/modules/seller-management/escalation-profile/views/TheEscalationProfile.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.escalationProfileMetaTitle'
    }
  },
  {
    path: '/escalation-profile/preview-details/:id',
    name: 'preview-escalationProfile',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewEscalationProfileChunk" */ '@/modules/seller-management/escalation-profile/views/ThePreviewEscalationProfile.vue'
      ),
    meta: {
      title: 'navigations.escalationProfileMetaTitle',
      parentRouteName: 'escalationProfile',
      logPage: {
        endpoint: escalationProfileEndpoints,
        previewDynamicComponents: escalationProfilePreviewComponents
      }
    }
  }
]
export const escalationProfileModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertEscalationProfile,
    persistent: true,
    name: 'upsert-escalationProfile',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-escalationProfile',
    requiredParams: ['id'],
    onDelete: q => escalationProfileEndpoints.delete(q.id.toString()),
    dialogTitle: 'escalationProfileTitle'
  }
]
