import { PreviewComponent } from '@/services/utils/basePreview'
import {
  CategoriesType,
  ICategory,
  IVariantCategory
} from '@/modules/inventory/category/types/categoryTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import i18n from '@/plugins/i18n'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

/**
 * dynamic component generator method
 * @param category is ICategory interface type which
 * @return An Array of IPreviewComponent category type
 */
export function categoryPreviewComponents(
  category: ICategory
): PreviewComponent<ICategory>[] {
  return [
    PreviewComponent.photo('image', category.image),
    PreviewComponent.labelValue<ICategory>(
      'name',
      'name',
      category.name
    ).setTitle('categoryDetails'),
    PreviewComponent.labelValue<ICategory>(
      'type',
      'categoryType',
      ContentFormatter.$customizeText(category.categoryType)
    ),
    PreviewComponent.labelValue<ICategory>(
      'requiredVariants',
      'requiredVariants',
      category.requiredVariants &&
        (category.requiredVariants as IVariantCategory[])
          ?.map(variant => variant.name)
          .join(' , ')
    ).setHidden(category.categoryType !== CategoriesType.LEAF),
    PreviewComponent.labelValue<ICategory>(
      'optionalVariants',
      'optionalVariants',
      category.optionalVariants &&
        (category.optionalVariants as IVariantCategory[])
          ?.map(variant => variant.name)
          .join(' , ')
    ).setHidden(category.categoryType !== CategoriesType.LEAF),
    PreviewComponent.labelValue<ICategory>(
      'isReturnable',
      'isReturnable',
      category.isReturnable ? i18n.t('label.yes') : i18n.t('label.no')
    )
      .setHidden(category.categoryType !== CategoriesType.LEAF)
      .setProp({ class: 'text-uppercase', color: 'primary' }),
    PreviewComponent.table<ICategory>(
      'children',
      [
        {
          text: i18n.t('header.name'),
          value: 'name',
          key: 'name',
          formatValue: 'text'
        },
        {
          text: i18n.t('header.type'),
          value: 'categoryType',
          key: 'categoryType',
          formatValue: 'customizeText'
        }
      ],
      category.children
    )
      .setProp({ class: 'col-12' })
      .setTitle('categoryChildren')
      .setHidden(category.categoryType === CategoriesType.LEAF),
    ...metadataLabelValues(category)
  ]
}
