import { IStaff } from '@/modules/staff/types/staffTypes'
import { ModelApi } from '@/services/api/modelApi'
import axios from 'axios'
import { Id } from '@/shared/types/builtInTypes'

/**
 * Staff endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class StaffEndpoints extends ModelApi<IStaff> {
  constructor() {
    super('staff')
  }

  assignRole(id: Id, roleId: Id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`staff/${id}/set_role/`, { roleId: roleId })
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
