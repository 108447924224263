
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { parsePathQueryString } from '@/shared/helpers/parsePathQueryString'
import BaseForm from '@/stories/base-form/BaseForm.vue'
import eventBus from '@/eventBus'
import { freezeObject } from '@/shared/helpers/freezeObject'
import { IInternationalLogistic } from '@/modules/seller-management/international-logistic/types/internationalLogisticTypes'
import { sellerEndpoints } from '@/modules/seller'

@Component({
  name: 'UpdateInternationalLogistic',
  components: {
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName: "DialogBaseSkeleton" */ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      ),
    BaseForm
  }
})
export default class UpdateInternationalLogistic extends Vue {
  loading = false
  data = {} as Pick<IInternationalLogistic, 'cost'>
  originalData = {} as Pick<IInternationalLogistic, 'cost'>

  created() {
    this.fetchRemoteData()
  }

  /** call given fetch data function */
  async fetchRemoteData() {
    const { id } = parsePathQueryString(location.search)

    this.loading = true
    try {
      if (id) {
        const res = await sellerEndpoints.get(id)
        this.data = res.internationalLogistic
        this.originalData = freezeObject(this.data)
      }
    } catch (error) {
      throw new Error(`Failed to fetch form data. Due to ${error}`)
    } finally {
      this.loading = false
    }
  }

  fields(): Field<IInternationalLogistic>[] {
    return [
      Field.currency<IInternationalLogistic>(
        'internationalLogisticCost',
        'cost'
      )
        .setProps({ decimalLength: { min: 0, max: 2 } })
        .setCol('col-12')
        .required()
    ]
  }

  /** submit the data to the desired endpoint. */
  async handleSubmit(values: Pick<IInternationalLogistic, 'cost'>) {
    const { id } = parsePathQueryString(location.search)

    if (id) {
      await sellerEndpoints
        .updateSellerEstimationOrLogistic(
          id,
          'international_logistic',
          values,
          this.originalData
        )
        .then(() => popUpRoute.removeModalPath(['id']))
    }
    eventBus.refreshData()
  }
  /** closePopUp function pass to the baseModal component */
  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
