import { ModelApi } from '@/services/api/modelApi'
import { IWorkflowAction } from '@/modules/seller-management/workflow-action/types/workflowActionTypes'

/**
 * Workflow Action endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the module names which will
 */
export class WorkflowActionEndpoints extends ModelApi<IWorkflowAction> {
  constructor() {
    super('maker_checker/workflow_actions')
  }
}
