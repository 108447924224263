import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import ViewGiftCardNumber from '@/modules/arbela-gift-card/gift-card/components/ViewGiftCardNumber.vue'

/** gift Card module routes */
export const giftCardRoutes: RouteConfig[] = [
  {
    path: '/gift-card/serial-number/:serialNumber?',
    name: 'gift-card-tracking',
    component: () =>
      import(
        /* webpackChunkName: "TheGiftCardChunk" */ '@/modules/arbela-gift-card/gift-card/views/TheGiftCard.vue'
      ),
    meta: {
      title: 'navigations.giftCardMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/gift-card/set-gift-card-status/serial-number/:serialNumber',
    name: 'set-gift-card-status',
    component: () =>
      import(
        /* webpackChunkName: "UpdateGiftCardStatusChunk" */ '@/modules/arbela-gift-card/gift-card/components/UpdateGiftCardStatus.vue'
      ),
    meta: {
      title: 'navigations.giftCardMetaTitle',
      parentRouteName: 'gift-card-tracking'
    }
  }
]

export const giftCardModalRoute: PopUpRouteConfig[] = [
  {
    component: ViewGiftCardNumber,
    persistent: false,
    name: 'view-gift-card-number',
    requiredParams: ['serialNumber']
  }
]
