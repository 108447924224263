import { PreviewComponent } from '@/services/utils/basePreview'
import { ISellerBranch } from '@/modules/seller/types/sellerTypes'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param sellerBranch is ISellerBranch interface type which
 * @return An Array of IPreviewComponent seller type
 */
export function sellerBranchPreviewLogComponents(
  sellerBranch: ISellerBranch
): PreviewComponent<ISellerBranch>[] {
  return [
    PreviewComponent.labelValue<ISellerBranch>(
      'name',
      'name',
      sellerBranch.name
    ),
    PreviewComponent.labelValue<ISellerBranch>(
      'city',
      'cityId',
      sellerBranch.city?.name
    ),
    PreviewComponent.labelValue<ISellerBranch>(
      'address',
      'address',
      sellerBranch.address
    )
      .setCol('col-12')
      .setBreak(true),
    PreviewComponent.labelValue<ISellerBranch>(
      'description',
      'description',
      sellerBranch.description
    )
      .setCol('col-12')
      .setBreak(true),
    ...metadataLabelValues(sellerBranch)
  ]
}
