/**
 * Customize the user phone number input
 * First Remove any space
 * If it Email or contain + in beginning (that is mean it is international) just return it
 * If it contains 07 or 7 or 009647 in the beginning replace it with +9647
 * @param number
 * @return appendedPrefix
 * */
export default function customizePhoneNumber(number = ''): string {
  // Removing space

  let appendedPrefix = number.replace(/\s+/g, '')

  if (appendedPrefix.includes('@')) return number

  if (/^\+/.test(appendedPrefix)) return appendedPrefix
  else {
    appendedPrefix = appendedPrefix.replace(/^(?:(009647)|(07)|(7))/gm, '+9647')
    return appendedPrefix
  }
}
