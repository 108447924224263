
import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/services/utils/baseForm'
import { popUpRoute } from '@/services/utils/popupRoute'
import { ILocalization } from '@/modules/preference/localization/types/localizationTypes'
import { localizationEndpoints } from '@/modules/preference/localization'
import appStore from '@/AppStore'
import { AnyObject } from '@/shared/types/builtInTypes'

@Component({
  name: 'ExportLocalization',
  components: {
    BaseForm: () =>
      import(
        /* webpackChunkName : "BaseForm"*/ '@/stories/base-form/BaseForm.vue'
      ),
    DialogBaseSkeleton: () =>
      import(
        /* webpackChunkName : "DialogBaseSkeleton"*/ '@/stories/dialog-base-skeleton/DialogBaseSkeleton.vue'
      )
  }
})
export default class ExportLocalization extends Vue {
  platform = appStore.getters['platform/getPlatformList']

  /**
   * function will be passed to child components
   * @params localization which modules type the fetched localizations will be passed to it in the child component. (base form)
   */
  fields = () => [
    Field.dropdown<ILocalization>(
      'platform',
      'platformId',
      () => Promise.resolve(this.platform),
      (item: AnyObject) => item.name,
      'id'
    )
      .required()
      .setCol('col-12')
  ]

  /**
   * submit the data to the desired endpoint.
   */
  async handleSubmit(values: Pick<ILocalization, 'platformId'>) {
    const name = this.platform.find(
      (current: AnyObject) => current.id === values.platformId
    )?.name

    await localizationEndpoints
      .exportFile(values.platformId, name || '')
      .then(() => this.closePopUp())
  }

  closePopUp() {
    popUpRoute.removeModalPath(['id'])
  }
}
