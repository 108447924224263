import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ar from '@/locales/ar.json'
import ckb from '@/locales/ckb.json'
import en from '@/locales/en.json'

Vue.use(VueI18n)

/**
 * translation configurations
 */
export default new VueI18n({
  // this has been added for storybook
  messages: {
    en,
    ckb,
    ar
  },
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})
