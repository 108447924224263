import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { IPricingLimitProfile } from '@/modules/seller-management/pricing-limit-profile/types/pricingLimitProfileTypes'

/**
 * dynamic component generator method
 * @param pricingLimitProfile is IPricingLimitProfile interface type which
 * @return An Array of IPreviewComponent pricing profile type
 */
export function pricingLimitProfilePreviewComponents(
  pricingLimitProfile: IPricingLimitProfile
): PreviewComponent<IPricingLimitProfile>[] {
  return [
    PreviewComponent.labelValue<IPricingLimitProfile>(
      'profileName',
      'profileName',
      pricingLimitProfile.profileName
    ).setTitle('pricingLimitProfileDetail'),
    PreviewComponent.labelValue<IPricingLimitProfile>(
      'upperLimit',
      'upperLimit',
      ContentFormatter.$formatToPercentage(pricingLimitProfile.upperLimit, true)
    ),
    PreviewComponent.labelValue<IPricingLimitProfile>(
      'lowerLimit',
      'lowerLimit',
      ContentFormatter.$formatToPercentage(pricingLimitProfile.lowerLimit, true)
    ),
    PreviewComponent.labelValue<IPricingLimitProfile>(
      'description',
      'description',
      pricingLimitProfile.description
    )
      .setCol('12')
      .setBreak(true),
    ...metadataLabelValues(pricingLimitProfile)
  ]
}
