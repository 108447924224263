import { Id, IMetadata } from '@/shared/types/builtInTypes'
import { ICustomerList } from '@/modules/customer/types/customerTypes'
import { TranslateResult } from 'vue-i18n'

export enum ReturnReasonTypeChoices {
  iDoNotLikeTheModel = `I don't like the model`,
  missingPiecesAccessoriesInTheProduct = `Missing pieces/accessories in the product`,
  cargoDeliveryDelay = 'Cargo Delivery Delay',
  defectiveProduct = 'Defective Product',
  wrongProduct = 'Wrong Product',
  productHasWrongCharacteristics = `Product has Wrong Characteristics`,
  usedDamagedProduct = `Used / Damaged Product`,
  iDoNotLikeTheQuality = `I don't like the quality`
}

export enum ItemStatus {
  PROCESSING = 'processing',
  COLLECTED = 'collected',
  PACKED = 'packed',
  CANCELLED = 'cancelled',
  DELIVERED = 'delivered',
  DELAYED = 'delayed',
  BOOKED = 'Booked',
  PENDING = 'pending'
}

export const itemStatusColors: Record<ItemStatus | string, string> = {
  [ItemStatus.PENDING]: 'warning',
  [ItemStatus.PROCESSING]: 'warning',
  [ItemStatus.PACKED]: 'info',
  [ItemStatus.CANCELLED]: 'error',
  [ItemStatus.DELIVERED]: 'success',
  [ItemStatus.COLLECTED]: 'info',
  [ItemStatus.DELAYED]: 'warning',
  [ItemStatus.BOOKED]: 'onSurfaceMedium'
}

export enum DelayStatus {
  escalated = 'escalated',
  safe = 'safe',
  warning = 'warning'
}

export const delayStatusColors: Record<DelayStatus | string, string> = {
  [DelayStatus.escalated]: 'error',
  [DelayStatus.safe]: 'success',
  [DelayStatus.warning]: 'warning'
}

export enum PaymentStatus {
  unpaid = 'unpaid',
  paid = 'paid',
  cashOnDelivery = 'cash_on_delivery',
  partialPaid = 'partial_paid',
  refunded = 'refunded'
}

export const paymentStatusColors: Record<PaymentStatus | string, string> = {
  [PaymentStatus.unpaid]: 'error',
  [PaymentStatus.paid]: 'success',
  [PaymentStatus.cashOnDelivery]: 'error',
  [PaymentStatus.partialPaid]: 'warning',
  [PaymentStatus.refunded]: 'info'
}

export interface ICurrencyRateDetail {
  rate: number
  currencyName: string
}

export interface IOrderItems extends IMetadata {
  id: Id
  url: string
  unitPrice: number
  quantity: number
  invoiceNumber: string
  productName: string
  variantOptions: {
    name: string
    value: string
  }[]
  productVariantOptions: {
    name: string
    value: string
  }[]
  taxCost: number
  totalAmount: number
  totalLogistic: number
  totalTax: number
  totalCommission: number
  sellerSku: string
  systemSku: string
  orderNumber: string
  delayStatus: DelayStatus
  itemStatus: ItemStatus
  paymentStatus: PaymentStatus
  customer: ICustomerList
  customerFullName: string
  customerId: Id
  customerPhone: number
  storeName: string
  currencyRateDetail: ICurrencyRateDetail
  paymentOptions: string[]
  productId: string
  merchantId: Id
  weight: string
  images: { image: string; id: Id }[]
  itemTracking: [
    {
      createdAt: string
      createdBy: string
      id: Id
      itemStatus: ItemStatus
      notes: string
    }
  ]
  returnReason: string
  notes: string
  returnDate: string
  returnType: string
  merchantAddress: string
  merchantPhone: string
  countryName: string
  orderItemId: Id
  note: string
  inspectionOutcome: string
  paymentOptionName: string
  customerAddress: string
}

export interface IOrderItemDetails {
  text: TranslateResult
  value: string | number
}
