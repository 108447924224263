import i18n from '@/plugins/i18n'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'

const trans = {
  //Getter for default local
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE
  }, //Getter for a list of supported locales
  get supportedLocales(): string[] {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE?.split(',') || []
  }, // Getter for current local
  get currentLocale() {
    return localStorage.getItem('language') || i18n.locale
  }, //Setter for setting current local
  set currentLocale(locale) {
    localStorage.setItem('language', locale)
    i18n.locale = locale
  },

  //Handle change local
  changeLocale(locale: string) {
    //Fetch the data and pass admin id for platformId
    //Customize the transformResponse to avoid applying camelCase for the keys
    return new Promise((resolve, reject) => {
      axios
        .get('/me/localization/translation_list/', {
          params: {
            languageId:
              this.supportedLocales &&
              this.supportedLocales.indexOf(locale) + 1,
            platformId: 3
          },
          transformResponse: data => {
            return JSON.parse(data).data
          }
        })
        .then(response => {
          i18n.setLocaleMessage(locale, response.data)
          this.setI18nLocaleInServices(locale)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  },

  //Method to switch locale
  setI18nLocaleInServices: function (locale: string) {
    localStorage.setItem('language', locale)
    trans.currentLocale = locale
    vuetify.framework.rtl = locale !== 'en'
    return locale
  }
}

export { trans }
