import { ModelApi } from '@/services/api/modelApi'
import { AnyObject, Id } from '@/shared/types/builtInTypes'
import { ApiListResponse } from '@/shared/types/apiListResponse'
import axios from 'axios'
import {
  IGiftCard,
  IGiftCardNumber
} from '@/modules/arbela-gift-card/gift-card/types/giftCardTypes'

/**
 * Gift Card endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class GiftCardEndpoints extends ModelApi<IGiftCard> {
  constructor() {
    super('gift_card')
  }

  /**
   * Get gift card data to display in data table
   * @param batchId
   * @param params
   */
  giftCardDataTable(
    batchId: Id,
    params = {}
  ): Promise<ApiListResponse<IGiftCard>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`gift_card/`, {
          params: {
            ...this.transformOptions(params),
            batchId: batchId
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Update gift card status by sending serial number
   * @param serialNumber
   * @param payload
   */
  updateGiftCardStatus(
    serialNumber: string,
    payload: AnyObject = {}
  ): Promise<IGiftCard> {
    return new Promise((resolve, reject) => {
      axios
        .post(`gift_card/${serialNumber}/gift_card_status/`, payload)
        .then(response => {
          this.responseNotify('notifications.updatedSuccessfully')
          resolve(response.data.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }

  /**
   * Get gift card number
   * @param serialNumber
   */
  giftCardNumber(serialNumber: string): Promise<IGiftCardNumber> {
    return new Promise((resolve, reject) => {
      axios
        .get(`gift_card/${serialNumber}/get_card_number/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          this.responseNotify(error.response.data, 'error')
          reject(error.response.data)
        })
    })
  }
}
