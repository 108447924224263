import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import { discountProfileEndpoints } from '@/modules/seller-management/discount-profile'
import { discountProfilePreviewComponents } from '@/modules/seller-management/discount-profile/helpers/previewComponents'
import TheUpsertDiscountProfile from '@/modules/seller-management/discount-profile/views/TheUpsertDiscountProfile.vue'

/** Discount Profile module routes*/
export const discountProfileRoutes: RouteConfig[] = [
  {
    path: '/discount-profile',
    name: 'discountProfile',
    component: () =>
      import(
        /* webpackChunkName: "TheDiscountProfileChunk" */ '@/modules/seller-management/discount-profile/views/TheDiscountProfile.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.discountProfileMetaTitle'
    }
  },
  {
    path: '/discount-profile/preview-details/:id',
    name: 'preview-discountProfile',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewDiscountProfileChunk" */ '@/modules/seller-management/discount-profile/views/ThePreviewDiscountProfile.vue'
      ),
    meta: {
      title: 'navigations.discountProfileMetaTitle',
      parentRouteName: 'discountProfile',
      logPage: {
        endpoint: discountProfileEndpoints,
        previewDynamicComponents: discountProfilePreviewComponents
      }
    }
  }
]
export const discountProfileModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertDiscountProfile,
    persistent: true,
    name: 'upsert-discountProfile',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-discountProfile',
    requiredParams: ['id'],
    onDelete: q => discountProfileEndpoints.delete(q.id.toString()),
    dialogTitle: 'discountProfileTitle'
  }
]
