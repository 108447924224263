import { ICustomer } from '@/modules/customer/types/customerTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import i18n from '@/plugins/i18n'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'
import { mergeAmountWithType } from '@/shared/helpers/amountWithAmountType'

/**
 * dynamic component generator method
 * @param customer is ICustomer interface type which
 * @return An Array of IPreviewComponent customer type
 */
export function customerPreviewComponents(
  customer: ICustomer
): PreviewComponent<ICustomer>[] {
  return [
    PreviewComponent.labelValue<ICustomer>(
      'firstName',
      'firstName',
      customer.firstName
    ).setTitle('customerProfile'),
    PreviewComponent.labelValue<ICustomer>(
      'lastName',
      'lastName',
      customer.lastName
    ),
    PreviewComponent.labelValue<ICustomer>('phone', 'phone', customer.phone),
    PreviewComponent.labelValue<ICustomer>('email', 'email', customer.email),
    PreviewComponent.labelValue<ICustomer>('customerID', 'id', customer.id),
    PreviewComponent.labelValue<ICustomer>(
      'gender',
      'gender',
      customer.gender === 'f' ? i18n.t('label.female') : i18n.t('label.male')
    ),

    PreviewComponent.labelValue<ICustomer>(
      'status',
      'isActive',
      customer.isActive ? i18n.t('label.active') : i18n.t('label.inActive')
    ).setClass(customer.isActive ? 'success--text' : 'error--text'),
    PreviewComponent.labelValue<ICustomer>(
      'arbelaPayBalance',
      'arbelaBalance',
      ContentFormatter.$formatToCurrency(customer.arbelaBalance, true)
    ),
    PreviewComponent.labelValue<ICustomer>(
      'isDeletedAccount',
      'isDeleted',
      customer.isDeleted
        ? i18n.t('label.accountDeleted')
        : i18n.t('label.accountAvailable')
    ).setClass(customer.isDeleted ? 'error--text' : 'success--text'),
    PreviewComponent.labelValue<ICustomer>(
      'isEmailVerified',
      'isEmailVerified',
      customer.isEmailVerified
        ? i18n.t('label.verified')
        : i18n.t('label.unverified')
    ).setClass(customer.isEmailVerified ? 'success--text' : 'error--text'),
    PreviewComponent.labelValue<ICustomer>(
      'isPhoneVerified',
      'isPhoneVerified',
      customer.isPhoneVerified
        ? i18n.t('label.verified')
        : i18n.t('label.unverified')
    ).setClass(customer.isPhoneVerified ? 'success--text' : 'error--text'),
    PreviewComponent.labelValue<ICustomer>(
      'arbelaBalanceTransferFee',
      'transferFeeId',
      customer.transferFee?.label
    ),
    PreviewComponent.labelValue<ICustomer>(
      'transferAmount',
      'transferFeeId',
      mergeAmountWithType(customer.transferFee)
    ).setBreak(true),
    PreviewComponent.table<ICustomer>(
      'customerAddresses',
      [
        {
          text: i18n.t('header.addressTitle'),
          value: 'addressTitle',
          key: 'addressTitle',
          formatValue: 'text',
          width: '200px'
        },
        {
          text: i18n.t('header.city'),
          key: 'city',
          formatValue: 'text',
          width: '200px',
          value: 'city.name'
        },
        {
          text: i18n.t('header.location'),
          value: 'location',
          key: 'location',
          formatValue: 'text',
          width: '200px'
        },
        {
          text: i18n.t('header.phone'),
          value: 'phone',
          key: 'phone',
          formatValue: 'text',
          width: '200px'
        }
      ],
      customer.customerAddresses
    )
      .setProp({ class: 'col-12' })
      .setTitle('addressDetails'),
    ...metadataLabelValues(customer)
  ]
}
