import { ModelApi } from '@/services/api/modelApi'
import { IEscalationProfile } from '@/modules/seller-management/escalation-profile/types/escalationProfileTypes'

/**
 * Escalation Profile endpoint extends model api to inherit its methods and custom methods up to its needs
 * @param url is the modules names which will
 */
export class EscalationProfileEndpoints extends ModelApi<IEscalationProfile> {
  constructor() {
    super('escalation_profile')
  }
}
