import { ICity } from '@/modules/preference/city/types/cityTypes'
import { PreviewComponent } from '@/services/utils/basePreview'
import { metadataLabelValues } from '@/shared/helpers/metadataLabelValues'

/**
 * dynamic component generator method
 * @param city is ICity interface type which
 * @return An Array of IPreviewComponent city type
 */
export function cityPreviewComponents(city: ICity): PreviewComponent<ICity>[] {
  return [
    PreviewComponent.labelValue<ICity>('cityName', 'name', city.name),
    ...metadataLabelValues(city)
  ]
}
