import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import DialogDeleteConfirmation from '@/stories/dialog-delete-confirmation/DialogDeleteConfirmation.vue'
import TheUpsertStockProfile from '@/modules/seller-management/stock-profile/views/TheUpsertStockProfile.vue'
import { stockProfileEndpoints } from '@/modules/seller-management/stock-profile'
import { stockProfilePreviewComponents } from '@/modules/seller-management/stock-profile/helpers/previewComponents'

/** Stock Profile module routes*/
export const stockProfileRoutes: RouteConfig[] = [
  {
    path: '/stock-profile',
    name: 'stockProfile',
    component: () =>
      import(
        /* webpackChunkName: "TheStockProfileChunk" */ '@/modules/seller-management/stock-profile/views/TheStockProfile.vue'
      ),
    meta: {
      parentRouteName: null,
      title: 'navigations.stockProfileMetaTitle'
    }
  },
  {
    path: '/stock-profile/preview-details/:id',
    name: 'preview-stockProfile',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewStockProfileChunk" */ '@/modules/seller-management/stock-profile/views/ThePreviewStockProfile.vue'
      ),
    meta: {
      title: 'navigations.stockProfileMetaTitle',
      parentRouteName: 'stockProfile',
      logPage: {
        endpoint: stockProfileEndpoints,
        previewDynamicComponents: stockProfilePreviewComponents
      }
    }
  }
]
export const stockProfileModalRoute: PopUpRouteConfig[] = [
  {
    component: TheUpsertStockProfile,
    persistent: true,
    name: 'upsert-stockProfile',
    requiredParams: []
  },
  {
    component: DialogDeleteConfirmation,
    persistent: false,
    name: 'delete-stockProfile',
    requiredParams: ['id'],
    onDelete: q => stockProfileEndpoints.delete(q.id.toString()),
    dialogTitle: 'stockProfileTitle'
  }
]
