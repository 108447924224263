
import { Component, Vue } from 'vue-property-decorator'
import TheFooter from '@/core/components/TheFooter.vue'
import { IChangePasswordForm } from '@/modules/force-change-password/types/ForceChangePasswordType'
import { authEndpoints } from '@/modules/login'

@Component({
  name: 'ForceChangePassword',
  components: {
    TheFooter
  }
})
export default class ForceChangePassword extends Vue {
  form: IChangePasswordForm = {
    oldPassword: '',
    password: '',
    confirmPassword: ''
  }
  oldPassword = false
  newPassword = false
  loading = false

  async handleChangePassword() {
    this.loading = true
    await authEndpoints
      .handleChangePassword(this.form)
      .then(() => {
        this.$router.push({ path: '/' })
      })
      .finally(() => (this.loading = false))
  }
}
