import Vue from 'vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

//Globally import VTextField.
Vue.component('v-text-field', VTextField)

// Configure VCurrencyField library.
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: { min: 0, max: 8 },
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
})
