import { PreviewComponent } from '@/services/utils/basePreview'
import { IMetadata } from '@/shared/types/builtInTypes'
import { ContentFormatter } from '@/shared/helpers/contentFormatter'

export const metadataLabelValues = (metaData: IMetadata) => [
  PreviewComponent.labelValue('createdBy', 'createdBy', metaData.createdBy),
  PreviewComponent.labelValue(
    'createdAt',
    'createdAt',
    formatItemText(metaData.createdAt)
  ),
  PreviewComponent.labelValue('updatedBy', 'updatedBy', metaData.updatedBy),
  PreviewComponent.labelValue(
    'updatedAt',
    'updatedAt',
    formatItemText(metaData.updatedAt)
  )
]

function formatItemText(item: string | Date) {
  return ContentFormatter.$formatToDateTime(item)
}
