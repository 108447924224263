import { RouteConfig } from 'vue-router'
import { PopUpRouteConfig } from '@/shared/types/basePopUp'
import TheAddingCurrencyRate from '@/modules/accounting/currency-rate/views/TheAddingCurrencyRate.vue'

/**
 * Currency Rate module routes
 */
export const currencyRateRoutes: RouteConfig[] = [
  {
    path: '/currency-rate',
    name: 'currencyRate',
    component: () =>
      import(
        /* webpackChunkName: "TheCurrencyRateChunk" */ '@/modules/accounting/currency-rate/views/TheCurrencyRate.vue'
      ),
    meta: {
      title: 'navigations.currencyRateMetaTitle',
      parentRouteName: null
    }
  },
  {
    path: '/currency-rate/preview-currency-rate/:id',
    name: 'preview-currency-rate',
    component: () =>
      import(
        /* webpackChunkName: "ThePreviewCurrencyRateChunk" */ '@/modules/accounting/currency-rate/views/ThePreviewCurrencyRate.vue'
      ),
    meta: {
      title: 'navigations.currencyRateMetaTitle',
      parentRouteName: 'currencyRate'
    }
  }
]

export const currencyRateModalRoute: PopUpRouteConfig[] = [
  {
    component: TheAddingCurrencyRate,
    persistent: true,
    size: 700,
    name: 'add-currency-rate',
    requiredParams: []
  }
]
